import Button from "@mui/material/Button";
import Edit from "@mui/icons-material/Edit";
import firebase from "firebase/compat/app";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Accommodationtypes } from "@bookingflow/types";
import AddBooking from "./AddBooking";
import "./Booking.css";
import PaginatedTable from "./PaginatedTable";
// TODO: use rowy.io for the table component

export interface IBookingProps {
  db: firebase.firestore.Firestore;
  accommodations: Accommodationtypes;
  siteId: string;
  dataLoaded: boolean;
  func: firebase.functions.Functions;
}

export interface ICellInfo {
  index: number;
  column: IColumn;
}
export interface IColumn {
  id: number;
}

const BookingTab = (props: IBookingProps) => {
  const [editID, setEditID] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditClose = () => {
    setEditModalOpen(false);
  };

  const editBooking = (id: string): void => {
    // TODO: require a confirmation modal before editing
    setEditID(id);
    setEditModalOpen(true);
  };

  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        Cell: (row: any) => (
          <div className="tableButtons">
            <Button
              onClick={() => editBooking(row.value)}
              className="waves-effect waves-light btn-flat"
            >
              <Edit />
            </Button>
          </div>
        ),
        id: "button",
      },
      {
        Header: "booking id",
        accessor: "id",
      },
      {
        Header: "site",
        accessor: "siteId",
      },
      {
        Header: "first name",
        accessor: "firstname",
      },
      {
        Header: "last name",
        accessor: "lastname",
      },
      {
        Header: "accommodation id",
        accessor: "accommodationid",
      },
      {
        Header: "accommodation name",
        accessor: "accommodation",
      },
      {
        id: "arrival",
        Header: "arrival",
        accessor: (row: any) => {
          try {
            return DateTime.fromJSDate(row.arrive.toDate()).toISODate();
          } catch (err) {
            return "Invalid Date";
          }
        },
      },
      {
        id: "departure",
        Header: "departure",
        accessor: (row: any) => {
          try {
            return DateTime.fromJSDate(row.depart.toDate()).toISODate();
          } catch (err) {
            return "Invalid Date";
          }
        },
      },
      {
        id: "created",
        Header: "created",
        accessor: (row: any) => {
          try {
            return DateTime.fromJSDate(row.created.toDate()).toISODate();
          } catch (err) {
            return "Invalid Date";
          }
        },
      },
      {
        id: "amount",
        Header: "amount",
        accessor: "accommodationcostamount",
      },
      {
        id: "adults",
        Header: "adults",
        accessor: "adults",
      },
      {
        id: "children",
        Header: "children",
        accessor: "children",
      },
      {
        id: "infants",
        Header: "infants",
        accessor: "infants",
      },
      {
        Header: "pitch number",
        accessor: "pitch",
      },
      {
        Header: "status",
        accessor: "status",
      },
      {
        Header: "email",
        accessor: "email",
      },
      {
        Header: "telephone",
        accessor: "telephone",
      },
      {
        Header: "cancelled",
        accessor: (row: any) => {
          return row.cancelled.toString();
        },
      },
    ],
    []
  );
  return (
    <div id="bookingGrid" className="main-container">
      <Helmet>
        <title>Booking</title>
      </Helmet>
      <h2>Bookings</h2>
      <div id="paginated-table">
        <PaginatedTable
          dataLoaded={props.dataLoaded}
          siteId={props.siteId}
          db={props.db}
          columns={columns}
        />
        <AddBooking
          dataLoaded={props.dataLoaded}
          editID={editID}
          accommodations={props.accommodations}
          db={props.db}
          addOpen={editModalOpen}
          handleClose={handleEditClose}
          siteId={props.siteId}
          func={props.func}
        />
      </div>
    </div>
  );
};

export default BookingTab;
