import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import React from "react";
import { IFile } from "./Documents";
import firebase from "firebase/compat/app";

export interface IProps {
  file: IFile | undefined;
  db: firebase.firestore.Firestore;
  setFileList: React.Dispatch<React.SetStateAction<IFile[]>>;
  fileList: IFile[];
  handleClose: () => void;
  deleteOpen: boolean;
  fileStorage: firebase.storage.Storage;
  siteId: string;
}

const DeleteBooking = (props: IProps) => {
  const handleSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    if (!props.file) {
      props.handleClose();
      return;
    }
    const fileId: string = props.file.id;
    const filename: string = props.file.filename;
    if (!fileId) {
      console.error("fileId does not exist");
      return;
    }
    const pathReference = props.fileStorage.ref(
      `${props.siteId}/documents/${props.file.filename}`
    );
    await pathReference.delete();
    const site = props.db.collection("sites").doc(props.siteId);
    const collectionRef = await site.collection("files");
    await collectionRef.doc(fileId).delete();
    const newFileList: IFile[] = props.fileList.filter(
      (doc: firebase.firestore.DocumentData) => doc.filename !== filename
    ) as unknown as IFile[];
    props.setFileList(newFileList);
    props.handleClose();
  };

  return (
    <div>
      <Dialog
        aria-label="delete-confirmation"
        aria-describedby="confirm-booking-should-be-deleted"
        open={props.deleteOpen}
        onClose={props.handleClose}
        id="deleteBooking"
      >
        <Card>
          <CardContent>
            <h6>Are you sure you want to delete this document</h6>
            <Button variant="text" onClick={props.handleClose}>
              Close
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              type="submit"
              value="Submit"
            >
              Confirm
            </Button>
          </CardContent>
        </Card>
      </Dialog>
    </div>
  );
};

export default DeleteBooking;
