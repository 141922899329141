import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DateTime } from "luxon";
import { useState } from "react";
import { Bookings, IBooking } from "@bookingflow/types";
import Skeleton from "@mui/material/Skeleton";
import { convertToUTCDate } from "@bookingflow/utils";

export interface IUpcomingProps {
  arrivals: Bookings;
  departures: Bookings;
  dataLoaded: boolean;
}

export interface IUpcomingState {
  type: string;
  date: string;
  data: any[];
  value: number;
}

const Upcoming = (props: IUpcomingProps) => {
  const [type, setType] = useState("arrivals");
  const [date, setDate] = useState("today");

  const getTodayArrival = (booking: IBooking) => {
    const arrival: number = booking.arrive.getTime();
    const date: number = new Date().setUTCHours(0, 0, 0, 0);
    return arrival === date;
  };

  const getTodayDeparture = (booking: IBooking) => {
    const departure: number = booking.depart.getTime();
    const date: number = new Date().setUTCHours(0, 0, 0, 0);
    return departure === date;
  };

  const getTomorrowArrival = (booking: IBooking) => {
    const arrival: number = booking.arrive.getTime();
    const tomorrow: number = convertToUTCDate(
      DateTime.local().startOf("day").plus({ day: 1 }).toJSDate()
    ).getTime();
    return arrival === tomorrow;
  };

  const getTomorrowDeparture = (booking: IBooking) => {
    const departure: number = booking.depart.getTime();
    const tomorrow: number = convertToUTCDate(
      DateTime.local().startOf("day").plus({ day: 1 }).toJSDate()
    ).getTime();
    return departure === tomorrow;
  };
  const createSkeletonRows = (): JSX.Element[] => {
    const items: any[] = [];
    const populateItems = () => {
      for (let index = 0; index < 4; index++) {
        items.push(
          <tr key={index}>
            <td>
              <Skeleton variant="rectangular" width={400} height={20} />
            </td>
            <td>
              <Skeleton variant="rectangular" width={50} height={20} />
            </td>
            <td>
              <Skeleton variant="rectangular" width={100} height={20} />
            </td>
            <td>
              <Skeleton variant="rectangular" width={50} height={20} />
            </td>
          </tr>
        );
      }
    };

    populateItems();

    return items;
  };

  const createRows = (): JSX.Element[] => {
    const items = [];
    let arrivals: Bookings;
    let departures: Bookings;
    if (date === "today") {
      arrivals = props.arrivals.filter(getTodayArrival);
      departures = props.departures.filter(getTodayDeparture);
    } else if (date === "tomorrow") {
      arrivals = props.arrivals.filter(getTomorrowArrival);
      departures = props.departures.filter(getTomorrowDeparture);
    } else {
      console.error("incorrect date specified");
      return [];
    }
    const populateItems = (d: IBooking, i: number) => {
      if (d.remainderamount) {
        items.push(
          <TableRow key={i}>
            <TableCell align="center">{`${d.firstname} ${d.lastname}`}</TableCell>
            <TableCell align="center">{d.nights}</TableCell>
            <TableCell align="center">
              {"£" + d.remainderamount.toString()}
            </TableCell>
            <TableCell align="center">{d.pitch}</TableCell>
          </TableRow>
        );
      }
    };
    if (type === "arrivals") {
      if (arrivals.length === 0) {
        items.push(
          <TableRow key="1">
            <TableCell align="center" />
            <TableCell align="center" />
            <TableCell align="center" />
            <TableCell align="center" />
          </TableRow>
        );
      } else {
        arrivals.forEach(populateItems);
      }
    } else if (type === "departures") {
      if (departures.length === 0) {
        items.push(
          <TableRow key="1">
            <TableCell align="center" />
            <TableCell align="center" />
            <TableCell align="center" />
            <TableCell align="center" />
          </TableRow>
        );
      } else {
        departures.forEach(populateItems);
      }
    } else {
      console.error("incorrect state specified");
      return [];
    }

    return items;
  };

  const handleTypeChange = (event: React.SyntheticEvent, newValue: number) => {
    newValue === 0 ? setType("arrivals") : setType("departures");
  };
  const handleDayChange = (event: React.SyntheticEvent, newValue: number) => {
    newValue === 0 ? setDate("today") : setDate("tomorrow");
  };
  return (
    <Card>
      <CardContent>
        <h3>Upcoming</h3>
        <Tabs
          value={type === "arrivals" ? 0 : 1}
          onChange={handleTypeChange}
          aria-label="arrivals vs departures selector"
        >
          <Tab label="Arrivals" />
          <Tab label="Departures" />
        </Tabs>
        <Tabs
          value={date === "today" ? 0 : 1}
          onChange={handleDayChange}
          aria-label="today vs tomorrow selector"
        >
          <Tab label="Today" />
          <Tab label="Tomorrow" />
        </Tabs>
        <TableContainer component={Paper} elevation={0}>
          <Table
            sx={{ minWidth: 350 }}
            aria-label="upcoming arrivals and departures"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Nights</TableCell>
                <TableCell align="center">Remaining Balance</TableCell>
                <TableCell align="center">Pitch No.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.dataLoaded && createRows()}
              {!props.dataLoaded && createSkeletonRows()}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
export default Upcoming;
