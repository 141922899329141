import Typography from "@mui/material/Typography";
import firebase from "firebase/compat/app";
import { DateTime } from "luxon";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { Helmet } from "react-helmet";
import {
  Allocations,
  Bookings,
  IAllocation,
  IBooking,
} from "@bookingflow/types";
import Headline from "./Headline";
import Performance from "./Performance";
import Recent from "./Recent";
import Upcoming from "./Upcoming";
import "./Dashboard.css";

export interface IDashboardProps {
  db: firebase.firestore.Firestore;
  func: firebase.functions.Functions;
  siteId: string;
  TY: Bookings;
  LY: Bookings;
  dataLoaded: boolean;
  recentBookings: Bookings;
  upcomingArrivals: Bookings;
  upcomingDepartures: Bookings;
}
/*
handleChange = <T extends keyof Booking>(event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const value = target.value;
    let name = target.name;
    const newState = {
        [name]: value
        // keyNotInState: '42', -> would throw a compile time error
        // numericKeyInState: 'assigning wrong type' -> would throw a compile time error
    };
    setState(newState as { [P in T]: Booking[P]; });
}

handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    alert('A form was submitted: ' + state.booking.firstName);
        // event.preventDefault();
        // submit booking to database
    }
    */
const Dashboard = (props: IDashboardProps) => {
  const [numVacancies, setNumVacancies] = useState("");
  const [allocations, setAllocations] = useState<Allocations>([]);
  const errorLogger = props.func.httpsCallable("error");
  const [unsubscribe, setUnsubscribe] = useState<() => () => void>(
    () => () => {}
  );
  const unsubscribeRef = useRef(unsubscribe);
  const getTodaysArrivals = (booking: IBooking) => {
    const arrival: number = booking.arrive.getTime();
    const date: number = new Date().setUTCHours(0, 0, 0, 0);

    return arrival === date;
  };
  const getTodaysDepartures = (booking: IBooking) => {
    const departure: number = booking.depart.getTime();
    const date: number = new Date().setUTCHours(0, 0, 0, 0);

    return departure === date;
  };

  const getNumVacancies = useCallback(async () => {
    if (!allocations) {
      setNumVacancies("No allocation has been set for this date");
    }
    if (allocations.length >= 1) {
      // TODO: properly handle multiple pitches
      let numVacancies = 0;
      for (const allocation of allocations) {
        if (allocation.vacancies) {
          numVacancies += allocation.vacancies;
        } else {
          if (allocation.vacancies !== 0) {
            errorLogger(
              "Vacancies is not defined in some allocations " + allocation.id
            );
          }
        }
      }
      setNumVacancies(numVacancies.toString());
    } else {
      setNumVacancies("No allocation has been set for this date");
    }
  }, [allocations, errorLogger]);
  const [arriveToday, setArriveToday] = useState<Bookings>([]);
  const [departToday, setDepartToday] = useState<Bookings>([]);
  const [numArriveToday, setNumArriveToday] = useState(0);
  const [numDepartToday, setNumDepartToday] = useState(0);

  useEffect(() => {
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [unsubscribe]);
  useEffect(() => {
    const getTodaysAllocation = async () => {
      const date: Date = DateTime.utc().startOf("day").toJSDate();
      if (unsubscribeRef) {
        unsubscribeRef.current();
      }
      const newUnsubscribe = await props.db
        .collection("sites")
        .doc(props.siteId)
        .collection("pricingAllocation")
        .where("date", "==", date)
        .onSnapshot((snapshot) => {
          const allocations: Allocations = [];
          for (const doc of snapshot.docs) {
            allocations.push(doc.data() as IAllocation);
          }
          setAllocations(allocations);
        });
      setUnsubscribe(() => newUnsubscribe);
    };
    if (props.dataLoaded === true) {
      setArriveToday(props.upcomingArrivals.filter(getTodaysArrivals));
      setDepartToday(props.upcomingDepartures.filter(getTodaysDepartures));
      getTodaysAllocation();
    }
    return () => {
      if (unsubscribeRef) {
        unsubscribeRef.current();
      }
    };
  }, [
    props.dataLoaded,
    props.upcomingArrivals,
    props.upcomingDepartures,
    props.db,
    props.siteId,
    unsubscribeRef,
  ]);
  useEffect(() => {
    unsubscribeRef.current = unsubscribe;
  }, [unsubscribe]);
  useEffect(() => {
    setNumArriveToday(arriveToday.length);
  }, [arriveToday]);
  useEffect(() => {
    setNumDepartToday(departToday.length);
  }, [departToday]);
  useEffect(() => {
    getNumVacancies();
  }, [allocations, getNumVacancies]);
  // const availability: boolean = numVacancies > 0
  return (
    <div>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div id="dashboardGrid" className="main-container">
        <h2>Dashboard</h2>
        <Typography variant="h4" component="h2" mt={1.5} mb={1.5}>
          {DateTime.local()
            .setLocale("en-GB")
            .toLocaleString(DateTime.DATE_FULL)}
        </Typography>
        <Headline
          dataLoaded={props.dataLoaded}
          arrivals={numArriveToday}
          departures={numDepartToday}
          vacancies={numVacancies}
        />
        <div id="update-container">
          <div id="upcoming">
            <Upcoming
              dataLoaded={props.dataLoaded}
              arrivals={props.upcomingArrivals}
              departures={props.upcomingDepartures}
            />
          </div>
          <div id="recent">
            <Recent
              dataLoaded={props.dataLoaded}
              bookings={props.recentBookings}
            />
          </div>
        </div>
        <Performance
          dataLoaded={props.dataLoaded}
          TY={props.TY}
          LY={props.LY}
        />
      </div>
    </div>
  );
};

export default Dashboard;
