import firebase from "firebase/compat/app";
import { DateTime } from "luxon";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Accommodationtypes,
  IBooking,
  Bookings,
  IEventClickInfo,
  IAccommodationtype,
} from "@bookingflow/types";
import { convertTimestamp } from "@bookingflow/utils";
import NativeSelect from "@mui/material/NativeSelect";
import InputLabel from "@mui/material/InputLabel";
import AddBooking from "./AddBooking";
import "./Calendar.css";
import { EventSourceInput } from "@fullcalendar/react";
import MonthCalendar from "./MonthCalendar";
// must manually import the stylesheets for each plugin

export interface ICalendarProps {
  accommodations: Accommodationtypes;
  dataLoaded: boolean;
  siteID: string;
  db: firebase.firestore.Firestore;
  func: firebase.functions.Functions;
}

const Calendar: FC<ICalendarProps> = (props: ICalendarProps) => {
  const [arrivals, setArrivals] = useState<Bookings>([]);
  const [selectedPitchType, setSelectedPitchType] = useState("all");
  const [events, setEvents] = useState<EventSourceInput>([]);
  const [currentDate, setCurrentDate] = useState(
    DateTime.local().startOf("month").toJSDate()
  );
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editID, setEditID] = useState("");

  const handleEditClose = () => {
    setEditModalOpen(false);
  };
  const handleSelectSlots = async (slotsInfo: any) => {
    setEditModalOpen(true);
  };

  const handleSelectEvent = (slotEvent: IEventClickInfo) => {
    setEditModalOpen(true);
    setEditID(slotEvent.event.id);
  };
  useEffect(() => {
    const dayMillis = 24 * 60 * 60 * 1000
    const monthStart = new Date(currentDate.getTime() - 42 * dayMillis);
    const monthEnd = new Date(currentDate.getTime() + 48 * dayMillis);
    const unsubscribe = props.db
      .collection("sites")
      .doc(props.siteID)
      .collection("transactional")
      .where("arrive", "<=", monthEnd)
      .where("arrive", ">=", monthStart)
      .onSnapshot((querySnapshot) => {
        const arrivals = [];
        for (const doc of querySnapshot.docs) {
          const arrival = convertTimestamp(doc.data()) as unknown as IBooking;
          // filter out cancelled bookings
          // can't do two inequality queries on the same
          // field in firestore apparently
          if (arrival.cancelled === false && arrival.status !== "hold") {
            arrivals.push(arrival);
          }
        }
        setArrivals(arrivals);
      });
    return () => unsubscribe();
  }, [currentDate, props.db, props.siteID]);
  const getEvents = useCallback(() => {
    if (selectedPitchType === "all") {
      const eventList = arrivals.map((d: IBooking, i: number) => ({
        title: `${d.firstname} ${d.lastname}`,
        start: new Date(d.arrive),
        end: new Date(d.depart),
        allDay: true,
      }));
      setEvents(eventList);
    } else {
      const filteredArrivals = arrivals.filter(
        (d) => d.accommodationid === selectedPitchType
      );
      const eventList = filteredArrivals.map((d: IBooking, i: number) => ({
        title: `${d.firstname} ${d.lastname}`,
        start: new Date(d.arrive),
        end: new Date(d.depart),
        allDay: true,
      }));
      setEvents(eventList);
    }
  }, [arrivals, selectedPitchType]);
  useEffect(() => {
    getEvents();
  }, [getEvents, arrivals]);
  const handlePitchTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const target = event.currentTarget;
    const { value } = target;
    setSelectedPitchType(value);
  };
  const createSelectItems = () => {
    const items = [
      <option key="placeholder" value="all">
        All Accommodation Types
      </option>,
    ];
    props.accommodations.forEach((d: IAccommodationtype) => {
      items.push(
        <option key={d.id} value={d.id}>
          {d.accommodation}
        </option>
      );
    });

    return items;
  };
  return (
    <div className="main-container">
      <Helmet>
        <title>Calendar</title>
      </Helmet>
      <h2>Calendar</h2>
      <div id="accommodation-select">
        <InputLabel variant="standard" htmlFor="pitch_group">
          Accommodation Type
        </InputLabel>
        <NativeSelect
          onChange={handlePitchTypeChange}
          value={selectedPitchType}
          name="pitch_group"
          id="pitch_group"
        >
          {createSelectItems()}
        </NativeSelect>
      </div>
      <MonthCalendar
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        events={events}
        handleSelectEvent={handleSelectEvent}
        handleSelectSlots={handleSelectSlots}
      />

      <AddBooking
        accommodations={props.accommodations}
        dataLoaded={props.dataLoaded}
        db={props.db}
        editID={editID}
        addOpen={editModalOpen}
        handleClose={handleEditClose}
        siteId={props.siteID}
        func={props.func}
      />
    </div>
  );
};

export default Calendar;
