import Button from "@mui/material/Button";
import React from "react";
import "./Upload.css";

export interface IUploadProps {
  handleFiles: (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => Promise<void>;
  filetype: string;
  siteId: string;
}

const Upload = (props: IUploadProps) => {
  return (
    <div id="upload">
      <Button variant="contained" onClick={props.handleFiles}>
        Upload File
      </Button>
    </div>
  );
};

export default Upload;
