import firebase from "firebase/compat/app";
import { DateTime } from "luxon";
import React, {
  useCallback,
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Column } from "react-table";
import { Data } from "./Table";
import DataGrid from "./DataGrid";
import { IBooking, Bookings } from "@bookingflow/types";
import { config } from "@bookingflow/config";
import { GridFilterItem } from "@mui/x-data-grid";
import { WhereFilterOp } from "@google-cloud/firestore";
interface IBookingTableProps {
  db: firebase.firestore.Firestore;
  columns: Array<Column<Data>>;
  siteId: string;
  dataLoaded: boolean;
}

const BookingTable: FC<IBookingTableProps> = (props: IBookingTableProps) => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState<Bookings>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [rowCount, setRowCount] = useState<number>(pageSize + 1);
  const [unsubscribe, setUnsubscribe] = useState<() => () => void>(
    () => () => {}
  );
  const unsubscribeRef = useRef(unsubscribe);
  const [startDoc, setStartDoc] = useState<
    | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    | undefined
  >(undefined);
  const [endDoc, setEndDoc] = useState<
    | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    | undefined
  >(undefined);

  type FilterValue = "true" | "false";
  // update subscription on filter and sort
  const fetchBookings = useCallback(async (
    filter: GridFilterItem | null,
    sortBy: string,
    direction: "asc" | "desc",
    pageIndex: number | undefined,
    page: number,
    pageSize: number,
    endDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | undefined,
    startDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | undefined,
    unsubscribeRef: MutableRefObject<() => () => void>,
  ): Promise<void> => {
    setLoading(true);
    const valueMapping = {
      true: true,
      false: false,
    };
    // update table data
    const updateTable = async (
      snapshot: firebase.firestore.QuerySnapshot
    ) => {
      if (!snapshot.empty) {
        // set start and end docs for pagination
        const firstVisible = snapshot.docs[0];
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];
        if (firstVisible !== startDoc) {
          setStartDoc(firstVisible);
        }
        if (lastVisible !== endDoc) {
          setEndDoc(lastVisible);
        }
        const bookings: Bookings = [];
        // set bookings
        for (const doc of snapshot.docs) {
          const booking = Object.assign(doc.data(), {
            id: doc.id,
          });
          if (
            booking.status === "hold" &&
            booking.created.toDate() <=
              DateTime.utc()
                .minus({ minutes: config.timeoutMinutes })
                .toJSDate()
          ) {
            //ignore old hold bookings
            //not ideal to have less than `limit` number of bookings
          } else {
            // for new pages push bookings
            if (!pageIndex){
              bookings.push(booking as IBooking);
            } else if (page < pageIndex){
              // if going backwards reverse the order
              bookings.unshift(booking as IBooking);
            } else {
              // otherwise keep the order
              bookings.push(booking as IBooking);
            }
          }
        }
        setData(bookings);
        // determine whether there is a next page
        const collectionRef = props.db
          .collection("sites")
          .doc(props.siteId)
          .collection("transactional");
        const nextDoc = await collectionRef
          .orderBy(sortBy, direction)
          .startAfter(lastVisible)
          .limit(1)
          .get();
        // ensure the next page button is disabled when there is no next page
        if (nextDoc.empty) {
          setRowCount((page + 1) * pageSize);
        } else {
          // keep it thinking there is always one more row
          setRowCount((page + 1) * pageSize + 1);
        }
        //set Data
        setLoading(false);
      } else {
        setData([]);
        setRowCount(page);
        setLoading(false);
      }
    };
    //remove existing subscriptions
    if (unsubscribeRef) {
      unsubscribeRef.current();
    }
    const collectionRef = props.db
      .collection("sites")
      .doc(props.siteId)
      .collection("transactional");
    // function that constructs a query based on current filter
    const getFilterQuery = (
      collectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    ):
      | firebase.firestore.Query<firebase.firestore.DocumentData>
      | firebase.firestore.CollectionReference<firebase.firestore.DocumentData> => {
      if (
        filter &&
        filter.columnField &&
        filter.operatorValue &&
        filter.value
      ) {
        const operator = filter.operatorValue;
        if (!operator) {
          return collectionRef;
        }
        let value;
        if (Object.keys(valueMapping).includes(filter.value)) {
          value = valueMapping[filter.value as FilterValue];
        } else {
          value = filter.value;
        }
        return collectionRef.where(
          filter.columnField,
          operator as WhereFilterOp,
          value
        );
      } else {
        return collectionRef;
      }
    };
    // function that returns start and end docs for pagination
    const getStartQuery = (
      query: firebase.firestore.Query<firebase.firestore.DocumentData>
    ): firebase.firestore.Query<firebase.firestore.DocumentData> => {
      // if we are on the first page
      if (pageIndex === undefined || page === 0) {
        return query;
      }
      if (page === pageIndex) {
        // if we're staying on the same page, we need to start from the first doc we fetched
        if (startDoc) {
          // if we have a start doc, we need to start from it
          return query.startAt(startDoc);
        } else {
          // otherwise we don't need to start from anything
          return query;
        }
      } else if (page > pageIndex) {
        // if we are going to the next page, we need to start after the last doc we fetched
        return query.startAfter(endDoc);
      } else {
        // if we are going to the previous page, we need to end before the first doc we fetched
        return query.startAfter(startDoc);
      }
    };
    const getOrderQuery = (
      query: firebase.firestore.Query<firebase.firestore.DocumentData>,
      sortBy: string, 
      direction: "asc" | "desc"
    ) => {
      if (pageIndex === undefined || page === 0) {
        const orderQuery = filterQuery.orderBy(sortBy, direction);
        return orderQuery
      }
      // if going backwards need to reverse sort to limit to last x
      // once retrieved it will then be reverse sorted again to get the correct order
      if (page < pageIndex) {
        const reverseDirection = direction === "asc" ? "desc" : "asc";
        const orderQuery = filterQuery.orderBy(sortBy, reverseDirection);
        return orderQuery
      } else {
        const orderQuery = filterQuery.orderBy(sortBy, direction);
        return orderQuery
      }
    }
    // construct filter query
    const filterQuery = getFilterQuery(collectionRef);
    const orderQuery = getOrderQuery(filterQuery, sortBy, direction);
    const startQuery = getStartQuery(orderQuery);
    const limitQuery = startQuery.limit(pageSize);
    const newUnsubscribe = await limitQuery.onSnapshot(
      (snapshot: firebase.firestore.QuerySnapshot) => {
        updateTable(snapshot);
      }
    );
    setUnsubscribe(() => newUnsubscribe);
  }, [props.db, props.siteId])
  useEffect(() => {
    // fetch bookings on first mount
    fetchBookings(null, "arrive", "desc", 0, 0, 10, undefined, undefined, unsubscribeRef);
    // remove subscriptions on unmount
      if (unsubscribeRef) {
        unsubscribeRef.current();
      }
    }, [fetchBookings]);
  // update the unsubscribeRef
  useEffect(() => {
    unsubscribeRef.current = unsubscribe;
  }, [unsubscribe]);
  // memoize the data
  const memoizedData = React.useMemo(() => data, [data]);
  return (
    <div>
      <DataGrid
        siteId={props.siteId}
        data={memoizedData}
        setPage={setPage}
        setPageSize={setPageSize}
        page={page}
        endDoc={endDoc}
        startDoc={startDoc}
        unsubscribeRef={unsubscribeRef}
        loading={loading}
        rowCount={rowCount}
        pageSize={pageSize}
        fetchBookings={fetchBookings}
      />
    </div>
  );
};
export default BookingTable;
