import React, { useState } from "react";
import "./Setup.css";
import SetupImport from "./SetupImport";
import SetupAccommodation from "./SetupAccommodation";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Welcome from "./Welcome";
import firebase from "firebase/compat/app";
import { Accommodationtypes } from "@bookingflow/types";

export interface ISetupProps {
  siteId: string;
  db: firebase.firestore.Firestore;
  accommodations: Accommodationtypes;
}

const Setup = (props: ISetupProps) => {
  const [welcomeVisible, setWelcomeVisible] = useState(true);
  const [importVisible, setImportVisible] = useState(false);
  const [setupAccommodationVisible, setAccommodationVisible] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(true);
  return (
    <Dialog id="setupModal" open={dialogOpen}>
      <Slide
        direction="left"
        mountOnEnter
        unmountOnExit
        in={welcomeVisible}
        children={Welcome({
          setWelcomeVisible,
          setImportVisible,
          db: props.db,
        })}
      />
      <Slide
        direction="left"
        mountOnEnter
        unmountOnExit
        in={importVisible}
        children={SetupImport({
          siteId: props.siteId,
          initialSetup: true,
          setWelcomeVisible,
          setImportVisible,
          setAccommodationVisible,
        })}
      />
      <Slide
        direction="left"
        mountOnEnter
        unmountOnExit
        in={setupAccommodationVisible}
        children={SetupAccommodation({
          accommodations: props.accommodations,
          siteId: props.siteId,
          db: props.db,
          setAccommodationVisible,
          setImportVisible,
          setDialogOpen,
        })}
      />
    </Dialog>
  );
};

export default Setup;
