import SignOut from "./firebase/SignOut";
import React from "react";
import Spinner from "./Spinner";
import "./Loading.css";
import { Auth } from "firebase/auth";

interface ILoadingProps {
  auth: Auth;
}

const Loading = (props: ILoadingProps) => {
  return (
    <div id="Loading">
      <h5 className="center-align signinBrandText">BookingFlow</h5>
      <Spinner id="spinner" />
      <SignOut auth={props.auth} />
    </div>
  );
};

export default Loading;
