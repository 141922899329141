import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

//import { materialStyles } from './materialStyles';
import "./PricingCards.css";

import pricing from "./pricing.json";
//const pricing = require("./pricing.json");

export interface price {
  name: string;
  amount: number;
}

const basePremiumPrice = pricing.find(
  (price: price) => price.name === "bookingflow premium",
)?.amount;
const basePlusPrice = pricing.find(
  (price: price) => price.name === "bookingflow plus",
)?.amount;
let premiumPrice = "";
let plusPrice = "";
if (basePlusPrice && basePremiumPrice) {
  premiumPrice = (basePremiumPrice / 100).toString();
  plusPrice = (basePlusPrice / 100).toString();
}

@customElement("pricing-cards")
export class PricingCards extends LitElement {
  @property()
  web = "true";

  /*static styles = [
    ...materialStyles, 
    css`
      .plans li {
        list-style-type: none;
      }
      .card-content {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .card-content,
      .card-actions {
        padding: 16px;
      }
      .pricing-button {
        margin-top: auto;
      }
      .mdc-card {
        height: 100%;
      }`
  ];*/

  override render() {
    let getStarted;
    let contactSales;
    if (this.web === "true") {
      getStarted = html` <div class="pricing-button">
        <a href="/get-started"
          ><button class="waves-effect waves-light btn">Get Started</button></a
        >
      </div>`;
      contactSales = html` <div class="pricing-button">
        <a href="/sales">
          <button class="waves-effect waves-light btn">Contact Sales</button>
        </a>
      </div>`;
    }
    return html` <section id="pricing-plans" class="plans">
      <div class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone"
          >
            <div class="mdc-card">
              <div class="card-content center-align">
                <h4 class="plan-name">Basic</h4>
                <div class="price">
                  <h1 style="" class="price-formatting -h1">
                    <sup>£</sup>${premiumPrice}
                  </h1>
                  <span class="price-per" style="">per member per month</span>
                  <span class="price-per" style="">billed annually</span>
                </div>
                <p>Our basic version</p>
                <div class="auto left-align">
                  <ul>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        /></svg
                      >Up to 10 accommodation units
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        /></svg
                      >Arrivals Dashboard
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        /></svg
                      >Searchable Bookings
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        /></svg
                      ><span class="bronze">Bronze</span> Support
                    </li>
                  </ul>
                </div>
                ${getStarted}
              </div>
            </div>
          </div>
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone"
          >
            <div class="mdc-card">
              <div class="card-content center-align">
                <h4 class="plan-name">Premium</h4>
                <div class="price">
                  <h1 style="" class="price-formatting -h1">
                    <sup>£</sup>${plusPrice}
                  </h1>
                  <span class="price-per" style="">per member per month</span>
                  <span class="price-per" style="">billed annually</span>
                </div>
                <p>All basic features plus...</p>
                <div class="auto left-align">
                  <ul>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        /></svg
                      >Up to 25 accommodation units
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        /></svg
                      >Custom Reporting
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        /></svg
                      >Online Bookings
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        /></svg
                      ><span class="silver">Silver</span> Support
                    </li>
                  </ul>
                </div>
                ${getStarted}
              </div>
            </div>
          </div>
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone"
          >
            <div class="mdc-card">
              <div class="card-content center-align">
                <h4 class="plan-name">Enterprise</h4>
                <div class="price">
                  <h1 style="" class="price-formatting -h1">Let's Talk!</h1>
                  <span class="price-per" style="">per member per month</span>
                  <span class="price-per" style="">billed annually</span>
                </div>
                <p>All plus features plus...</p>
                <div class="auto left-align">
                  <ul>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        /></svg
                      >More than 50 accommodation units
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        /></svg
                      >AD integration
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        /></svg
                      >User Administration
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        /></svg
                      ><span class="silver">Platinum</span> Support
                    </li>
                  </ul>
                </div>
                ${contactSales}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>`;
  }
  override createRenderRoot() {
    /**
     * Render template without shadow DOM. Note that shadow DOM features like
     * encapsulated CSS and slots are unavailable.
     */
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "pricing-cards": PricingCards;
  }
}
