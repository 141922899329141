import "@bookingflow/components";
import "@bookingflow/components/dist/PricingCards/PricingCards.css";
import React from "react";
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "pricing-cards": PricingCardAttributes;
    }
    interface PricingCardAttributes extends React.HTMLAttributes<HTMLElement> {
      web: string;
    }
  }
}

export const SignupPricingCards = () => {
  return <pricing-cards web="false" />;
};
