import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import DeleteBooking from "./DeleteBooking";
import { GridRowId, useGridApiContext } from "@mui/x-data-grid";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import "firebase/compat/firestore";
interface IGridToolbarProps {
  siteId: string;
}

const GridToolbar = (props: IGridToolbarProps) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteIds, setDeleteIds] = useState<string[]>([]);
  const apiRef = useGridApiContext();
  const func = firebase.app().functions("europe-west2");
  const db = firebase.firestore();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDeleteClose = () => {
    setDeleteLoading(false);
    setDeleteModalOpen(false);
  };
  const deleteBookings = () => {
    const selectedRows = Array.from(apiRef.current.getSelectedRows());
    const selectedRowIds = selectedRows.map((row: [GridRowId, any]) => {
      return row[1].id;
    });
    setDeleteIds(selectedRowIds);
    setDeleteModalOpen(true);
  };

  return (
    <React.Fragment>
      <Grid justifyContent="flex-start" container spacing={24}>
        <Grid item>
          <Button startIcon={<DeleteIcon />} onClick={deleteBookings}>
            Delete Selected
          </Button>
        </Grid>
      </Grid>
      <DeleteBooking
        deleteIds={deleteIds}
        db={db}
        deleteOpen={deleteModalOpen}
        handleClose={handleDeleteClose}
        loading={deleteLoading}
        setLoading={setDeleteLoading}
        siteId={props.siteId}
        func={func}
      />
    </React.Fragment>
  );
};
export default GridToolbar;
