import firebase from "firebase/compat/app";
import {
  DataGrid,
  GridSortModel,
  GridFilterModel,
  getGridNumericOperators,
  getGridBooleanOperators,
  GridFilterInputValue,
  GridFilterItem,
  getGridDateOperators,
  GridColumns,
  GridEnrichedColDef,
} from "@mui/x-data-grid";
import { IFirestoreBooking } from "@bookingflow/types";
import "./Table.css";
import { DateTime } from "luxon";
import React, { MutableRefObject, useMemo, useState } from "react";
import GridToolbar from "./GridToolbar";
import "./DataGrid.css";

interface ITableProps {
  siteId: string;
  data: any;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  rowCount: number;
  pageSize: number;
  page: number;
  loading: boolean;
  endDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | undefined;
  startDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | undefined;
  unsubscribeRef: MutableRefObject<() => () => void>,
  fetchBookings: (
    filter: GridFilterItem | null,
    sortBy: string,
    direction: "asc" | "desc",
    pageIndex: number | undefined,
    page: number,
    pageSize: number,
    endDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | undefined,
    startDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | undefined,
    unsubscribeRef: MutableRefObject<() => () => void>,
  ) => Promise<void>
}

const BookingflowDataGrid = (props: ITableProps) => {
  const [sortBy, setSortBy] = useState("arrive");
  const [filter, setFilter] = useState<GridFilterItem | null>(null);
  const defaultDirection = "desc"
  // Render the UI for your table
  const convertedData = props.data.map((row: IFirestoreBooking) => {
    return {
      ...row,
      created: DateTime.fromJSDate(row.created.toDate()).toISODate(),
      arrive: DateTime.fromJSDate(row.arrive.toDate()).toISODate(),
      depart: DateTime.fromJSDate(row.depart.toDate()).toISODate(),
      cancelled: row.cancelled?.toString(),
    };
  });
  const setPageHandler = (newPage: any) => {
    fetchBookings(filter, sortBy, defaultDirection, props.page, newPage, pageSize, endDoc, startDoc, unsubscribeRef);
    props.setPage(newPage);
  };
  const setPageSizeHandler = (newPageSize: any) => {
    fetchBookings(filter, sortBy, defaultDirection, props.page, props.page, pageSize, endDoc, startDoc, unsubscribeRef);
  };

  const firestoreStringOperators = useMemo(
    () => [
      {
        label: "does not equal",
        value: "!=",
        getApplyFilterFn: (filterItem: GridFilterItem) => {
          return null;
        },
        InputComponent: GridFilterInputValue,
      },
      {
        label: "equals",
        value: "==",
        getApplyFilterFn: (filterItem: GridFilterItem) => {
          return null;
        },
        InputComponent: GridFilterInputValue,
      },
    ],
    []
  );
  const firestoreNumericOperators = getGridNumericOperators().filter(
    (operator) =>
      operator.value !== "isEmpty" && operator.value !== "isNotEmpty"
  );

  const columns = useMemo(
    () => [
      {
        headerName: "booking id",
        field: "id",
        sortable: false,
        filterable: false,
        editable: false,
        width: 200,
      },
      {
        headerName: "first name",
        field: "firstname",
        filterOperators: firestoreStringOperators,
        type: "string",
        width: 100,
      },
      {
        headerName: "last name",
        filterOperators: firestoreStringOperators,
        type: "string",
        field: "lastname",
      },
      {
        headerName: "accommodation name",
        filterOperators: firestoreStringOperators,
        type: "string",
        field: "accommodation",
        width: 200,
      },
      {
        headerName: "arrival",
        filterOperators: getGridDateOperators(),
        type: "date",
        field: "arrive",
      },
      {
        headerName: "departure",
        filterOperators: firestoreNumericOperators,
        type: "date",
        field: "depart",
      },
      {
        headerName: "created",
        filterOperators: firestoreNumericOperators,
        type: "date",
        field: "created",
      },
      {
        headerName: "amount",
        filterOperators: firestoreNumericOperators,
        type: "number",
        field: "accommodationcostamount",
      },
      {
        headerName: "adults",
        field: "adults",
        filterOperators: firestoreNumericOperators,
      },
      {
        headerName: "children",
        filterOperators: firestoreNumericOperators,
        type: "number",
        field: "children",
      },
      {
        headerName: "infants",
        filterOperators: firestoreNumericOperators,
        type: "number",
        field: "infants",
      },
      {
        headerName: "pitch number",
        filterOperators: firestoreNumericOperators,
        type: "number",
        field: "pitch",
      },
      {
        headerName: "status",
        filterOperators: firestoreStringOperators,
        type: "string",
        field: "status",
      },
      {
        headerName: "email",
        field: "email",
        filterOperators: firestoreStringOperators,
        type: "string",
        width: 200,
      },
      {
        headerName: "telephone",
        field: "telephone",
        filterOperators: firestoreStringOperators,
        type: "string",
        width: 200,
      },
      {
        headerName: "cancelled",
        field: "cancelled",
        filterOperators: getGridBooleanOperators(),
        type: "string",
      },
    ],
    [firestoreNumericOperators, firestoreStringOperators]
  );
  const { setPage, pageSize, endDoc, startDoc, unsubscribeRef, fetchBookings } = props;
  
  const onFilterChange = React.useCallback(
    (filterModel: GridFilterModel) => {
      const dateOperatorMapping = {
        is: "==",
        not: "!=",
        before: "<",
        after: ">",
        onOrAfter: ">=",
        onOrBefore: "<=",
      };
      const filterItem: GridFilterItem = filterModel.items[0];
      if (!filterItem || !filterItem.value) {
        setFilter(null);
        return;
      }
      const gridColumns = columns as GridColumns;
      const column: GridEnrichedColDef | undefined = gridColumns.find(
        (column: GridEnrichedColDef) => filterItem.columnField === column.field
      );
      const type = column?.type;
      if (type === "string") {
        const filter = {
          columnField: filterItem.columnField,
          operatorValue: filterItem.operatorValue,
          value: filterItem.value.toString(),
        };
        // reset pagination to 0
        const newPage = 0;
        if (filterItem.operatorValue === "!=") {
          const newSortBy = filterItem.columnField
          const newDirection = "asc"
          fetchBookings(filter, newSortBy, newDirection, undefined, newPage, pageSize, endDoc, startDoc, unsubscribeRef);

        }
        setPage(0);
      } else if (type === "boolean") {
        if (filterItem?.value === "any") {
          const filter = null;
          const newPage = 0;
          fetchBookings(filter, sortBy, defaultDirection, undefined, newPage, pageSize, endDoc, startDoc, unsubscribeRef);
          setPage(0);
        } else {
          console.log(filterItem.value)
          const filter = {
            columnField: filterItem.columnField,
            operatorValue: filterItem.operatorValue,
            value: filterItem.value === "true",
          };
          // reset pagination to 0
          const newPage = 0;
          fetchBookings(filter, sortBy, defaultDirection, undefined, newPage, pageSize, endDoc, startDoc, unsubscribeRef);
          setPage(0);
        }
      } else if (type === "numeric") {
        const value = Number(filterItem.value);
        if (Number.isNaN(value)) {
          const filter = null;
          const newPage = 0;
          fetchBookings(filter, sortBy, defaultDirection, undefined, newPage, pageSize, endDoc, startDoc, unsubscribeRef);
          setPage(0)
        }
        if (filterItem.operatorValue === "=") {
          const filter ={
            columnField: filterItem.columnField,
            operatorValue: "==",
            value: value,
          };
          // reset pagination to 0
          const newPage = 0;
          fetchBookings(filter, sortBy, defaultDirection, undefined, newPage, pageSize, endDoc, startDoc, unsubscribeRef);
          setPage(0)
        } else {
          const filter ={
            columnField: filterItem.columnField,
            operatorValue: filterItem.operatorValue,
            value: value,
          };
          // reset pagination to 0
          const newPage = 0;
          fetchBookings(filter, sortBy, defaultDirection, undefined, newPage, pageSize, endDoc, startDoc, unsubscribeRef);
          setPage(0)
        }
      } else if (type === "date") {
        if (!filterItem.operatorValue) {
          return;
        }
        const operator =
          dateOperatorMapping[
            filterItem.operatorValue as keyof typeof dateOperatorMapping
          ];
        const filter ={
          columnField: filterItem.columnField,
          operatorValue: operator,
          value: new Date(filterItem.value),
        };
        // reset pagination to 0
        const newPage = 0;
        fetchBookings(filter, sortBy, defaultDirection, undefined, newPage, pageSize, endDoc, startDoc, unsubscribeRef);
        setPage(0)
      }
    },
    [columns, setFilter, setPage, defaultDirection, sortBy, pageSize, endDoc, startDoc, unsubscribeRef, fetchBookings]
  );
  const onSortChange = React.useCallback(
    (sortModel: GridSortModel) => {
      if (sortModel.length === 1) {
        const sortBy =  sortModel[0].field;
        const direction = sortModel[0].sort as "asc" | "desc";
        const newPage = 0
        fetchBookings(filter, sortBy, direction, newPage, newPage, pageSize, endDoc, startDoc, unsubscribeRef);
        setSortBy(sortBy);
        setPage(0)
        // reset pagination to start
      } else {
        setSortBy("arrive");
        // reset pagination to start
        const newPage = 0
        fetchBookings(filter, sortBy, defaultDirection, newPage, newPage, pageSize, endDoc, startDoc, unsubscribeRef);
        setPage(0)
      }
    },
    [setSortBy, setPage, filter, sortBy, pageSize, endDoc, startDoc, unsubscribeRef, fetchBookings]
  );
  return (
    <div id="booking-table">
      <div>
        <DataGrid
          rows={convertedData}
          columns={columns as GridColumns}
          sortingMode="server"
          onSortModelChange={onSortChange}
          disableSelectionOnClick
          rowsPerPageOptions={[10, 20, 40, 80, 100]}
          pageSize={props.pageSize}
          rowCount={props.rowCount}
          paginationMode="server"
          page={props.page}
          onPageChange={setPageHandler}
          onPageSizeChange={setPageSizeHandler}
          loading={props.loading}
          filterMode="server"
          checkboxSelection={true}
          onFilterModelChange={onFilterChange}
          components={{
            Toolbar: () => <GridToolbar siteId={props.siteId} />,
          }}
        />
      </div>
    </div>
  );
};

export default BookingflowDataGrid;
