import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import NativeSelect from "@mui/material/NativeSelect";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { DateTime, Interval } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Accommodationtypes,
  IAccommodationtype,
  IAllocation,
  IEventClickInfo,
  Allocations,
} from "@bookingflow/types";
import {
  convertTimestamp,
  convertToUTCDate,
  updateAllocation,
  makeUnavailable,
} from "@bookingflow/utils";
import { EventSourceInput, DateSelectArg } from "@fullcalendar/react";
import MonthCalendar from "./MonthCalendar";
import "./Allocation.css";
import { InputLabel } from "@mui/material";
import Button from "@mui/material/Button";
export interface IRatesProps {
  accommodations: Accommodationtypes;
  db: firebase.firestore.Firestore;
  siteId: string;
  dataLoaded: boolean;
  func: firebase.functions.Functions;
}

export interface ISlotsInfo {
  start: Date;
  end: Date;
  startStr: string;
  endStr: string;
  allDay: boolean;
  resource?: any;
  jsEvent: MouseEvent;
  view: any;
}
/*
const updateAllocations = async(slotRange: IterableIterator<number>, UTCDate: Date, db: firebase.firestore.Firestore, siteId: string, selectedPitchType: string, maxAllocation: number, pitchprice: number, adultprice: number, childprice: number, infantprice: number, minNights: number) => {
  for (const i of slotRange) {
    const date = DateTime.fromJSDate(UTCDate).plus({ days: i }).toJSDate()
    await updateAllocation(db, date, siteId,  selectedPitchType, maxAllocation, pitchprice, adultprice, childprice, infantprice, minNights)
  }
}
*/
const Rates = (props: IRatesProps) => {
  const [selectedPitchType, setSelectedPitchType] = useState(
    props.accommodations.length > 0 ? props.accommodations[0].id : ""
  );
  const [pitchprice, setPitchPrice] = useState<number | undefined>(undefined);
  const [adultprice, setAdultPrice] = useState<number | undefined>(undefined);
  const [childprice, setChildPrice] = useState<number | undefined>(undefined);
  const [infantprice, setInfantPrice] = useState<number | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [maxAllocation, setMaxAllocation] = useState<number | undefined>(
    undefined
  );
  const [minNights, setMinNights] = useState<number | undefined>(undefined);
  const [maxNights, setMaxNights] = useState<number | undefined>(undefined);
  const [allocations, setAllocations] = useState<Allocations>([]);
  const [slotEvent, setSlotEvent] = useState<IEventClickInfo | undefined>(
    undefined
  );
  const [slotsInfo, setSlotsInfo] = useState<DateSelectArg | undefined>(
    undefined
  );
  const [events, setEvents] = useState<EventSourceInput>([]);
  const [message, setMessage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [unavailable, setUnavailable] = useState(false);
  const [currentDate, setCurrentDate] = useState(
    DateTime.utc().startOf("month").toJSDate()
  );
  const errorLogger = props.func.httpsCallable("error");
  function handleSelectSlots(selectInfo: DateSelectArg) {
    setModalOpen(true);
    setSlotEvent(undefined);
    setSlotsInfo(selectInfo);
  };

  function handleSelectEvent(slotEvent: IEventClickInfo){
    // TODO: if slot.start is not in valid dates (e.g past) do not allow adjustment use toast
    setModalOpen(true);
    setSlotsInfo(undefined);
    setSlotEvent(slotEvent);
  };

  function handleClose() {
    setModalOpen(false);
  };
  const handleSubmitKeyDown = async (
    e: React.KeyboardEvent<HTMLFormElement>
  ) => {
    /**
     * Note: Pressing enter in some input in a browser forms
     *  triggers onClick on the first child button
     *
     * So, prevent `enter` from triggering `onClick` on any buttons
     *  and instead trigger onSubmit
     */
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };
  const handleSubmitButton = async (e: React.MouseEvent<HTMLFormElement>) => {
    /**
     * Prevent submit from reloading the page
     */
    e.preventDefault();
    e.stopPropagation();
    handleSubmit();
  };
  async function handleSubmit() {
    // disable button while performing submit action
    setSpinner(true);
    const button: HTMLInputElement | null =
      document.querySelector("#allocation-submit");
    if (!button) {
      console.error("DOM element with #allocation-submit doesn't exist");
      return;
    }
    button.disabled = true;
    // if there are multiple events then run in parallel
    if (slotEvent === undefined && slotsInfo !== undefined) {
      const numSlots = Interval.fromDateTimes(
        slotsInfo.start,
        slotsInfo.end
      ).length("days");
      const slotRange = Array(numSlots).keys();
      const slotArray = Array.from(slotRange);
      const UTCDate = convertToUTCDate(slotsInfo.start);
      if (unavailable) {
        const makeUnavailable = props.func.httpsCallable(
          "makeUnavailableRequest"
        );
        const data = {
          slotArray,
          UTCDate,
          siteId: props.siteId,
          selectedPitchType,
        };
        await makeUnavailable(data);
      }
      if (
        !selectedPitchType ||
        (!maxAllocation && maxAllocation !== 0) ||
        (!pitchprice && pitchprice !== 0) ||
        (!adultprice && adultprice !== 0) ||
        (!childprice && childprice !== 0) ||
        (!infantprice && infantprice !== 0) ||
        (!minNights && minNights !== 0)
      ) {
        errorLogger({
          error: "form has somehow been submitted without required fields",
        });
        setSpinner(false);
        return;
      }
      const updateAllocations = props.func.httpsCallable(
        "updateAllocationsRequest"
      );
      const data = {
        slotArray,
        UTCDate,
        siteId: props.siteId,
        selectedPitchType,
        maxAllocation,
        pitchprice,
        adultprice,
        childprice,
        infantprice,
        minNights,
        maxNights,
      };
      await updateAllocations(data);
      //updateAllocations(slotRange, UTCDate, props.db, props.siteId, selectedPitchType, maxAllocation, pitchprice, adultprice, childprice, infantprice, minNights)
    } else if (slotEvent !== undefined && slotsInfo === undefined) {
      // update an existing slot
      if (!slotEvent.event.start) {
        console.error("no start date was specified");
        setSpinner(false);
        return;
      }
      const date = convertToUTCDate(slotEvent.event.start);
      if (unavailable) {
        await makeUnavailable(
          props.db,
          errorLogger,
          date,
          props.siteId,
          selectedPitchType
        );
      } else {
        if (
          !selectedPitchType ||
          (!maxAllocation && maxAllocation !== 0) ||
          (!pitchprice && pitchprice !== 0) ||
          (!adultprice && adultprice !== 0) ||
          (!childprice && childprice !== 0) ||
          (!infantprice && infantprice !== 0) ||
          !minNights ||
          !maxNights
        ) {
          errorLogger({
            error: "form has somehow been submitted without required fields",
          });
          return;
        }
        let error: any | undefined = undefined;
        await updateAllocation(
          props.db,
          errorLogger,
          date,
          props.siteId,
          selectedPitchType,
          maxAllocation,
          pitchprice,
          adultprice,
          childprice,
          infantprice,
          minNights,
          maxNights
        ).catch((err: Error) => {
          error = err
        });
        if (error) {
          setMessage(error.message);
          setSnackOpen(true);
        }
      }
    }
    setSlotsInfo(undefined);
    setSlotEvent(undefined);
    button.disabled = false;
    setSpinner(false);
    setUnavailable(false);
    handleClose();
  };
  function handleAvailableChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setUnavailable(event.target.checked);
  };
  function handlePitchPriceChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const target = event.currentTarget;
    const { value } = target;
    setPitchPrice(Number(value.toString()));
  };
  function handleAdultPriceChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const target = event.currentTarget;
    const { value } = target;
    setAdultPrice(Number(value));
  };
  function handleChildPriceChange(
    event: React.ChangeEvent<HTMLInputElement>
  ){
    const target = event.currentTarget;
    const { value } = target;
    setChildPrice(Number(value));
  };
  function handleInfantPriceChange (
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const target = event.currentTarget;
    const { value } = target;
    setInfantPrice(Number(value));
  };
  function handleAllocationChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const target = event.currentTarget;
    const { value } = target;
    setMaxAllocation(Number(value));
  };
  function handleMinNightsChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const target = event.currentTarget;
    const { value } = target;
    setMinNights(Number(value));
  };
  function handleMaxNightsChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const target = event.currentTarget;
    const { value } = target;
    setMaxNights(Number(value));
  };

  function handlePitchTypeChange(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    const target = event.currentTarget;
    const { value } = target;
    setSelectedPitchType(value);
  };
  function createSelectItems() {
    const items = [
      <option
        key="placeholder"
        value=""
        disabled={props.accommodations.length > 0}
      >
        Select an accommodation type
      </option>,
    ];
    props.accommodations.forEach((d: IAccommodationtype) => {
      items.push(
        <option key={d.id} value={d.id}>
          {d.accommodation}
        </option>
      );
    });

    return items;
  };
  const getEvents = useCallback(() => {
    const eventList: EventSourceInput = allocations.map(
      (allocation: IAllocation) => ({
        allDay: true,
        end: allocation.date,
        id: allocation.id,
        start: allocation.date,
        backgroundColor: allocation.available ? "#3788d8" : "#EF4444",
        title: `Acc. Price: £${allocation.accommodationprice}\nAdult Price: £${allocation.adultprice}\nChild Price: £${allocation.childprice}\nInfant Price: £${allocation.infantprice}\nMax Allocation: ${allocation.maxAllocation}\nMin Nights: ${allocation.minNights}`,
      })
    );
    setEvents(eventList);
  }, [allocations]);
  const handleSnackClose = (
    event: React.SyntheticEvent | Event,
    reason: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };
  const onSnackClose = () => {
    setSnackOpen(false);
  };
  const { accommodations } = props;
  useEffect(() => {
    setSelectedPitchType(accommodations.length > 0 ? accommodations[0].id : "");
  }, [accommodations]);
  useEffect(() => {
    if (props.dataLoaded) {
      const siteRef = props.db.collection("sites");
      const sites = siteRef.doc(props.siteId);
      const collectionRef = sites.collection("pricingAllocation");
      const dayMillis = 24 * 60 * 60 * 1000
      const monthStart = new Date(currentDate.getTime() -6 * dayMillis);
      const monthEnd = new Date(currentDate.getTime() + 48 * dayMillis);
      const unsubscribe = collectionRef
        .where("date", "<=", monthEnd)
        .where("date", ">=", monthStart)
        .where("accommodationid", "==", selectedPitchType)
        .onSnapshot((snapshot) => {
          const newAllocations = [];
          for (const doc of snapshot.docs) {
            // doc.data() is never undefined for query doc snapshots
            const newAllocation = {
              id: doc.id,
              ...convertTimestamp(doc.data()),
            } as unknown as IAllocation;
            newAllocations.push(newAllocation);
          }
          setAllocations(newAllocations);
        });
      return () => unsubscribe();
    }
    return;
  }, [
    props.dataLoaded,
    currentDate,
    selectedPitchType,
    props.db,
    props.siteId,
  ]);

  useEffect(() => {
    getEvents();
  }, [allocations, getEvents]);
  useEffect(() => {
    handleClose();
  }, [events]);
  useEffect(() => {
    if (maxAllocation === 0) {
      setUnavailable(true);
    }
  }, [maxAllocation]);
  return (
    <div className="main-container">
      <Helmet>
        <title>Allocation and Pricing</title>
      </Helmet>
      <Snackbar
        className="warning-snackbar"
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar">
              <WarningIcon />
              <h6>{message}</h6>
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={onSnackClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
      <div>
        <h2>Allocation</h2>
        <div id="accommodation-select">
          <InputLabel htmlFor="pitch_group">Accommodation Type</InputLabel>
          <NativeSelect
            onChange={handlePitchTypeChange}
            required={!unavailable}
            value={selectedPitchType}
            name="pitch_group"
            id="pitch_group"
          >
            {createSelectItems()}
          </NativeSelect>
        </div>
        {selectedPitchType !== "" && (
          <MonthCalendar
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            events={events}
            handleSelectEvent={handleSelectEvent}
            handleSelectSlots={handleSelectSlots}
          />
        )}
      </div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalOpen}
        onClose={handleClose}
      >
        <div id="allocation-modal" className="bookingflowModal">
          <form
            id="newPriceForm"
            onKeyDown={handleSubmitKeyDown}
            onSubmit={handleSubmitButton}
          >
            <h2>New Price</h2>
            <TextField
              type="number"
              label="Accommodation Price"
              inputProps={{
                min: "0",
                step: ".01",
              }}
              id="pitch"
              name="pitchprice"
              onChange={handlePitchPriceChange}
              required={!unavailable}
            />
            <TextField
              type="number"
              label="Adult Price"
              inputProps={{
                step: ".01",
                min: "0",
              }}
              id="adult"
              name="adultprice"
              onChange={handleAdultPriceChange}
              required={!unavailable}
            />
            <TextField
              type="number"
              label="Child Price"
              id="child"
              inputProps={{
                step: ".01",
                min: "0",
              }}
              name="childprice"
              onChange={handleChildPriceChange}
              required={!unavailable}
            />

            <TextField
              type="number"
              id="infant"
              label="Infant Price"
              inputProps={{
                step: ".01",
                min: "0",
              }}
              name="infantprice"
              onChange={handleInfantPriceChange}
              required={!unavailable}
            />

            <TextField
              type="number"
              id="allocation"
              label="Allocation"
              inputProps={{
                step: "1",
                min: "0",
              }}
              name="allocation"
              onChange={handleAllocationChange}
              required={!unavailable}
            />

            <TextField
              type="number"
              label="Min Nights"
              inputProps={{
                step: "1",
                min: "1",
              }}
              id="minNights"
              name="minNights"
              onChange={handleMinNightsChange}
              required={!unavailable}
            />
            <TextField
              type="number"
              label="Max Nights"
              inputProps={{
                step: "1",
                min: "1",
              }}
              id="maxNights"
              name="maxNights"
              onChange={handleMaxNightsChange}
              required={!unavailable}
            />
            <FormControlLabel
              control={
                <Switch
                  id="allocation-switch"
                  checked={unavailable}
                  onChange={handleAvailableChange}
                  name="checkedAvailability"
                  inputProps={{ "aria-label": "Remove availability" }}
                />
              }
              label="Pause Availability"
            />
            <Button
              className="waves-effect waves-light btn-flat right"
              onClick={handleClose}
            >
              Close
            </Button>
            <LoadingButton
              id="allocation-submit"
              className="waves-effect waves-light btn right"
              form="newPriceForm"
              type="submit"
              value="submit"
              disabled={spinner}
              loading={spinner}
            >
              Submit
            </LoadingButton>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default Rates;
