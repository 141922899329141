import * as React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Label,
} from "recharts";
import { IFormattedSerie } from "@bookingflow/types";

export interface IChartProps {
  data: IFormattedSerie[];
  ylabel: string;
}

const colors = ["#489688", "#de425b"];
const Chart = (props: IChartProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%" minWidth="500" minHeight="500">
      <BarChart
        data={props.data}
        margin={{
          top: 0,
          right: 0,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="x">
          <Label value="date" position="bottom" />
        </XAxis>
        <YAxis>
          <Label
            angle={270}
            offset={-10}
            value={props.ylabel}
            position="left"
          />
        </YAxis>
        <Tooltip />
        <Bar dataKey="y" stackId="a" fill="#489688">
          {props.data.map((entry, index) => {
            const color = entry.y > 0 ? colors[0] : colors[1];
            return <Cell key={index} fill={color} />;
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
