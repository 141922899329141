import 'firebase/compat/functions'
import 'firebase/compat/firestore'
import 'firebase/compat/app-check'

import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'
import { Accommodationtypes, Bookings } from '@bookingflow/types'
import 'modern-normalize/modern-normalize.css'
import './App.css'
import Account from './components/Account'
import BookingTab from './components/Booking'
import Calendar from './components/Calendar'
import Dashboard from './components/Dashboard'
import Documents from './components/Documents'
import Statics from './components/Statics'
import Navigation from './components/Navigation'
import Setup from './components/Setup'
import Loading from './components/Loading'
import Allocation from './components/Allocation'
import SignIn from './components/SignIn'
import Signup from './components/Signup'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './styles'
import Settings from './components/Settings'
import Accommodations from './components/Accommodations'
import Plan from './components/Plan'
import Website from './components/Website'
import SettingsImport from './components/SettingsImport'
import { initFirebase } from './init'
import { fetchBookings, fetchUserData, fetchAccommodationtypes } from './snapshotSetup'
import { User, getAuth, onAuthStateChanged } from 'firebase/auth'
// Get the Firebase config from the auto generated file
// Configure FirebaseUI.
const { db, func, auth } = initFirebase()

const App = () => {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [isSignedUp, setIsSignedUp] = useState<boolean | undefined>(undefined)
  const [siteId, setSiteId] = useState('')
  const [planType, setPlanType] = useState('')
  const [firstLogin, setFirstLogin] = useState(false)
  const [planAmount, setPlanAmount] = useState('')
  const [dataLoaded, setDataLoaded] = useState(false)
  const [TY, setDashboardTY] = useState<Bookings>([])
  const [LY, setDashboardLY] = useState<Bookings>([])
  const [recentBookings, setRecentBookings] = useState<Bookings>([])
  const [upcomingArrivals, setUpcomingArrivals] = useState<Bookings>([])
  const [upcomingDepartures, setUpcomingDepartures] = useState<Bookings>([])
  const [TYLoaded, setDashboardTYLoaded] = useState(false)
  const [LYLoaded, setDashboardLYLoaded] = useState(false)
  const [recentBookingsLoaded, setRecentBookingsLoaded] = useState(false)
  const [upcomingArrivalsLoaded, setUpcomingArrivalsLoaded] = useState(false)
  const [upcomingDeparturesLoaded, setUpcomingDeparturesLoaded] = useState(false)
  const [accommodations, setAccommodations] = useState<Accommodationtypes>([])
  const [accommodationsLoaded, setAccommodationsLoaded] = useState(false)

  // fetch user data on mount
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      const script = document.createElement('script')

      script.src = '/__/firebase/5.3.0/firebase.js'
      script.async = true
      const initscript = document.createElement('script')
      initscript.src = '/__/firebase/init.js'
      initscript.async = true

      document.body.appendChild(script)
      document.body.appendChild(initscript)
    }
    const unregisterAuthObserver = onAuthStateChanged(getAuth(), async (user: User | null) => {
      //if auth state changes set the isSignedin variable
      setIsSignedIn(!!user)
      if (!!user) {
        // if user is authenticated fetch their account data
        await fetchUserData(setPlanAmount, setPlanType, setFirstLogin, setSiteId, setIsSignedUp)
      }
    })
    // unmount clean up
    return () => {
      unregisterAuthObserver()
    }
  }, [])

  //Load data if siteId exists/changes and data isn't already Loaded
  useEffect(() => {
    let unsubscribeBookings: (() => void)[]
    if (siteId && !dataLoaded) {
      fetchAccommodationtypes(setAccommodations, siteId, dataLoaded, setAccommodationsLoaded)
      unsubscribeBookings = fetchBookings(
        setDashboardLY,
        setDashboardTY,
        setRecentBookings,
        setUpcomingArrivals,
        setUpcomingDepartures,
        siteId,
        dataLoaded,
        setDashboardLYLoaded,
        setDashboardTYLoaded,
        setRecentBookingsLoaded,
        setUpcomingArrivalsLoaded,
        setUpcomingDeparturesLoaded
      )
    }
    // Lazily add facebook messenger for production
    if (dataLoaded && window.location.hostname === 'app.bookingflow.app') {
      const setupFacebookMessenger = async () => {
        const { setFBAsyncInit, insertFBScript } = await import('./fb')
        setFBAsyncInit()
        insertFBScript()
      }
      setupFacebookMessenger()
    }
    return () => {
      if (unsubscribeBookings) {
        for (const unsubscribe of unsubscribeBookings) {
          unsubscribe()
        }
      }
    }
  }, [siteId, dataLoaded])

  //set dataloaded when all listeners have returned their first snapshot
  useEffect(() => {
    const dataLoadedArray = [
      LYLoaded,
      TYLoaded,
      recentBookingsLoaded,
      upcomingArrivalsLoaded,
      upcomingDeparturesLoaded,
      accommodationsLoaded,
    ]
    setDataLoaded(dataLoadedArray.every((d) => d))
  }, [
    LYLoaded,
    TYLoaded,
    recentBookingsLoaded,
    upcomingArrivalsLoaded,
    upcomingDeparturesLoaded,
    accommodationsLoaded,
  ])

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {
          // If a user is signed in but sign up status is unknown show the Loading page
          isSignedIn && isSignedUp === undefined && <Loading auth={auth} />
        }
        {
          // If a user is signed in and signed up but this is their
          // first login then show the setup wizard
          isSignedIn && isSignedUp === true && firstLogin && (
            <Setup accommodations={accommodations} siteId={siteId} db={db} />
          )
        }
        {isSignedIn && isSignedUp === true && (
          <Navigation
            db={db}
            dataLoaded={dataLoaded}
            auth={auth}
            accommodations={accommodations}
            planType={planType}
            authUser={isSignedIn}
            siteId={siteId}
            func={func}
          />
        )}
        <Routes>
          {
            // If a user is not signed then show the firebase UI also make signup available
            !isSignedIn && (
              <React.Fragment>
                <Route path="/*" element={<SignIn />} />
                <Route path="/signup" element={<Signup db={db} func={func} auth={auth} />} />
              </React.Fragment>
            )
          }
          {
            // If a user is signed in but not signed up show the signup page
            isSignedIn && isSignedUp === false && (
              <React.Fragment>
                <Route path="/" element={<Signup db={db} func={func} auth={auth} />} />
              </React.Fragment>
            )
          }
          {
            // If a user is signed in and signed up
            isSignedIn && isSignedUp === true && (
              <React.Fragment>
                <Route
                  path="/"
                  element={
                    <main>
                      <Outlet />
                    </main>
                  }
                >
                  <Route
                    path="/"
                    element={
                      <Dashboard
                        db={db}
                        func={func}
                        siteId={siteId}
                        TY={TY}
                        LY={LY}
                        dataLoaded={dataLoaded}
                        recentBookings={recentBookings}
                        upcomingArrivals={upcomingArrivals}
                        upcomingDepartures={upcomingDepartures}
                      />
                    }
                  />
                  <Route
                    path="/bookings"
                    element={
                      <BookingTab
                        dataLoaded={dataLoaded}
                        siteId={siteId}
                        db={db}
                        accommodations={accommodations}
                        func={func}
                      />
                    }
                  />
                  <Route
                    path="/calendar"
                    element={
                      <Calendar
                        dataLoaded={dataLoaded}
                        siteID={siteId}
                        db={db}
                        accommodations={accommodations}
                        func={func}
                      />
                    }
                  />
                  <Route
                    path="/rates"
                    element={
                      <Allocation
                        dataLoaded={dataLoaded}
                        db={db}
                        accommodations={accommodations}
                        siteId={siteId}
                        func={func}
                      />
                    }
                  />
                  <Route
                    path="/account"
                    element={
                      <Account
                        dataLoaded={dataLoaded}
                        siteId={siteId}
                        accommodations={accommodations}
                        db={db}
                        planType={planType}
                        planAmount={planAmount}
                        func={func}
                      />
                    }
                  >
                    <Route path="settings" element={<Settings auth={auth} />} />
                    <Route
                      path="accommodations"
                      element={
                        <Accommodations siteId={siteId} accommodations={accommodations} db={db} />
                      }
                    />
                    <Route
                      path="plan"
                      element={
                        <Plan
                          planType={planType}
                          planAmount={planAmount}
                          auth={auth}
                          db={db}
                          func={func}
                        />
                      }
                    />
                    <Route path="import" element={<SettingsImport siteId={siteId} />} />
                    <Route
                      path="website"
                      element={
                        <Website
                          db={db}
                          dataLoaded={dataLoaded}
                          accommodations={accommodations}
                          siteId={siteId}
                        />
                      }
                    />
                  </Route>
                  {isSignedIn && planType !== 'minimal' && (
                    <React.Fragment>
                      <Route
                        path="/documents"
                        element={<Documents siteId={siteId} db={db} func={func} />}
                      />
                      {window.location.hostname === 'localhost' && (
                        <Route path="/statics" element={<Statics db={db} func={func} />} />
                      )}
                    </React.Fragment>
                  )}
                </Route>
              </React.Fragment>
            )
          }
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}
export default App
