import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React from "react";
import Divider from "@mui/material/Divider";
import firebase from "firebase/compat/app";
import { Auth } from "firebase/auth";

export interface IPlanProps {
  auth: Auth;
  db: firebase.firestore.Firestore;
  planType: string;
  planAmount: string;
  func: firebase.functions.Functions;
}

const Plan = (props: IPlanProps) => {
  const handleUpgradeOpen = async () => {
    const createPortalLink = props.func.httpsCallable("createPortalLink");
    const createPortalRes = await createPortalLink({});
    const url = createPortalRes.data.url as string;
    globalThis.window.location.href = url;
  };
  return (
    <div className="main-container">
      <h5>Plan Details</h5>
      <p>
        You're on the
        {" " + props.planType} plan.
      </p>
      <Divider />
      <h6>Usage summary</h6>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="plan details table">
          <TableBody>
            <TableRow>
              <TableCell>1 user on this plan</TableCell>
              <TableCell>£{props.planAmount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>1 park managed on this plan</TableCell>
              <TableCell>+£0</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>50 MB of file storage used</TableCell>
              <TableCell>+£0</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Total based on current usage
                <br />
                Billing period : Dec 31, 2019 - Jan 1, 2020
              </TableCell>
              <TableCell>£{props.planAmount}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <table>
        <tbody></tbody>
      </table>
      <Button variant="contained" onClick={handleUpgradeOpen}>
        Manage your Plan
      </Button>
    </div>
  );
};

export default Plan;
