import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Label from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import firebase from "firebase/compat/app";
import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useLocation } from "react-router-dom";
import SignOutButton from "./firebase/SignOut";
import Payment from "./Payment";
import { SignupPricingCards } from "./PricingCards";
import "./Signup.css";
import Typography from "@mui/material/Typography";
import { Auth } from "firebase/auth";

export interface ISignupProps {
  db: firebase.firestore.Firestore;
  func: firebase.functions.Functions;
  auth: Auth;
}

const handleSiteNameChange =
  (setSiteName: React.Dispatch<React.SetStateAction<string>>) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    setSiteName(event.target.value);
  };
const handleFirstNameChange =
  (setFirstname: React.Dispatch<React.SetStateAction<string>>) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstname(event.target.value);
  };
const handleLastNameChange =
  (setLastname: React.Dispatch<React.SetStateAction<string>>) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value);
  };
const toggleSiteName =
  (
    setSitenameVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setPricePlanVisible: React.Dispatch<React.SetStateAction<boolean>>,
    sitenameVisible: boolean,
    pricePlanVisible: boolean,
  ) =>
  (event: React.MouseEvent) => {
    setSitenameVisible(!sitenameVisible);
    setPricePlanVisible(!pricePlanVisible);
  };

const Signup = (props: ISignupProps) => {
  const [sitenameVisible, setSitenameVisible] = useState(true);
  const [pricePlanVisible, setPricePlanVisible] = useState(false);
  const [paymentVisible, setPaymentVisible] = useState(false);
  const [siteName, setSiteName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [paymentPlan, setPaymentPlan] = useState("premium");
  const [snackOpen, setSnackOpen] = useState(false);
  const [siteId, setSiteId] = useState<string>("");
  const [uid, setUid] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState("");
  const [submitText, setSubmitText] = useState("Submit");
  const [nextDisabled, setNextDisabled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [planAmount, setPlanAmount] = useState("");
  const [emailUpdates, setEmailUpdates] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);

  const handleSiteNameSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    // ReactGA.event({category: 'Signup', action: 'Entered Campsite Name', label: siteName});
    setNextDisabled(true);
    setSubmitText("Submitting...");
    const siteRef = props.db.collection("sites").doc();
    const siteId = siteRef.id;
    setSiteId(siteId);
    setSitenameVisible(!sitenameVisible);
    setPricePlanVisible(!pricePlanVisible);
    setNextDisabled(false);
    setSubmitText("Submit");
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailUpdates(event.target.checked);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwd = event.target.value;
    setPassword(passwd);
    if (passwd.length < 6) {
      setPasswordError(true);
      setNextDisabled(true);
    } else {
      setPasswordError(false);
      setNextDisabled(false);
    }
  };
  const handlePricePlanChange = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    setNextDisabled(true);
    setSubmitText("Submitting...");
    setPricePlanVisible(!pricePlanVisible);
    setPaymentVisible(!paymentVisible);
    setNextDisabled(false);
    setSubmitText("Submit");
  };

  const handleChange =
    (setPaymentPlan: React.Dispatch<React.SetStateAction<string>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPaymentPlan(event.target.value);
    };
  const handleSnackClose =
    (setSnackOpen: React.Dispatch<React.SetStateAction<boolean>>) =>
    (event: React.SyntheticEvent | Event, reason: string) => {
      if (reason === "clickaway") {
        return;
      }

      setSnackOpen(false);
    };
  const onSnackClose =
    (setSnackOpen: React.Dispatch<React.SetStateAction<boolean>>) =>
    (event: any) => {
      setSnackOpen(false);
    };
  const fetchPlan = useCallback(
    async (isMounted: boolean) => {
      const product = await props.db
        .collection("products")
        .where("name", "==", `bookingflow ${paymentPlan}`)
        .get();
      const productDoc = product.docs[0];
      const planQuery = productDoc.ref.collection("plans");
      const plan = await planQuery.get();
      const doc = plan.docs[0];
      if (!doc.data().amount) {
        const errorLogger = props.func.httpsCallable("error");
        errorLogger({ error: "plan amount not set" });
        setNextDisabled(true);
        return;
      }
      const selectedPlanAmount = (doc.data().amount / 100).toString();
      setPlanAmount(selectedPlanAmount);
    },
    [paymentPlan, props.db, props.func],
  );

  useEffect(() => {
    const errorLogger = props.func.httpsCallable("error");
    const loadStripeAsync = async () => {
      if (!process.env.REACT_APP_STRIPE_KEY) {
        //TODO: handle this more gracefully
        errorLogger({ error: "no stripe key environment variable" });
        throw Error(
          "there is currently a technical fault with our payment provider please try later or contact support",
        );
      }
      const loadedStripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
      setStripePromise(loadedStripe);
    };
    loadStripeAsync();
    let isMounted = true;
    if (isMounted) {
      fetchPlan(isMounted);
    }
    return () => {
      isMounted = false;
    };
  }, [paymentPlan, props.db, fetchPlan, props.func]);
  useEffect(() => {
    // TODO: consider checking token against the db again or why pass it as URL param?
    let isMounted = true;
    if (isMounted) {
      const params = new URLSearchParams(location.search);
      const uid = params.get("uid");
      if (uid) {
        if (isMounted) setUid(uid);
        const email = params.get("email");
        if (!email) {
          navigate("/");
          return;
        }
        setEmail(email);
        // navigate("/signup/new_account")
      }
    }
    return () => {
      isMounted = false;
    };
  }, [navigate, location.search]);
  return (
    <div id="signup" className="main-container">
      <Helmet>
        <title>Signup</title>
      </Helmet>
      <Snackbar
        className="warning-snackbar"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose(setSnackOpen)}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar">
              <ErrorIcon />
              <h6>Cannot connect to payment server</h6>
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={onSnackClose(setSnackOpen)}
              size="large"
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ flexGrow: "1" }}>
          <Typography variant="h5" align="center" className="signinBrandText">
            BookingFlow
          </Typography>
        </Box>
        <Box sx={{ alignItems: "center" }}>
          Not you? <SignOutButton auth={props.auth} />
        </Box>
      </Box>
      <Slide direction="left" in={sitenameVisible} mountOnEnter unmountOnExit>
        <Card>
          <CardContent>
            <h5>Let us know a bit more about you</h5>
            <h6>You're signing up with {email}</h6>
            <form action="" onSubmit={handleSiteNameSubmit}>
              <TextField
                id="signup-firstname"
                className="signup-info"
                name="given-name"
                autoComplete="given-name"
                type="text"
                variant="outlined"
                label="First Name"
                value={firstname}
                onChange={handleFirstNameChange(setFirstname)}
              />
              <TextField
                id="signup-lastname"
                className="signup-info"
                name="family-name"
                autoComplete="family-name"
                type="text"
                variant="outlined"
                label="Last Name"
                value={lastname}
                onChange={handleLastNameChange(setLastname)}
              />
              <TextField
                id="signup-businessname"
                className="signup-info"
                name="organization"
                autoComplete="organization"
                type="text"
                label="Business Name"
                value={siteName}
                variant="outlined"
                onChange={handleSiteNameChange(setSiteName)}
              />
              <TextField
                id="signup-password"
                className="signup-info"
                autoComplete="new-password"
                name="password"
                label="Password"
                type="password"
                value={password}
                variant="outlined"
                error={passwordError}
                helperText="Password must be at least 6 characters in length"
                onChange={handlePasswordChange}
              />
              <Label
                control={
                  <Checkbox
                    id="email-updates"
                    checked={emailUpdates}
                    onChange={handleEmailChange}
                    value="updates"
                  />
                }
                label="Get feature updates and tips via email"
              />
              <div className="button-div">
                <Button
                  id="signup-submit-1"
                  variant="contained"
                  type="submit"
                  disabled={nextDisabled}
                  color="primary"
                >
                  {submitText}
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </Slide>
      <Slide direction="left" in={pricePlanVisible} mountOnEnter unmountOnExit>
        <Card>
          <CardContent>
            <h5>Choose your plan</h5>
            <form action="" onSubmit={handlePricePlanChange}>
              <SignupPricingCards />
              <div id="radio-group">
                <div>
                  <Radio
                    id="premium"
                    name="premium"
                    value="premium"
                    onChange={handleChange(setPaymentPlan)}
                    checked={paymentPlan === "premium"}
                  />
                </div>
                <div>
                  <Radio
                    id="plus"
                    name="plus"
                    value="plus"
                    onChange={handleChange(setPaymentPlan)}
                    checked={paymentPlan === "plus"}
                  />
                </div>
                <div>
                  <a href="https://bookingflow.app/sales">
                    <Button
                      variant="contained"
                      disabled={nextDisabled}
                      type="submit"
                      color="primary"
                    >
                      Contact Sales
                    </Button>
                  </a>
                </div>
              </div>
              <div className="button-div">
                <Button
                  variant="contained"
                  onClick={toggleSiteName(
                    setSitenameVisible,
                    setPricePlanVisible,
                    sitenameVisible,
                    pricePlanVisible,
                  )}
                >
                  Back
                </Button>
                <Button
                  id="signup-plan-submit"
                  variant="contained"
                  disabled={nextDisabled}
                  type="submit"
                  color="primary"
                >
                  {submitText}
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </Slide>
      <Slide direction="left" in={paymentVisible} mountOnEnter unmountOnExit>
        <div>
          <Elements stripe={stripePromise}>
            <Payment
              paymentPlan={paymentPlan}
              setPaymentVisible={setPaymentVisible}
              setPricePlanVisible={setPricePlanVisible}
              pricePlanVisible={pricePlanVisible}
              paymentVisible={paymentVisible}
              uid={uid}
              email={email}
              siteId={siteId}
              siteName={siteName}
              planAmount={planAmount}
              password={password}
              db={props.db}
              firstname={firstname}
              lastname={lastname}
              emailUpdates={emailUpdates}
              func={props.func}
            />
          </Elements>
        </div>
      </Slide>
    </div>
  );
};

export default Signup;
