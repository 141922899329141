import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import "firebase/compat/firestore";
import React from "react";
import "./Settings.css";
import { Auth, sendPasswordResetEmail } from "firebase/auth";

export interface ISettingsProps {
  auth: Auth;
}

const Settings = (props: ISettingsProps) => {
  const user = props.auth.currentUser;
  if (!user) {
    console.error("no user here");
    props.auth.signOut();
  }
  const resetPassword = async () => {
    if (!user) {
      console.error("no user here");
      props.auth.signOut();
      return;
    }
    const email = user.email;
    if (!email) {
      console.error("cannot find email address for user: " + user.uid);
      return;
    }
    await sendPasswordResetEmail(props.auth, email);
  };

  const email: string | null = user ? user.email : null;

  return (
    <div className="main-container">
      <h5>Contact Details</h5>
      {email && (
        <div id="contact-email">
          <TextField
            id="filled-basic"
            label="Contact Email"
            defaultValue={email}
            variant="filled"
            InputProps={{ readOnly: true }}
          />
          <Button variant="outlined" onClick={resetPassword}>
            Reset your Password
          </Button>
        </div>
      )}
      {!email && (
        <p>Could not find your email address please contact support</p>
      )}
    </div>
  );
};

export default Settings;
