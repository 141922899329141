import { createTheme } from '@mui/material/styles'
import { teal } from '@mui/material/colors'

export const theme = createTheme({
  palette: {
    primary: {
      main: teal[500],
    },
  },
  components: {
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        // The default props to change
        color: teal[500],
      },
    },
  },
})
