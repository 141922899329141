import Button from "@mui/material/Button";
import React from "react";
import { Auth } from "firebase/auth";

type SignOutProps = {
  auth: Auth;
};

const SignOutButton = (props: SignOutProps) => (
  <Button id="signout">
    <a href="/#" onClick={() => props.auth.signOut()}>
      Sign Out
    </a>
  </Button>
);
export default SignOutButton;
