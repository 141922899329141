import FullCalendar, {
  EventSourceInput,
  DateSelectArg,
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "@fullcalendar/daygrid/main.css";
import interactionPlugin from "@fullcalendar/interaction"; // for selectable
import timeGridPlugin from "@fullcalendar/timegrid";
import "@fullcalendar/timegrid/main.css";
import React from "react";
import { ISlotEvent } from "@bookingflow/types";
import "./MonthCalendar.css";

export interface ISlotsInfo {
  start: Date;
  end: Date;
  startStr: string;
  endStr: string;
  allDay: boolean;
  resource?: any;
  jsEvent: MouseEvent;
  view: any;
}
export interface IEventClickInfo {
  event: ISlotEvent;
  el: HTMLElement;
  jsEvent: MouseEvent;
  view: any;
}

export interface IMonthCalendarProps {
  currentDate: Date;
  setCurrentDate: (date: Date) => void;
  events: EventSourceInput;
  handleSelectEvent: (slotEvent: IEventClickInfo) => void;
  handleSelectSlots: (selectInfo: DateSelectArg) => void;
}

const MonthCalendar = (props: IMonthCalendarProps) => {
  const calendarRef: React.RefObject<FullCalendar> = React.createRef();
  return (
    <div>
      <FullCalendar
        ref={calendarRef}
        selectable
        eventClick={props.handleSelectEvent}
        select={props.handleSelectSlots}
        initialView="dayGridMonth"
        longPressDelay={200}
        eventColor="#009688"
        eventDisplay="block"
        datesSet={(dateInfo) => {props.setCurrentDate(dateInfo.start)}}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        events={props.events}
      />
    </div>
  );
};

export default MonthCalendar;
