import type admin from "firebase-admin";
import type firebase from "firebase/compat/app";
import {
  Allocations,
  IAllocation,
  Bookings,
  IBooking,
  IAvailabilityFlexResult,
  Accommodationtypes,
  IAvailabilityResult,
} from "@bookingflow/types";
import { config } from "@bookingflow/config";
import { Firestore, collection, doc, where, query, getDocs } from "firebase/firestore/lite";
/**
 * Convert Date-related IBooking fields from string to utc date objects
 */
export const convertDatestring = (
  dat: Record<string, unknown>
): Record<string, unknown> => {
  const convertedDat = { ...dat };
  for (const field of Object.keys(dat)) {
    if (["arrive", "depart", "date"].includes(field)) {
      convertedDat[field] = convertToUTCDate(new Date(dat[field] as string));
    } else if (field === "created") {
      convertedDat[field] = new Date(dat[field] as string);
    } else {
      convertedDat[field] = dat[field];
    }
  }
  return convertedDat;
};
/**
 * Convert Date-related IBooking fields from date to iso date string
 */
export const convertToDatestring = (
  dat: Record<string, unknown>
): Record<string, unknown> => {
  const convertedDat = { ...dat };
  for (const field of Object.keys(dat)) {
    if (["arrive", "depart", "created", "date"].includes(field)) {
      convertedDat[field] = convertToISODate(dat[field] as Date);
    } else {
      convertedDat[field] = dat[field];
    }
  }
  return convertedDat;
};
/**
 * Convert Date-related IBooking fields from firestore timestamp to utc date objects
 */
export const convertTimestamp = (
  dat: Record<string, unknown>
): Record<string, unknown> => {
  const convertedDat = { ...dat };
  for (const field of Object.keys(dat)) {
    if (["arrive", "depart", "created", "date"].includes(field)) {
      try {
        const timestamp: any = dat[field] as any;
        convertedDat[field] = timestamp.toDate();
      } catch {
        convertedDat[field] = null;
      }
    } else {
      convertedDat[field] = dat[field];
    }
  }
  return convertedDat;
};
/**
 * runs convertTimestamp over all IBookings in a Bookings object
 */
export const convertTimestamps = (
  data: Array<Record<string, unknown>>
): Array<Record<string, unknown>> => {
  const convertedData: Array<Record<string, unknown>> = [];
  for (const dat of data) {
    const convertedDat = convertTimestamp(dat);
    convertedData.push(convertedDat);
  }
  return convertedData;
};

// create date range from arrival and depart dates
export const getDateArray = (arrive: Date, depart: Date): Date[] => {
  const dateArray: Date[] = [];
  let current: number = arrive.getTime();
  while (current <= depart.getTime()) {
    dateArray.push(new Date(current));
    current = current + 24 * 60 * 60 * 1000; //milliseconds in a day
  }
  return dateArray;
};
/**
 * Convert DateTime to utc date objects with no time
 */
export const convertToUTCDate = (date: Date): Date => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const utcDate = new Date(Date.UTC(year, month, day));
  return utcDate;
};
/**
 * Convert to ISODate without time or timezone components
 */
export const convertToISODate = (date: Date): string => {
  return date.toISOString().substring(0, 10);
};
/**
 * adds a booking to db
 */
export const addBooking = async (
  db: firebase.firestore.Firestore | admin.firestore.Firestore,
  booking: IBooking
) => {
  const data = convertDatestring(booking);
  const sitesCollection = db.collection("sites");
  const site = sitesCollection.doc(booking.siteId);
  const collectionRef = site.collection("transactional");
  const docRef = await collectionRef.add(data);
  return docRef;
};
/**
 * gets allocations for a given accommodation and time period
 */
export const getAllocations = async (
  db: firebase.firestore.Firestore | admin.firestore.Firestore,
  siteId: string,
  fromTime: Date,
  toTime: Date,
  accommodation: string
): Promise<Allocations> => {
  // get allocations between arrive and depart dates
  const sitesCollection = db.collection("sites");
  const site = sitesCollection.doc(siteId);
  const collectionRef = site.collection("pricingAllocation");
  // Get available dates
  const snapshot = await collectionRef
    .where("date", ">=", fromTime)
    .where("date", "<", toTime)
    .where("accommodationid", "==", accommodation)
    .get();
  const allocations = [];
  for (const doc of snapshot.docs) {
    allocations.push(convertTimestamp(doc.data()));
  }
  return allocations as unknown as Allocations;
};
/**
 * gets all hold transactions for a given accommodation time period
 */
export const getHoldTransactions = async (
  db: firebase.firestore.Firestore | admin.firestore.Firestore,
  siteId: string,
  fromTime: Date,
  toTime: Date,
  accommodation: string
): Promise<Bookings> => {
  // get transactions in hold
  const sitesCollection = db.collection("sites");
  const site = sitesCollection.doc(siteId);
  const timeoutMillis = config.timeoutMinutes * 60 * 1000;
  const holdTimeMillis = new Date().getTime() - timeoutMillis;
  const holdTime = new Date(holdTimeMillis);
  const holdSnapshot = await site
    .collection("transactional")
    .where("created", ">=", holdTime)
    .where("accommodationid", "==", accommodation)
    .where("status", "==", "hold")
    .get();
  const holdTransactions = [];
  for (const doc of holdSnapshot.docs) {
    const data = convertTimestamp(doc.data()) as IBooking;
    // because firestore only allows inequality operators on
    if (
      data.arrive.getTime() >= fromTime.getTime() &&
      data.depart.getTime() < toTime.getTime()
    ) {
      holdTransactions.push(data);
    }
  }
  return holdTransactions as Bookings;
};
/**
 * Constructs the query for a fetching all accommodations for a given site
 */
export const getAccommodationsQuery = (
  siteId: string,
  errorLogger: (error: any) => void,
  db: firebase.firestore.Firestore | admin.firestore.Firestore
) => {
  const sitesCollection = db.collection("sites");
  const site = sitesCollection.doc(siteId);
  const collectionRef = site.collection("accommodation");
  const query = collectionRef.where("status", "==", "active");
  return query;
};
/**
 * gets all accommodations for a given site
 */
export const getAccommodations = async (
  siteId: string,
  errorLogger: (error: any) => void,
  db: firebase.firestore.Firestore | admin.firestore.Firestore
): Promise<Accommodationtypes | undefined> => {
  const query = getAccommodationsQuery(siteId, errorLogger, db);
  try {
    const dataSnapshot = await query.get();
    const data = dataSnapshot.docs.map((doc: any) => {
      return { id: doc.id, ...doc.data() };
    });
    return data as Accommodationtypes;
  } catch (error) {
    // creates a tagged error for logging
    const appendedError = {
      ...(error as Object),
      ...{
        description: `BFP1: accommodations not returned in portal for siteId ${siteId}`,
      },
    };
    errorLogger({ error: appendedError });
    return;
  }
};

export const getAccommodationsQueryNew = async (
  siteId: string,
  db: Firestore
) => {
  const sitesCollection = collection(db, "sites");
  const site = doc(sitesCollection, siteId);
  const collectionRef = collection(site, "accommodation");
  const q = query(collectionRef, where("status", "==", "active"));
  return q;
};
export const getAccommodationsNew = async (
  siteId: string,
  errorLogger: (error: any) => void,
  db: Firestore
): Promise<Accommodationtypes | undefined> => {
  const q = await getAccommodationsQueryNew(siteId, db);
  try {
    const dataSnapshot = await getDocs(q);
    const data = dataSnapshot.docs.map((doc: any) => {
      return { id: doc.id, ...doc.data() };
    });
    return data as Accommodationtypes;
  } catch (error) {
    // creates a tagged error for logging
    const appendedError = {
      ...(error as Object),
      ...{
        description: `BFP1: accommodations not returned in portal for siteId ${siteId}`,
      },
    };
    errorLogger({ error: appendedError });
    return;
  }
};
/**
 * works out the extra individuals that are not included in the accommodation price and
 * need to be charged incrementally
 */
export const getAdditionals = (
  adults: number,
  children: number,
  infants: number,
  includedIndividuals: number
): {
  additionalAdults: number;
  additionalChildren: number;
  additionalInfants: number;
} => {
  // This function uses up the included individuals in order from adults > children > infants
  // in the strange world where that isn't true it could lead to unintended results
  if (adults - includedIndividuals >= 0) {
    return {
      additionalAdults: adults - includedIndividuals,
      additionalChildren: children,
      additionalInfants: infants,
    };
  } else if (adults + children - includedIndividuals >= 0) {
    return {
      additionalAdults: 0,
      additionalChildren: children - (includedIndividuals - adults),
      additionalInfants: infants,
    };
  } else if (adults + children + infants - includedIndividuals >= 0) {
    return {
      additionalAdults: 0,
      additionalChildren: 0,
      additionalInfants: infants - (includedIndividuals - adults - children),
    };
  } else {
    return {
      additionalAdults: 0,
      additionalChildren: 0,
      additionalInfants: 0,
    };
  }
};
/**
 * gets the costs for a set of allocations and additionals
 */
export const getCosts = (
  allocations: Allocations,
  additionalAdults: number,
  additionalChildren: number,
  additionalInfants: number
) => {
  if (allocations.length === 0) {
    return null;
  }
  const totalCost = {
    adultCost: 0,
    infantCost: 0,
    accommodationCost: 0,
    childCost: 0,
    totalCost: 0,
  };

  allocations.forEach((allocation: IAllocation) => {
    const adultCost = allocation.adultprice * additionalAdults;
    const infantCost = allocation.infantprice * additionalInfants;
    const accommodationCost = allocation.accommodationprice;
    const childCost = allocation.childprice * additionalChildren;
    totalCost.accommodationCost += accommodationCost;
    totalCost.infantCost += infantCost;
    totalCost.childCost += childCost;
    totalCost.adultCost += adultCost;
  });
  const roundCost = (amount: number) => Math.floor(amount * 100) / 100;
  // round before calculating totals so that it definitely adds up
  totalCost.accommodationCost = roundCost(totalCost.accommodationCost);
  totalCost.infantCost = roundCost(totalCost.infantCost);
  totalCost.childCost = roundCost(totalCost.childCost);
  totalCost.adultCost = roundCost(totalCost.adultCost);
  //sum up rounded costs to get total
  const total =
    totalCost.adultCost +
    totalCost.infantCost +
    totalCost.accommodationCost +
    totalCost.childCost;
  totalCost.totalCost = total;
  return totalCost;
};
/**
 * Combines hold bookings with confirmed bookings and checks availability
 *
 * Checks the slot is still available after accounting for hold bookings and returns a boolean
 *
 * @async
 * @function checkStillAvailable
 * @param {Allocations} allocations - pass in the allocations object for the search criteria
 * @param {Bookings} holdTransactions - the transactions that are on hold for the search criteria
 *
 * @return {Promise<Boolean>} boolean representing whether it is still available.
 */
export const checkStillAvailable = (
  allocations: Allocations,
  holdTransactions: Bookings
): boolean => {
  const stillAvailableArray = allocations.map((allocation: IAllocation) => {
    // Filter Hold Transactions to time and accommodationid
    const holdTransaction: Bookings = holdTransactions.filter(
      (transaction: IBooking) => {
        return (
          transaction.arrive.getTime() === allocation.date.getTime() &&
          transaction.accommodationid === allocation.accommodationid
        );
      }
    );
    // If there are no hold bookings then it is still available
    const holdCount = holdTransaction.length;
    if (holdCount === 0) {
      return true;
    }
    return holdCount < allocation.vacancies;
  });
  // checking it is still available after accounting for the hold transactions
  const stillAvailable = stillAvailableArray.every(
    (value: boolean) => value === true
  );
  return stillAvailable;
};
/**
 * for a given accommodation fetch how many individuals are
 * included in the accommodation price
 */
export const getIncludedIndividuals = async (
  db: firebase.firestore.Firestore | admin.firestore.Firestore,
  errorLogger: (error: any) => void,
  siteId: string,
  accommodationid: string
): Promise<number> => {
  const docRef = db
    .collection("sites")
    .doc(siteId)
    .collection("accommodation")
    .doc(accommodationid);
  const doc = await docRef.get();
  const data = doc.data();
  if (!data) {
    throw new Error("cannot find accommodation document");
  }
  if (isNaN(data.includedIndividuals)) {
    errorLogger({ error: data });
    errorLogger({ error: data.includedIndividuals });
    throw new Error("included individuals is not set");
  }
  return Number(data.includedIndividuals);
};
/**
 * works out how many nights are between a given arrival and departure date
 */
export const getNights = (fromTime: Date, toTime: Date): number => {
  const lastFullDay = new Date(toTime.getTime() - 24 * 60 * 60 * 1000); // toTime minus 1 day
  const dateArray: Date[] = getDateArray(fromTime, lastFullDay);
  const nights = dateArray.length;
  return nights;
};
/**
 * Checks whether allocation is available based on its confirmed bookings
 * ignores the hold bookings
 */
const isConfirmedAllocationAvailable = (
  allocations: Allocations,
  nights: number
) => {
  const availableBool: boolean = allocations.every(
    (value: IAllocation) => value.available === true
  );
  if (nights > allocations.length || !availableBool) {
    return false;
  } else {
    return true;
  }
};
/**
 * checks whether the minimum nights requirement has been satisfied
 */
export const minNightsCheck = (allocations: Allocations, nights: number) => {
  const maxMinNights = Math.max(
    ...allocations.map((allocation: IAllocation) => {
      return allocation.minNights;
    })
  );
  const isMinNightValid = nights >= maxMinNights;
  return {
    isMinNightsSatisfied: isMinNightValid,
    maxMinNights: maxMinNights,
  };
};
/**
 * checks whether the maximum nights requirement has been satisfied
 */
export const maxNightsCheck = (allocations: Allocations, nights: number) => {
  const minMaxNights = Math.max(
    ...allocations.map((allocation: IAllocation) => {
      return allocation.maxNights;
    })
  );
  const isMaxNightValid = nights <= minMaxNights;
  return {
    isMaxNightsSatisfied: isMaxNightValid,
    minMaxNights: minMaxNights,
  };
};
/**
 * uses the search dates and the flex input to construct a matrix of
 * dates to search over
 */
export const getSearchMatrix = (
  from: Date,
  to: Date,
  nights: number,
  flex: number
): [Date, Date, number][] => {
  // generate a matrix of alternative
  // set start date to previous date if that is not in the past
  const searchDates: [Date, Date, number][] = [];
  // search for 1 less nights and 1 more nights
  let nightFlexArray;
  if (nights === 1) {
    nightFlexArray = [nights, nights + 1];
  } else {
    nightFlexArray = [nights - 1, nights, nights + 1];
  }
  for (let i = -flex; i < flex + 1; i++) {
    const dayMillis = 24 * 60 * 60 * 1000;
    // for each flex create a new from date
    const nextFromDate = new Date(from.getTime() + i * dayMillis);
    for (const nights of nightFlexArray) {
      if (nextFromDate.getTime() >= new Date().setUTCHours(0, 0, 0, 0)) {
        //for each nightOption and for each flex create a new to date
        const nextToDate = new Date(
          nextFromDate.getTime() + nights * dayMillis
        );
        searchDates.push([nextFromDate, nextToDate, nights]);
      }
    }
  }
  return searchDates;
};
/**
 * orchestrates the process to check whether a search query is available
 */

export const getAvailabilityAndPrices = async (
  db: firebase.firestore.Firestore | admin.firestore.Firestore,
  errorLogger: (error: any) => void,
  siteId: string,
  from: Date,
  to: Date,
  accommodationid: string,
  adults: number,
  children: number,
  infants: number,
  flex: number
): Promise<IAvailabilityFlexResult> => {
  // get included individuals
  const includedIndividuals: number = await getIncludedIndividuals(
    db,
    errorLogger,
    siteId,
    accommodationid
  );
  // return number of nights
  const nights = getNights(from, to);
  // if date range is invalid return quickly
  if (nights <= 0) {
    const availability = {
      isAvailable: false,
      message: "The date selection is invalid",
    };
    const availabilityResult = {
      id: accommodationid,
      availability: availability,
    };
    return availabilityResult;
  }
  // construct a search matrix of dates
  let searchMatrix: [Date, Date, number][];
  if (flex > 0) {
    searchMatrix = getSearchMatrix(from, to, nights, flex);
  } else {
    searchMatrix = [[from, to, nights]];
  }
  const searchPromises = searchMatrix.map(async (search) => {
    const [searchFrom, searchTo, searchNights] = search;
    // get relevant Allocation
    const allocations = await getAllocations(
      db,
      siteId,
      searchFrom,
      searchTo,
      accommodationid
    );
    // Checks whether allocation is available based on confirmed bookings
    // get array of availability by allocation
    if (!isConfirmedAllocationAvailable(allocations, searchNights)) {
      const availabilityResult: IAvailabilityResult = {
        arriveTime: searchFrom.getTime(),
        departTime: searchTo.getTime(),
        isAvailable: false,
        cost: null,
        minNightsSatisfied: null,
        maxNightsSatisfied: null,
        onHold: null,
      };
      return availabilityResult;
    }
    // check max nights restriction is satisfied
    const { isMaxNightsSatisfied, minMaxNights } = maxNightsCheck(
      allocations,
      searchNights
    );
    if (!isMaxNightsSatisfied) {
      const availabilityResult: IAvailabilityResult = {
        arriveTime: searchFrom.getTime(),
        departTime: searchTo.getTime(),
        isAvailable: false,
        cost: null,
        minNightsSatisfied: null,
        maxNightsSatisfied: minMaxNights,
        onHold: null,
      };
      return availabilityResult;
    }
    // check minimum nights restriction
    const { isMinNightsSatisfied, maxMinNights } = minNightsCheck(
      allocations,
      searchNights
    );
    if (!isMinNightsSatisfied) {
      const availabilityResult: IAvailabilityResult = {
        arriveTime: searchFrom.getTime(),
        departTime: searchTo.getTime(),
        isAvailable: false,
        cost: null,
        minNightsSatisfied: maxMinNights,
        maxNightsSatisfied: null,
        onHold: null,
      };
      return availabilityResult;
    }
    //get additional hold transactions
    const holdTransactions = await getHoldTransactions(
      db,
      siteId,
      searchFrom,
      searchTo,
      accommodationid
    );
    const stillAvailable = checkStillAvailable(allocations, holdTransactions);
    if (!stillAvailable) {
      const availabilityResult: IAvailabilityResult = {
        arriveTime: searchFrom.getTime(),
        departTime: searchTo.getTime(),
        isAvailable: false,
        cost: null,
        minNightsSatisfied: null,
        maxNightsSatisfied: null,
        onHold: !stillAvailable,
      };
      return availabilityResult;
    }
    // convert total number of individuals to how many additional to the included allowance
    const { additionalAdults, additionalChildren, additionalInfants } =
      getAdditionals(adults, children, infants, includedIndividuals);
    // get the costs
    const costs = getCosts(
      allocations,
      additionalAdults,
      additionalChildren,
      additionalInfants
    );
    const availabilityResult: IAvailabilityResult = {
      arriveTime: searchFrom.getTime(),
      departTime: searchTo.getTime(),
      isAvailable: true,
      cost: costs,
      minNightsSatisfied: null,
      maxNightsSatisfied: null,
      onHold: !stillAvailable,
    };
    return availabilityResult;
  });
  const searchResults = await Promise.all(searchPromises);
  const availability = searchResults.some((result) => result.isAvailable);

  if (!availability) {
    const minNightRestricted = searchResults.filter((result) => {
      result.minNightsSatisfied !== null;
    });
    const isMinRestricted = minNightRestricted.length > 0;
    const maxNightRestricted = searchResults.filter((result) => {
      result.maxNightsSatisfied !== null;
    });
    const isMaxRestricted = maxNightRestricted.length > 0;
    const isHoldRestricted = searchResults.some((result) => {
      result.onHold !== null;
    });
    let message = "";
    // if any results are unavailable due to min nights restriction
    if (isMinRestricted) {
      const maxMinNights = Math.max(
        ...(minNightRestricted.map((o) => o.minNightsSatisfied) as number[])
      );
      message = `You cannot book for less than ${maxMinNights} nights for this period`;
    }
    // if any results are unavailable due to min nights restriction
    else if (isMaxRestricted) {
      const minMaxNights = Math.max(
        ...(maxNightRestricted.map((o) => o.maxNightsSatisfied) as number[])
      );
      message = `You cannot book for more than ${minMaxNights} nights for this period`;
    }
    // if any results are unavailable due to min nights restriction
    else if (isHoldRestricted) {
      message =
        "Someone else is holding the booking, call us and we may be able to help!";
    } else {
      message = "There is no availability on these dates";
    }
    const availabilityResult: IAvailabilityFlexResult = {
      id: accommodationid,
      availability: {
        isAvailable: availability,
        message: message,
      },
    };
    return availabilityResult;
  }
  const recommended = searchResults.find((result) => result.isAvailable);
  const availabilityResult = {
    id: accommodationid,
    availability: {
      isAvailable: availability,
      message: "",
    },
    recommended: recommended,
    results: searchResults,
  };
  return availabilityResult;
};

/**
 * checks whether a date is before another one
 */
export const isBeforeDay = (a: Date, b: Date): boolean => {
  const aYear = a.getFullYear();
  const aMonth = a.getMonth();

  const bYear = b.getFullYear();
  const bMonth = b.getMonth();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.getDate() < b.getDate();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
};

/**
 * checks whether a date is on or after another one
 */
export const isInclusivelyAfterDay = (a: Date, b: Date): boolean => {
  return !isBeforeDay(a, b);
};

/**
 * makes a slot unavailable. overrides
 */
//TODO: a slot that has been made unavailable should not come back as available when someone cancels
export const makeUnavailable = async (
  db: firebase.firestore.Firestore | admin.firestore.Firestore,
  errorLogger: (data: any) => void,
  date: Date,
  siteId: string,
  selectedPitchType: string
) => {
  const siteRef = db.collection("sites");
  const sites = siteRef.doc(siteId);
  const collectionRef = sites.collection("pricingAllocation");
  //availability doesnt need to be calculated as it has been overridden
  const available = false;
  const allocation = {
    accommodationid: selectedPitchType,
    available,
    date,
    vacancies: 0,
  };
  // if slot is already filled get id and updateprice

  const id = allocation.accommodationid + allocation.date.toISOString();
  collectionRef
    .doc(id)
    .update(allocation)
    .catch((err: string) => {
      errorLogger({ error: err });
    });
  return;
};
/**
 * updates Allocation for a single document
 */
export const updateAllocation = async (
  db: firebase.firestore.Firestore | admin.firestore.Firestore,
  errorLogger: (data: any) => void,
  date: Date,
  siteId: string,
  selectedPitchType: string,
  maxAllocation: number,
  pitchprice: number,
  adultprice: number,
  childprice: number,
  infantprice: number,
  minNights: number,
  maxNights: number
): Promise<admin.firestore.WriteResult | void> => {
  const siteRef = db.collection("sites");
  const sites = siteRef.doc(siteId);
  const collectionRef = sites.collection("pricingAllocation");
  
  //calculate availability from existing transactions - maxAllocation
  const transactionRef = sites.collection("transactional");
  const snapshot = await transactionRef
    .where("accommodationid", "==", selectedPitchType)
    .where("arrive", "==", date)
    .get()
    .catch((err: string) => {
      errorLogger({ message: err });
  });
  let size: number;
  if (!snapshot) {
    size = 0;
  } else {
    size = snapshot.size
  }
  if (size > maxAllocation) {
    const dateString = date.toISOString().slice(0, 10);
    throw new Error(`Allocation could not be updated for the 
      date: ${dateString} as existing bookings 
      already exceed the specified maxAllocation`,
    );
  }
  const available: boolean = size < maxAllocation;
  const allocation: IAllocation = {
    accommodationid: selectedPitchType,
    accommodationprice: pitchprice,
    adultprice,
    available,
    childprice,
    date,
    infantprice,
    maxAllocation,
    minNights,
    maxNights,
    vacancies: maxAllocation - size,
  };
  // if slot is already filled get id and updateprice

  const id = allocation.accommodationid + allocation.date.toISOString();
  errorLogger({message: id})
  const promise = collectionRef
    .doc(id)
    .set(allocation)
  return promise; 
};
