import React, { useState } from "react";
import NativeSelect from "@mui/material/NativeSelect";
import Import from "./Import";

export interface ISettingsImportProps {
  siteId: string;
}
const SettingsImport = (props: ISettingsImportProps) => {
  const [selectedFileType, setSelectedFileType] = useState<
    "Booking" | "Allocation" | "Accommodation"
  >("Booking");
  const [activeStep, setActiveStep] = useState(0);
  const handleFiletypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const target = event.currentTarget;
    const { value } = target;
    setSelectedFileType(value as "Booking" | "Allocation" | "Accommodation");
  };
  return (
    <div>
      <h3>Data Import</h3>
      <NativeSelect
        onChange={handleFiletypeChange}
        required
        value={selectedFileType}
        name="fileGroup"
        id="fileGroup"
      >
        <option key="booking" value="Booking">
          Bookings
        </option>
        <option key="allocation" value="Allocation">
          Prices and Allocation
        </option>
        <option key="accommodation" value="Accommodation">
          Accommodation Types
        </option>
      </NativeSelect>
      <Import
        siteId={props.siteId}
        selectedFileType={selectedFileType}
        skip={null}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    </div>
  );
};
export default SettingsImport;
