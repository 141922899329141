import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

export interface ISpinnerProps {
  size?: number;
  id: string;
}

const Spinner = (props: ISpinnerProps) => {
  return (
    <div id={props.id} className="landing-page">
      <CircularProgress size={props.size === null ? 12 : props.size} />
    </div>
  );
};

export default Spinner;
