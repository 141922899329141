import React, { useState, useEffect } from "react";
import NativeSelect from "@mui/material/NativeSelect";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { Accommodationtypes } from "@bookingflow/types";
import "./Website.css";
import { Divider } from "@mui/material";
import firebase from "firebase/compat/app";

export interface IWebsiteProps {
  siteId: string;
  accommodations: Accommodationtypes;
  dataLoaded: boolean;
  db: firebase.firestore.Firestore;
}

const Website = (props: IWebsiteProps) => {
  const [accommodationid, setAccommodationid] = useState("");
  const [detailsAccommodationid, setDetailsAccommodationid] = useState("");
  const [banner, setBanner] = useState<string>("");
  const [image1, setImage1] = useState<string>("");
  const [image2, setImage2] = useState<string>("");
  const [image3, setImage3] = useState<string>("");
  const [accommodationDimensionsWidth, setAccommodationDimensionsWidth] =
    useState<number | undefined>(undefined);
  const [accommodationDimensionsLength, setAccommodationDimensionsLength] =
    useState<number | undefined>(undefined);
  const [accommodationArea, setAccommodationArea] = useState<
    number | undefined
  >(undefined);
  const [accommodationWater, setAccommodationWater] = useState<
    boolean | undefined
  >(undefined);
  const [accommodationElectric, setAccommodationElectric] = useState<
    boolean | undefined
  >(undefined);
  const [accommodationChemical, setAccommodationChemical] = useState<
    boolean | undefined
  >(undefined);
  const [accommodationAwnings, setAccommodationAwnings] = useState<
    boolean | undefined
  >(undefined);
  const [accommodationParking, setAccommodationParking] = useState<
    boolean | undefined
  >(undefined);
  const [accommodationDogs, setAccommodationDogs] = useState<
    boolean | undefined
  >(undefined);

  // get images when accommodationid changes
  useEffect(() => {
    const getBanner = async () => {
      const docRef = props.db.collection("sites").doc(props.siteId);
      const snapshot = await docRef.get();
      const site = snapshot.data();
      if (!site) {
        console.error("site is undefined in firestore: " + props.siteId);
        return;
      }
      setBanner(site.banner);
    };
    getBanner();
    const accommodation = props.accommodations.find(
      (accommodation) => accommodationid === accommodation.id
    );
    if (!accommodation) {
      return;
    }
    setImage1(accommodation.image1);
    setImage2(accommodation.image2);
    setImage3(accommodation.image3);
  }, [props.accommodations, accommodationid, props.db, props.siteId]);
  //get details when detailsAccommodationid changes
  useEffect(() => {
    const accommodation = props.accommodations.find(
      (accommodation) => detailsAccommodationid === accommodation.id
    );
    if (!accommodation) {
      return;
    }
    setAccommodationDimensionsWidth(accommodation.dimensionsWidth);
    setAccommodationDimensionsLength(accommodation.dimensionsLength);
    setAccommodationWater(accommodation.water);
    setAccommodationElectric(accommodation.electric);
    setAccommodationChemical(accommodation.chemical);
    setAccommodationAwnings(accommodation.awnings);
    setAccommodationParking(accommodation.parking);
    setAccommodationDogs(accommodation.dogs);
  }, [props.accommodations, detailsAccommodationid]);

  //calculate area when width and length change
  useEffect(() => {
    if (accommodationDimensionsWidth && accommodationDimensionsLength) {
      setAccommodationArea(
        accommodationDimensionsWidth * accommodationDimensionsLength
      );
    } else {
      setAccommodationArea(undefined);
    }
  }, [accommodationDimensionsWidth, accommodationDimensionsLength]);

  const createSelectItems = () => {
    const items = [
      <option key="placeholder" value="" disabled>
        Select an accommodation type
      </option>,
    ];
    props.accommodations.forEach((d) => {
      items.push(
        <option key={d.id} value={d.id}>
          {d.accommodation}
        </option>
      );
    });
    return items;
  };
  const handleAccommodationTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const target = event.currentTarget;
    const { value } = target;
    setAccommodationid(value);
  };
  const handleDetailsAccommodationTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const target = event.currentTarget;
    const { value } = target;
    setDetailsAccommodationid(value);
  };
  const handleFileSelect = async (event: React.ChangeEvent) => {
    const elem = event.currentTarget as HTMLInputElement;
    if (elem === null) {
      console.error("IMPORT: no file input exists");
    } else if (elem.files === null) {
      console.error("IMPORT: HTMLInputElement contains no files");
    } else if (elem.files.length > 0) {
      const fileObj = elem.files[0];

      const storageRef = firebase.storage().ref();
      // const ts = Math.round((new Date()).getTime() / 1000).toString();
      const filepath =
        elem.id === "banner"
          ? `${props.siteId}/images/banner.jpg`
          : `${props.siteId}/images/${accommodationid}/${elem.id}.jpg`;
      const stagingRef = storageRef.child(filepath);
      await stagingRef.put(fileObj);
      const url = await stagingRef.getDownloadURL();
      switch (elem.id) {
        case "banner":
          setBanner(url);
          props.db
            .collection("sites")
            .doc(props.siteId)
            .update({ banner: url });
          break;
        case "image1":
          setImage1(url);
          props.db
            .collection("sites")
            .doc(props.siteId)
            .collection("accommodation")
            .doc(accommodationid)
            .update({ image1: url });
          break;
        case "image2":
          setImage2(url);
          props.db
            .collection("sites")
            .doc(props.siteId)
            .collection("accommodation")
            .doc(accommodationid)
            .update({ image2: url });
          break;
        case "image3":
          setImage3(url);
          props.db
            .collection("sites")
            .doc(props.siteId)
            .collection("accommodation")
            .doc(accommodationid)
            .update({ image3: url });
          break;
        default:
      }
    }
  };
  const handleDetailsSubmit = () => {
    if (detailsAccommodationid) {
      props.db
        .collection("sites")
        .doc(props.siteId)
        .collection("accommodation")
        .doc(detailsAccommodationid)
        .update({
          dimensionsWidth: accommodationDimensionsWidth,
          dimensionsLength: accommodationDimensionsLength,
          area: accommodationArea,
          water: accommodationWater,
          electric: accommodationElectric,
          chemical: accommodationChemical,
          awnings: accommodationAwnings,
          parking: accommodationParking,
          dogs: accommodationDogs,
        });
    }
  };
  return (
    <div id="website" className="main-container">
      <h3>Website Integration</h3>
      <section>
        <h5>Step 1: Upload Banner</h5>
        <div>
          <h6>Banner Image (recommended 1920 x 600)</h6>
          {!banner && (
            <Input
              type="file"
              inputProps={{ accept: "image/jpeg" }}
              id={"banner"}
              onChange={handleFileSelect}
            />
          )}
          {banner && (
            <div>
              <img src={banner} id="banner-img" alt="banner" />
              <Input
                type="file"
                inputProps={{ accept: "image/jpeg" }}
                id={"banner"}
                onChange={handleFileSelect}
              />
            </div>
          )}
        </div>
      </section>
      <Divider />
      <section>
        <h5>Step 2: Upload Accommodation Photos</h5>
        <NativeSelect
          name="accommodation"
          onChange={handleAccommodationTypeChange}
          aria-label="accommodation-type-input"
          required
          value={accommodationid}
        >
          {props.dataLoaded && createSelectItems()}
        </NativeSelect>

        {accommodationid && (
          <div id="image-container">
            <div>
              <h6>Image 1 (recommended 1000 x 500)</h6>
              {!image1 && (
                <Input
                  type="file"
                  disabled={accommodationid === ""}
                  id={"image1"}
                  onChange={handleFileSelect}
                />
              )}
              {image1 && (
                <div>
                  <img src={image1} alt="img1" />
                  <Input
                    type="file"
                    disabled={accommodationid === ""}
                    id={"image1"}
                    onChange={handleFileSelect}
                  />
                </div>
              )}
            </div>
            <div>
              <h6>Image 2 (recommended 500 x 500)</h6>
              {!image2 && (
                <Input
                  type="file"
                  disabled={accommodationid === ""}
                  inputProps={{ accept: "image/jpeg" }}
                  id={"image2"}
                  onChange={handleFileSelect}
                />
              )}
              {image2 && (
                <div>
                  <img src={image2} alt="img2" />
                  <Input
                    type="file"
                    disabled={accommodationid === ""}
                    inputProps={{ accept: "image/jpeg" }}
                    id={"image2"}
                    onChange={handleFileSelect}
                  />
                </div>
              )}
            </div>
            <div>
              <h6>Image 3 (recommended 500 x 500)</h6>
              {!image3 && (
                <Input
                  type="file"
                  disabled={accommodationid === ""}
                  inputProps={{ accept: "image/jpeg" }}
                  id={"image3"}
                  onChange={handleFileSelect}
                />
              )}
              {image3 && (
                <div>
                  <img src={image3} alt="img3" />
                  <Input
                    type="file"
                    disabled={accommodationid === ""}
                    inputProps={{ accept: "image/jpeg" }}
                    id={"image3"}
                    onChange={handleFileSelect}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </section>
      <section>
        <h5>Step 3: Add Accommodation Details</h5>
        <NativeSelect
          name="accommodation-details"
          id="accommodation-details"
          onChange={handleDetailsAccommodationTypeChange}
          aria-label="accommodation-type-input"
          required
          value={detailsAccommodationid}
        >
          {props.dataLoaded && createSelectItems()}
        </NativeSelect>

        {detailsAccommodationid && (
          <div id="details-container">
            <label>
              <span>Max width(m)</span>
              <Input
                type="number"
                name="accommodation-dimensions-width"
                id="accommodation-dimensions-width"
                value={accommodationDimensionsWidth}
                onChange={(e) => {
                  setAccommodationDimensionsWidth(Number(e.target.value));
                }}
              />
            </label>
            <label>
              <span>Max length(m)</span>
              <Input
                type="number"
                name="accommodation-dimensions-length"
                id="accommodation-dimensions-length"
                value={accommodationDimensionsLength}
                onChange={(e) => {
                  setAccommodationDimensionsLength(Number(e.target.value));
                }}
              />
            </label>
            <label>
              <span>Area(m²)</span>
              <Input
                type="number"
                name="accommodation-area"
                id="accommodation-area"
                disabled={true}
                value={accommodationArea}
                readOnly={true}
              />
            </label>
            <label>
              <span>Water Included</span>
              <NativeSelect
                name="accommodation-water"
                id="accommodation-water"
                onChange={(e) => {
                  if (e.target.value === "yes") {
                    setAccommodationWater(true);
                  } else {
                    setAccommodationWater(false);
                  }
                }}
                aria-label="accommodation-water-input"
                required
                value={accommodationWater ? "yes" : "no"}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </NativeSelect>
            </label>
            <label>
              <span>Electric Included</span>
              <NativeSelect
                name="accommodation-electric"
                id="accommodation-electric"
                onChange={(e) => {
                  if (e.target.value === "yes") {
                    setAccommodationElectric(true);
                  } else {
                    setAccommodationElectric(false);
                  }
                }}
                aria-label="accommodation-electric-input"
                required
                value={accommodationElectric ? "yes" : "no"}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </NativeSelect>
            </label>
            <label>
              <span>Chemical disposal available</span>
              <NativeSelect
                name="accommodation-disposal"
                id="accommodation-disposal"
                onChange={(e) => {
                  if (e.target.value === "yes") {
                    setAccommodationChemical(true);
                  } else {
                    setAccommodationChemical(false);
                  }
                }}
                aria-label="accommodation-disposal-input"
                required
                value={accommodationChemical ? "yes" : "no"}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </NativeSelect>
            </label>
            <label>
              <span>Car Parking</span>
              <NativeSelect
                name="accommodation-parking"
                id="accommodation-parking"
                onChange={(e) => {
                  if (e.target.value === "yes") {
                    setAccommodationParking(true);
                  } else {
                    setAccommodationParking(false);
                  }
                }}
                aria-label="accommodation-parking-input"
                required
                value={accommodationParking ? "yes" : "no"}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </NativeSelect>
            </label>
            <label>
              <span>Awnings Allowed</span>
              <NativeSelect
                name="accommodation-awnings"
                id="accommodation-awnings"
                onChange={(e) => {
                  if (e.target.value === "yes") {
                    setAccommodationAwnings(true);
                  } else {
                    setAccommodationAwnings(false);
                  }
                }}
                aria-label="accommodation-awnings-input"
                required
                value={accommodationAwnings ? "yes" : "no"}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </NativeSelect>
            </label>
            <label>
              <span>Dogs Allowed</span>
              <NativeSelect
                name="accommodation-dogs"
                id="accommodation-dogs"
                onChange={(e) => {
                  if (e.target.value === "yes") {
                    setAccommodationDogs(true);
                  } else {
                    setAccommodationDogs(false);
                  }
                }}
                aria-label="accommodation-dogs-input"
                required
                value={accommodationDogs ? "yes" : "no"}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </NativeSelect>
            </label>
            <Button
              id="details-submit"
              variant="contained"
              onClick={handleDetailsSubmit}
              className="waves-effect waves-light btn right"
            >
              Confirm
            </Button>
          </div>
        )}
      </section>
      <Divider />
      <section>
        <h5>Step 4: Embed Booking widget</h5>
        <p>Button snippet:</p>
        <textarea
          name=""
          id=""
          cols={30}
          rows={10}
          value={`<button href='https://portal.bookingflow.app/book?site_id=${props.siteId}>Book</button>`}
        />
        <p>
          Paste this html snippet into your website and style it however you
          want.
        </p>
        <p>
          It will link your customers through to our booking portal to complete
          their bookings which will automatically sync back here
        </p>
      </section>
      <section>
        <h5>Step 5: Check out your new booking portal</h5>
        <a href={`https://portal.bookingflow.app/book?site_id=${props.siteId}`}>
          <Button variant="outlined">click here</Button>
        </a>
      </section>
    </div>
  );
};

export default Website;
