import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import React from "react";
import WelcomeImage from "../static/images/i-can-fly.svg";
import firebase from "firebase/compat/app";
import Grid from "@mui/material/Grid";

interface IWelcomeProps {
  db: firebase.firestore.Firestore;
  setWelcomeVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setImportVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Welcome = (props: IWelcomeProps) => {
  const handleSubmit = () => {
    props.setWelcomeVisible(false);
    props.setImportVisible(true);
  };
  return (
    <Grid>
      <img src={WelcomeImage} alt="Welcome" />
      <h2>Welcome to bookingflow</h2>
      <p>Let's get you set up, it will only take a few minutes</p>
      <Divider />
      <p>
        By continuing, you agree to the
        <a href="https://bookingflow.app/terms#terms-of-service">
          {" "}
          Terms of Service
        </a>{" "}
        and
        <a href="https://bookingflow.app/terms#privacy-policy">
          {" "}
          Privacy Policy
        </a>
      </p>
      <Button disabled={true}>Back</Button>
      <Button onClick={handleSubmit}>Next</Button>
    </Grid>
  );
};

export default Welcome;
