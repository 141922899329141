import Skeleton from "@mui/material/Skeleton";
import React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import AccountIcon from "@mui/icons-material/AccountCircle";
import Add from "@mui/icons-material/Add";
import RatesIcon from "@mui/icons-material/AttachMoney";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DocumentIcon from "@mui/icons-material/Description";
import HelpIcon from "@mui/icons-material/Help";
import MenuIcon from "@mui/icons-material/Menu";
import BookingIcon from "@mui/icons-material/TableChart";
import StaticsIcon from "@mui/icons-material/TableChart";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Accommodationtypes } from "@bookingflow/types";
import { ReactComponent as Logo } from "../static/images/BrandLogo.svg";
import AddBooking from "./AddBooking";
import SignOutButton from "./firebase/SignOut";
import "./Navigation.css";
import { Auth } from "firebase/auth";

export interface INavigationProps {
  db: firebase.firestore.Firestore;
  func: firebase.functions.Functions;
  auth: Auth;
  authUser: boolean;
  accommodations: Accommodationtypes;
  siteId: string;
  planType: string;
  dataLoaded: boolean;
}

const Navigation = (props: INavigationProps) => {
  const [open, setOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);

  const handleOpen = () => {
    setAddOpen(true);
  };
  const skeletonDrawerContent = (
    <div className="navContainer">
      <div id="sidebarBranding">
        <Logo />
        <div id="BrandText">BookingFlow</div>
      </div>
      <div className="navTop">
        <div className="navIconTop">
          <Skeleton
            id="add-skeleton"
            variant="circular"
            width={20}
            height={20}
          />
        </div>
        <div className="navText">
          <div className="navLink">
            <List>
              <Link id="dashboardLink" to="/">
                <ListItem className="navigationList">
                  <Skeleton variant="rectangular" width={150} height={20} />
                </ListItem>
              </Link>
              <Link id="bookingLink" to="/">
                <ListItem className="navigationList">
                  <Skeleton variant="rectangular" width={150} height={20} />
                </ListItem>
              </Link>
              <Link id="calendarLink" to="/">
                <ListItem className="navigationList">
                  <Skeleton variant="rectangular" width={150} height={20} />
                </ListItem>
              </Link>
              <Link id="allocationLink" to="/">
                <ListItem className="navigationList">
                  <Skeleton variant="rectangular" width={150} height={20} />
                </ListItem>
              </Link>
              <Link id="documentsLink" to="/">
                <ListItem className="navigationList">
                  <Skeleton variant="rectangular" width={150} height={20} />
                </ListItem>
              </Link>
              {(props.planType === "premium" || props.planType === "plus") && (
                <Link id="staticsLink" to="/">
                  <ListItem className="navigationList">
                    <Skeleton variant="rectangular" width={150} height={20} />
                  </ListItem>
                </Link>
              )}
            </List>
          </div>
        </div>
      </div>
      <div className="navIcons">
        <div className="navIconBottom">
          <a
            id="helpLink"
            href={process.env.REACT_APP_HELP_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Skeleton
              id="help-skeleton"
              className="skeleton"
              variant="circular"
              width={22.5}
              height={22.5}
            />
          </a>
          <Link id="accountLink" to="/">
            <Skeleton
              id="account-skeleton"
              className="skeleton"
              variant="circular"
              width={22.5}
              height={22.5}
            />
          </Link>
          <SignOutButton auth={props.auth} />
        </div>
      </div>
    </div>
  );
  const drawerContent = (
    <div className="navContainer">
      <div id="sidebarBranding">
        <Logo />
        <div id="BrandText">BookingFlow</div>
      </div>
      <div className="navTop">
        <div className="navIconTop">
          <Button
            id="menuButton"
            variant="text"
            aria-label="Add"
            onClick={handleOpen}
          >
            <Add />
          </Button>
        </div>
        <div className="navText">
          <div className="navLink">
            <List>
              <Link id="dashboardLink" to="/">
                <ListItem className="navigationList">
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  Dashboard
                </ListItem>
              </Link>
              <Link id="bookingLink" to="/bookings">
                <ListItem className="navigationList">
                  <ListItemIcon>
                    <BookingIcon />
                  </ListItemIcon>
                  Bookings
                </ListItem>
              </Link>
              <Link id="calendarLink" to="/calendar">
                <ListItem className="navigationList">
                  <ListItemIcon>
                    <CalendarIcon />
                  </ListItemIcon>
                  Calendar
                </ListItem>
              </Link>
              <Link id="allocationLink" to="/rates">
                <ListItem className="navigationList">
                  <ListItemIcon>
                    <RatesIcon />
                  </ListItemIcon>
                  Allocation
                </ListItem>
              </Link>
              <Link id="documentsLink" to="/documents">
                <ListItem className="navigationList">
                  <ListItemIcon>
                    <DocumentIcon />
                  </ListItemIcon>
                  Documents
                </ListItem>
              </Link>
              {(props.planType === "premium" || props.planType === "plus") &&
                window.location.hostname === "localhost" && (
                  <Link id="staticsLink" to="/statics">
                    <ListItem className="navigationList">
                      <ListItemIcon>
                        <StaticsIcon />
                      </ListItemIcon>
                      Statics
                    </ListItem>
                  </Link>
                )}
            </List>
          </div>
        </div>
      </div>
      <div className="navIcons">
        <div className="navIconBottom">
          <a
            id="helpLink"
            href={process.env.REACT_APP_HELP_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            <IconButton color="inherit" aria-label="help" size="large">
              <HelpIcon />
            </IconButton>
          </a>
          <Link id="accountLink" to="/account">
            <IconButton color="inherit" aria-label="Account" size="large">
              <AccountIcon />
            </IconButton>
          </Link>
          <SignOutButton auth={props.auth} />
        </div>
      </div>
    </div>
  );
  const toggleDrawer = (open: boolean) => () => {
    setOpen(open);
  };

  const handleClose = () => {
    setAddOpen(false);
  };

  return (
    <div>
      <Hidden mdUp implementation="css">
        <AppBar id="appbar" position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Menu"
              onClick={toggleDrawer(true)}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Hidden>
      <nav id="sidebarNav">
        <Hidden mdUp implementation="css">
          <Drawer id="mobile-drawer" open={open} onClose={toggleDrawer(false)}>
            <div
              tabIndex={0}
              role="button"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              {props.dataLoaded && drawerContent}
              {!props.dataLoaded && skeletonDrawerContent}
            </div>
          </Drawer>
          <AddBooking
            db={props.db}
            dataLoaded={props.dataLoaded}
            addOpen={addOpen}
            accommodations={props.accommodations}
            editID=""
            handleClose={handleClose}
            siteId={props.siteId}
            func={props.func}
          />
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer variant="permanent" open onClose={toggleDrawer(false)}>
            {drawerContent}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};

export default Navigation;
