import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Add from "@mui/icons-material/Add";
import Clear from "@mui/icons-material/Clear";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import React, { useState } from "react";
import {
  AccommodationGroup,
  Accommodationtypes,
  IAccommodationtype,
  IAccommodationData,
} from "@bookingflow/types";
//import './Settings.css'
import "./Accommodation.css";

export interface ISettingsProps {
  accommodations: Accommodationtypes;
  db: firebase.firestore.Firestore;
  siteId: string;
}

const Settings = (props: ISettingsProps) => {
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [accommodation, setAccommodation] = useState("");
  const [includedIndividuals, setIncludedIndividuals] = useState<number>(0);
  const [group, setGroup] = useState<AccommodationGroup | undefined>(undefined);

  const getAccommodationtypes = () => {
    const items: JSX.Element[] = [];
    const sortedAccommodations = props.accommodations.sort((a, b) => {
      if (a.accommodation < b.accommodation) return -1;
      if (a.accommodation > b.accommodation) return 1;
      return 0;
    });
    sortedAccommodations.forEach((d: IAccommodationtype) => {
      items.push(
        <div className="existing-accommodations" key={d.id}>
          <TextField id={d.id} key={d.id} value={d.accommodation} disabled />
          <TextField
            id={d.id + "accommodationGroup"}
            key={d.id + "accommodationGroup"}
            value={d.group}
            disabled
          />
          <TextField
            id={d.id + "includedIndividuals"}
            key={d.id + "includedIndividuals"}
            value={d.includedIndividuals}
            disabled
          />
          <IconButton
            className="acc-delete-button"
            onClick={handleOpen(d.id)}
            aria-label={"delete-" + d.accommodation}
            size="large"
          >
            <Clear />
          </IconButton>
        </div>
      );
    });

    return items;
  };

  const addAccommodationtype = async () => {
    // TODO: [BOOK-124] handle doing adding accommodation types offline
    if (!accommodation || !group || isNaN(includedIndividuals)) {
      return;
    }
    // Add to Firestore
    const siteRef = props.db.collection("sites");
    const sites = siteRef.doc(props.siteId);
    const collectionRef = sites.collection("accommodation");
    const firestoreAccommodationtype: IAccommodationData = {
      visible: true,
      accommodation: accommodation,
      includedIndividuals: includedIndividuals,
      status: "active",
      group: group,
      banner: "",
      image1: "",
      image2: "",
      image3: "",
    };
    await collectionRef.add(firestoreAccommodationtype);
    // Get id back from Firestore
    setAccommodation("");
    setIncludedIndividuals(0);
  };

  const deleteAccommodationtype =
    (id: string) =>
    async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      // Delete from Firestore
      const siteRef = props.db.collection("sites");
      const sites = siteRef.doc(props.siteId);
      await sites
        .collection("accommodation")
        .doc(id)
        .update({ status: "deleted" });
      //TODO: delete storage photos
      handleClose();
    };

  const handleOpen = (id: string) => () => {
    setOpen(true);
    setId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAccommodationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.currentTarget;
    const value = target.value;
    setAccommodation(value);
  };
  const handleIncludedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const value = Number(target.value);
    setIncludedIndividuals(value);
  };
  const getOptions = (): JSX.Element[] => {
    const items = [
      <option key="placeholder" value="" disabled>
        Select an accommodation group
      </option>,
    ];
    for (const group of ["Tent", "Room", "Tourer", "Motorhome", "Static"]) {
      items.push(
        <option key={group} value={group}>
          {group}
        </option>
      );
    }
    return items;
  };
  const handleGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const value = target.value as AccommodationGroup;
    setGroup(value);
  };
  return (
    <div className="main-container">
      <h5>App Setup</h5>
      <h6 id="existingAccommodationTypes">Accommodation Types</h6>
      <div id="accommodationInput">
        <TextField
          label="Accommodation Name"
          id="newInput"
          required={true}
          onChange={handleAccommodationChange}
          placeholder="Add Accommodation type"
          value={accommodation}
        />
        <TextField
          id="group-select"
          select={true}
          required={true}
          SelectProps={{
            value: "",
            native: true,
          }}
          InputLabelProps={{ shrink: true }}
          label="Accommodation Group"
          value={group}
          onChange={handleGroupChange}
        >
          {getOptions()}
        </TextField>
        <TextField
          label="Included People"
          type="number"
          inputProps={{
            min: 0,
            step: "1",
          }}
          required={true}
          onChange={handleIncludedChange}
        />
        <IconButton
          aria-label="Add"
          onClick={addAccommodationtype}
          size="large"
        >
          <Add />
        </IconButton>
      </div>
      {getAccommodationtypes()}
      <Modal
        aria-labelledby="delete-confirmation"
        aria-describedby="confirm-accommodation-type-should-be-deleted"
        open={open}
        onClose={handleClose}
        id="deleteAccommodation"
      >
        <Card>
          <div>
            <h6>Are you sure you want to delete {props.accommodations.find((accommodation) => accommodation.id === id)?.accommodation}</h6>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={deleteAccommodationtype(id)}
              type="submit"
              value="Submit"
            >
              Confirm
            </Button>
          </div>
        </Card>
      </Modal>
    </div>
  );
};

export default Settings;
