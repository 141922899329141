import { ButtonGroup, Container, FormControl, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useRef } from "react";
import {
  Auth,
  fetchSignInMethodsForEmail,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useState } from "react";
import { FirebaseError } from "firebase/app";

type SignOutProps = {
  auth: Auth;
};
function translateFirebaseAuthError(code: string) {
  switch (code) {
    case "auth/email-already-in-use":
      return "The email address is already used by another account";
    case "auth/requires-recent-login":
      return "Please log in again to continue.";
    case "auth/too-many-requests":
      return "You have entered an incorrect password too many times. Please try again in a few minutes.";
    case "auth/user-cancelled":
      return "Please authorize the required permissions to sign in to the application";
    case "auth/user-not-found":
      return "That email address doesn't match an existing account";
    case "auth/user-token-expired":
      return "Please log in again to continue.";
    case "auth/weak-password":
      return "The password must be at least 6 characters long";
    case "auth/wrong-password":
      return "The email and password you entered don't match";
    case "auth/network-request-failed":
      return "A network error has occurred";
    case "auth/invalid-phone-number":
      return "The phone number is invalid.";
    case "auth/invalid-verification-code":
      return "The confirmation code is invalid.";
    case "auth/code-expired":
      return "This code is no longer valid";
    case "auth/expired-action-code":
      return "This code has expired.";
    case "auth/invalid-action-code":
      return "The action code is invalid. This can happen if the code is malformed, expired, or has already been used.";
    case "auth/password-does-not-meet-requirements":
      return "Missing password requirements:";
    default:
      return code;
  }
}

const SignOutButton = (props: SignOutProps) => {
  const myRef = useRef(null);
  const auth = getAuth();
  const [start, setStart] = useState(true);
  const [emailPassed, setEmailPassed] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("test");
  const [error, setError] = useState("");
  const [reset, setReset] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [resetMessage, setResetMessage] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  useEffect(()=>{
    (myRef.current as unknown as HTMLInputElement)?.focus();
  }, [emailPassed])
  const handleEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    try {
      const methods = await fetchSignInMethodsForEmail(auth, email);
      if (methods.length < 1) {
        setEmailError("That email address doesn't match an existing account");
      } else {
        setEmailError("");
        setEmailPassed(true);
        
      }
    } catch (error) {
      setEmailError(translateFirebaseAuthError((error as FirebaseError).code));
    }
  };
  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError(translateFirebaseAuthError((error as FirebaseError).code));
    }
  };
  const toggleReset = () => {
    setReset(!reset);
    setStart(!start);
  };
  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setResetError(false);
      setResetMessage("Password reset link sent!");
      setReset(false);
      setConfirmation(true);
      setError("");
    } catch (error) {
      setResetError(true);
      setResetMessage((error as FirebaseError).message);
    }
  };
  const handleConfirmation = () => {
    setConfirmation(!confirmation);
    setStart(!start);
  };
  return (
    <Container maxWidth="xs">
      {start && (
        <form onSubmit={handleEmail}>
          <FormControl
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 1,
              m: 1,
              borderRadius: 1,
            }}
          >
            <TextField
              error={emailError ? true : false}
              id="standard-basic"
              type="email"
              label="email"
              variant="standard"
              onChange={(e) => setEmail(e.target.value)}
              helperText={emailError}
            />

            {!emailPassed && <Button type="submit">Next</Button>}
          </FormControl>
        </form>
      )}
      {start && emailPassed && (
        <form onSubmit={handleSignIn}>
          <FormControl
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 1,
              m: 1,
              borderRadius: 1,
            }}
          >
            <TextField
              error={error ? true : false}
              inputRef={myRef}
              id="password"
              type="password"
              label="password"
              variant="standard"
              onChange={(e) => setPassword(e.target.value)}
              helperText={error}
            />
            {error && (
              <a href="/#" onClick={toggleReset}>
                Forgotten your password?
              </a>
            )}
            <Button type="submit">Next</Button>
          </FormControl>
        </form>
      )}

      {reset && (
        <form onSubmit={handlePasswordReset}>
          <FormControl
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 1,
              m: 1,
              borderRadius: 1,
            }}
          >
            <h4>Recover password</h4>
            <p>
              Get instructions sent to this email that explain how to reset your
              password
            </p>
            <TextField
              error={resetError ? true : false}
              type="email"
              label="email"
              value={email}
              variant="standard"
              onChange={(e) => setEmail(e.target.value)}
              helperText={resetMessage}
            />
          </FormControl>
          <ButtonGroup
            sx={{
              display: "flex",
              justifyContent: "end",
              p: 1,
              m: 1,
              borderRadius: 1,
            }}
          >
            <Button onClick={toggleReset}>Cancel</Button>
            <Button variant="contained" type="submit">
              Send
            </Button>
          </ButtonGroup>
        </form>
      )}
      {confirmation && (
        <form onSubmit={handleConfirmation}>
          <FormControl
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 1,
              m: 1,
              borderRadius: 1,
            }}
          >
            <h4>Check your email</h4>
            <p>
              Follow the instructions sent to {email} to recover your password
            </p>
            <Button type="submit">Done</Button>
          </FormControl>
        </form>
      )}
    </Container>
  );
};
export default SignOutButton;
