import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import UploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";
import Input from "@mui/material/Input";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import axios from "axios";
import firebase from "firebase/compat/app";
import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import DeleteDocument from "./DeleteDocument";
import "./Documents.css";
import { getAuth } from "firebase/auth";

export interface IFile {
  id: string;
  user: string;
  filename: string;
  location: string;
}
export interface IDocumentProps {
  db: firebase.firestore.Firestore;
  siteId: string;
  func: firebase.functions.Functions;
}

const DocumentsTab = (props: IDocumentProps) => {
  const [fileList, setFileList] = useState<IFile[]>([]);
  const [filename, setFilename] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [file, setFile] = useState<IFile | undefined>(undefined);
  const [message, setMessage] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const errorLogger = props.func.httpsCallable("error");
  const fileStorage = firebase.storage();
  if (window.location.hostname === "localhost") {
    fileStorage.useEmulator("localhost", 9199);
  }
  const getFiles = useCallback(async () => {
    const siteRef = props.db.collection("sites");
    const sites = siteRef.doc(props.siteId);
    const collectionRef = sites.collection("files");
    const snapshot = await collectionRef.get();
    const siteFileList = [];
    for (const doc of snapshot.docs) {
      const data: IFile = doc.data() as IFile;
      siteFileList.push(data);
    }
    setFileList(siteFileList);
  }, [props.db, props.siteId]);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getFiles();
    }
    return () => {
      isMounted = false;
    };
  }, [getFiles]);
  const handleSnackClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };
  const onSnackClose = () => {
    setSnackOpen(false);
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fullPath = event.target.value;
    const startIndex =
      fullPath.indexOf("\\") >= 0
        ? fullPath.lastIndexOf("\\")
        : fullPath.lastIndexOf("/");
    let filename = fullPath.substring(startIndex);
    if (filename.indexOf("\\") === 0 || filename.indexOf("/") === 0) {
      filename = filename.substring(1);
    }
    setFilename(filename);
  };
  const deleteFile = (file: IFile) => async () => {
    setFile(file);
    setDeleteModalOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteModalOpen(false);
  };
  const upload = async (event: React.MouseEvent) => {
    const elem = document.getElementById("documentInput") as HTMLInputElement;
    if (elem === null) {
      console.error("IMPORT: no file selected");
    } else if (elem.files === null) {
      console.error("IMPORT: HTMLInputElement contains no files");
    } else if (elem.files.length > 0) {
      const exists = fileList.some((file: IFile) => file.filename === filename);
      if (exists) {
        setMessage("new file cannot have the same name as existing file");
        setSnackOpen(true);
        return;
      }
      const fileObj = elem.files[0];
      const pathReference = fileStorage.ref(
        `${props.siteId}/documents/${filename}`,
      );
      const uploadTask = await pathReference.put(fileObj);
      const location = await uploadTask.ref.getDownloadURL();
      const currentUser = getAuth().currentUser;
      if (!currentUser) {
        getAuth().signOut();
        return;
      }
      const email = currentUser.email;
      const siteRef = props.db.collection("sites");
      const sites = siteRef.doc(props.siteId);
      const collectionRef = await sites.collection("files");
      const data = { email, filename, location };
      const doc = await collectionRef.add(data).catch((err) => {
        errorLogger({ error: err });
      });
      if (!doc) {
        setMessage("an unknown error occurred");
        setSnackOpen(true);
        return;
      }
      const id = doc.id;
      const file = { ...data, id };
      setFileList([...fileList, file] as IFile[]);
    }
  };
  const download = (file: IFile) => async () => {
    const fileStorage = firebase.storage();
    const pathReference = fileStorage.ref(
      `${props.siteId}/documents/${file.filename}`,
    );
    const url = await pathReference.getDownloadURL();
    await axios.get(url);
    // TODO: handle errors https://firebase.google.com/docs/storage/web/handle-errors
  };
  const getTableContent = () => {
    const items: JSX.Element[] = [];
    for (const file of fileList) {
      items.push(
        <TableRow key={file.id}>
          <TableCell>{file.filename}</TableCell>
          <TableCell>
            <Tooltip title="Download">
              <a
                href={file.location}
                download="proposed_file_name"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  onClick={download(file)}
                  endIcon={<DownloadIcon />}
                >
                  Download
                </Button>
              </a>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                variant="outlined"
                onClick={deleteFile(file)}
                endIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </Tooltip>
          </TableCell>
        </TableRow>,
      );
    }
    return items;
  };
  const tableContent = getTableContent();
  return (
    <div id="documents" className="main-container">
      <Helmet>
        <title>Documents</title>
      </Helmet>
      <Snackbar
        className="warning-snackbar"
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar">
              <p>
                <WarningIcon />
                {message}
              </p>
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={onSnackClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
      <Typography variant="h3">Documents</Typography>
      <Card>
        <CardContent>
          {fileList.length === 0 && (
            <h6>You don't have any documents - why not upload one? </h6>
          )}
          <Input
            type="file"
            id="documentInput"
            name="filename"
            onChange={handleFileChange}
          />
          <Button variant="outlined" onClick={upload} endIcon={<UploadIcon />}>
            Upload
          </Button>
          {fileList.length !== 0 && (
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="Document list">
                <TableHead>
                  <TableRow>
                    <TableCell>File Name</TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tableContent}</TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>
      <DeleteDocument
        db={props.db}
        siteId={props.siteId}
        file={file}
        setFileList={setFileList}
        fileList={fileList}
        deleteOpen={deleteModalOpen}
        fileStorage={fileStorage}
        handleClose={handleDeleteClose}
      />
    </div>
  );
};

export default DocumentsTab;
