import { DateTime } from 'luxon'
import { convertTimestamp, convertToUTCDate, getAccommodationsQuery } from '@bookingflow/utils'
import { Accommodationtypes, Bookings, IBooking } from '@bookingflow/types'
import firebase from 'firebase/compat/app'
import 'firebase/compat/functions'
import 'firebase/compat/firestore'
import { getAuth } from 'firebase/auth'

const fetchCreatedTY = (
  setDashboardTY: React.Dispatch<React.SetStateAction<Bookings>>,
  siteId: string,
  dataLoaded: boolean,
  setDashboardTYLoaded: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const db = firebase.firestore()
  const end = DateTime.utc().endOf('month').endOf('day')
  const start = end.startOf('month').startOf('day')
  const unsubscribe = db
    .collection('sites')
    .doc(siteId)
    .collection('transactional')
    .where('created', '<=', end.toJSDate())
    .where('created', '>=', start.toJSDate())
    .where('cancelled', '==', false)
    .onSnapshot((snapshot) => {
      const TY: Bookings = []
      for (const doc of snapshot.docs) {
        const bookings = convertTimestamp(doc.data()) as unknown as IBooking
        TY.push(bookings)
      }
      setDashboardTY(TY)
      if (!dataLoaded) {
        setDashboardTYLoaded(true)
      }
    })
  return unsubscribe
}
const fetchCreatedLY = async (
  setDashboardLY: React.Dispatch<React.SetStateAction<Bookings>>,
  siteId: string,
  dataLoaded: boolean,
  setDashboardLYLoaded: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const db = firebase.firestore()
  const endTY = DateTime.utc().endOf('month').endOf('day')
  const startTY = endTY.startOf('month').startOf('day')
  const endLY = endTY.plus({ year: -1 })
  const startLY = startTY.plus({ year: -1 })
  // this does not need to be a listener as it shouldn't update
  const snapshot = await db
    .collection('sites')
    .doc(siteId)
    .collection('transactional')
    .where('created', '<=', endLY.toJSDate())
    .where('created', '>=', startLY.toJSDate())
    .where('cancelled', '==', false)
    .get()

  const LY: Bookings = []
  for (const doc of snapshot.docs) {
    const bookings = convertTimestamp(doc.data()) as unknown as IBooking
    LY.push(bookings)
  }
  setDashboardLY(LY)
  if (!dataLoaded) {
    setDashboardLYLoaded(true)
  }
}
const fetchUpcomingArrivals = (
  setUpcomingArrivals: React.Dispatch<React.SetStateAction<Bookings>>,
  siteId: string,
  dataLoaded: boolean,
  setUpcomingArrivalsLoaded: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const db = firebase.firestore()
  const today = convertToUTCDate(DateTime.local().startOf('day').toJSDate())
  const tomorrow = convertToUTCDate(DateTime.local().plus({ days: 1 }).startOf('day').toJSDate())
  const unsubscribe = db
    .collection('sites')
    .doc(siteId)
    .collection('transactional')
    .where('arrive', '>=', today)
    .where('arrive', '<=', tomorrow)
    .where('cancelled', '==', false)
    .onSnapshot((snapshot) => {
      const upcomingArrivals: Bookings = []
      for (const doc of snapshot.docs) {
        const bookings = convertTimestamp(doc.data()) as unknown as IBooking
        upcomingArrivals.push(bookings)
      }
      setUpcomingArrivals(upcomingArrivals)
      if (!dataLoaded) {
        setUpcomingArrivalsLoaded(true)
      }
    })
  return unsubscribe
}
const fetchUpcomingDepartures = (
  setUpcomingDepartures: React.Dispatch<React.SetStateAction<Bookings>>,
  siteId: string,
  dataLoaded: boolean,
  setUpcomingDeparturesLoaded: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const db = firebase.firestore()
  const today = DateTime.local().startOf('day').toJSDate()
  const tomorrow = DateTime.local().plus({ days: 1 }).endOf('day').toJSDate()
  const unsubscribe = db
    .collection('sites')
    .doc(siteId)
    .collection('transactional')
    .where('depart', '>=', today)
    .where('depart', '<=', tomorrow)
    .where('cancelled', '==', false)
    .onSnapshot((snapshot) => {
      const upcomingDepartures: Bookings = []
      for (const doc of snapshot.docs) {
        const bookings = convertTimestamp(doc.data()) as unknown as IBooking
        upcomingDepartures.push(bookings)
      }
      setUpcomingDepartures(upcomingDepartures)
      if (!dataLoaded) {
        setUpcomingDeparturesLoaded(true)
      }
    })
  return unsubscribe
}

const fetchRecentBookings = (
  setRecentBookings: React.Dispatch<React.SetStateAction<Bookings>>,
  siteId: string,
  dataLoaded: boolean,
  setRecentBookingsLoaded: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const db = firebase.firestore()
  const yesterday = DateTime.local().plus({ days: -1 }).startOf('day').toJSDate()
  const unsubscribe = db
    .collection('sites')
    .doc(siteId)
    .collection('transactional')
    .where('created', '>=', yesterday)
    .where('cancelled', '==', false)
    .onSnapshot((snapshot) => {
      const recentBookings: Bookings = []
      for (const doc of snapshot.docs) {
        const bookings = convertTimestamp(doc.data()) as unknown as IBooking
        recentBookings.push(bookings)
      }
      setRecentBookings(recentBookings)
      if (!dataLoaded) {
        setRecentBookingsLoaded(true)
      }
    })
  return unsubscribe
}

// fetch all bookings from db
export const fetchBookings = (
  setDashboardLY: React.Dispatch<React.SetStateAction<Bookings>>,
  setDashboardTY: React.Dispatch<React.SetStateAction<Bookings>>,
  setRecentBookings: React.Dispatch<React.SetStateAction<Bookings>>,
  setUpcomingArrivals: React.Dispatch<React.SetStateAction<Bookings>>,
  setUpcomingDepartures: React.Dispatch<React.SetStateAction<Bookings>>,
  siteId: string,
  dataLoaded: boolean,
  setDashboardLYLoaded: React.Dispatch<React.SetStateAction<boolean>>,
  setDashboardTYLoaded: React.Dispatch<React.SetStateAction<boolean>>,
  setRecentBookingsLoaded: React.Dispatch<React.SetStateAction<boolean>>,
  setUpcomingArrivalsLoaded: React.Dispatch<React.SetStateAction<boolean>>,
  setUpcomingDeparturesLoaded: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const unsubscribeAll = []
  fetchCreatedLY(setDashboardLY, siteId, dataLoaded, setDashboardLYLoaded)
  unsubscribeAll.push(fetchCreatedTY(setDashboardTY, siteId, dataLoaded, setDashboardTYLoaded))
  unsubscribeAll.push(
    fetchRecentBookings(setRecentBookings, siteId, dataLoaded, setRecentBookingsLoaded)
  )
  unsubscribeAll.push(
    fetchUpcomingArrivals(setUpcomingArrivals, siteId, dataLoaded, setUpcomingArrivalsLoaded)
  )
  unsubscribeAll.push(
    fetchUpcomingDepartures(setUpcomingDepartures, siteId, dataLoaded, setUpcomingDeparturesLoaded)
  )
  return unsubscribeAll
}
export const fetchAccommodationtypes = (
  setAccommodations: React.Dispatch<React.SetStateAction<Accommodationtypes>>,
  siteId: string,
  dataLoaded: boolean,
  setAccommodationsLoaded: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const func = firebase.app().functions('europe-west2')
  const db = firebase.firestore()
  const errorLogger = func.httpsCallable('error')
  const query = getAccommodationsQuery(siteId, errorLogger, db)
  query.onSnapshot((snapshot: any) => {
    const accommodations = snapshot.docs.map((doc: firebase.firestore.DocumentData) => {
      return { id: doc.id, ...doc.data() }
    })
    setAccommodations(accommodations as unknown as Accommodationtypes)
    if (!dataLoaded) {
      setAccommodationsLoaded(true)
    }
  })
}
export const fetchUserData = async (
  setPlanAmount: React.Dispatch<React.SetStateAction<string>>,
  setPlanType: React.Dispatch<React.SetStateAction<string>>,
  setFirstLogin: React.Dispatch<React.SetStateAction<boolean>>,
  setSiteId: React.Dispatch<React.SetStateAction<string>>,
  setIsSignedUp: React.Dispatch<React.SetStateAction<boolean | undefined>>
) => {
  const func = firebase.app().functions('europe-west2')
  const db = firebase.firestore()
  const auth = getAuth()
  const errorLogger = func.httpsCallable('error')
  const currentUser = auth.currentUser
  if (!currentUser) {
    auth.signOut()
    return
  }
  const doc = await db.collection('user').doc(currentUser.uid).get()
  const user = doc.data()
  if (!user) {
    errorLogger({ error: 'no document data exists for user: ' + currentUser.uid })
    return
  }
  //check subscription is active
  if (user.subscription.status === 'active') {
    const planName: string | undefined = user.plan
    if (!planName) {
      errorLogger({ error: 'plan does not exist on the document for user: ' + currentUser.uid })
      return
    }
    const product = await db
      .collection('products')
      .where('name', '==', `bookingflow ${planName}`)
      .get()
    const productDoc = product.docs[0]
    const planQuery = productDoc.ref.collection('plans')

    const plan = await planQuery.get()
    const planAmount = plan.docs[0].data().amount / 100
    setFirstLogin(user.firstLogin)
    setPlanType(planName)
    setPlanAmount(planAmount.toString())
    const token = await currentUser.getIdTokenResult()
    const { siteId } = token.claims
    setSiteId(siteId)
    if (siteId === undefined) {
      errorLogger('siteId is not defined')
      throw new Error('siteId is undefined')
    }
    if (planName) {
      setIsSignedUp(true)
    }
  } else {
    throw new Error('Subscription is not active')
  }
}
