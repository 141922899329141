import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import firebase from "firebase/compat/app";
import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { StripeCardElement } from "@stripe/stripe-js";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export interface IPaymentProps {
  paymentPlan: string;
  setPaymentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setPricePlanVisible: React.Dispatch<React.SetStateAction<boolean>>;
  pricePlanVisible: boolean;
  paymentVisible: boolean;
  uid: string;
  email: string;
  siteId: string;
  siteName: string;
  planAmount: string;
  password: string;
  db: firebase.firestore.Firestore;
  firstname: string;
  lastname: string;
  emailUpdates: boolean;
  func: firebase.functions.Functions;
}

const togglePricePlan =
  (
    setPaymentVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setPricePlanVisible: React.Dispatch<React.SetStateAction<boolean>>,
    pricePlanVisible: boolean,
    paymentVisible: boolean,
  ) =>
  (event: React.MouseEvent) => {
    setPricePlanVisible(!pricePlanVisible);
    setPaymentVisible(!paymentVisible);
  };
const Payment = (props: IPaymentProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [promoCode, setPromoCode] = useState<string>("");
  const [promoCodeID, setPromoCodeID] = useState<string | null>(null);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [couponSpinner, setCouponSpinner] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const [cardElement, setCardElement] = useState<StripeCardElement | null>(
    null,
  );
  const [updatedPlanAmount, setUpdatedPlanAmount] = useState<string | null>(
    null,
  );
  const [discount, setDiscount] = useState<number | null>(null);
  const errorLogger = props.func.httpsCallable("error");
  useEffect(() => {
    setUpdatedPlanAmount(props.planAmount);
    if (elements) {
      setCardElement(elements.getElement(CardElement));
    }
  }, [elements, props.planAmount]);
  useEffect(() => {
    if (cardElement) {
      cardElement.on("change", (event) => {
        if (event.complete) {
          setNextDisabled(false);
        } else if (event.error) {
          // TODO: show validation to customer
        }
      });
    }
  }, [cardElement, errorLogger, props.planAmount]);
  const onSnackClose = () => {
    setSnackOpen(false);
  };
  const handleSnackClose = (
    event: React.SyntheticEvent | Event,
    reason: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    onSnackClose();
  };
  const handlePromoCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPromoCode(event.target.value);
  };
  const handleApplyPromo = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setCouponSpinner(true);
    // submit to customer db
    const applyCoupon = props.func.httpsCallable("applyCouponRequest");
    const applyCouponRes = await applyCoupon({
      code: promoCode,
    }).catch((error) => {
      setPaymentError("An unexpected error occurred");
      setSpinner(false);
      setNextDisabled(false);
    });
    if (!applyCouponRes) {
      return;
    }
    const { discount, promoCodeID, duration } = applyCouponRes.data;
    if (!updatedPlanAmount) {
      return;
    }
    if (discount === 100 && duration === "forever") {
      setNextDisabled(false);
    }
    setUpdatedPlanAmount(
      (Number(props.planAmount) * (100 - discount)).toFixed(2),
    );
    setDiscount(discount);
    setPromoCode(promoCode);
    setPromoCodeID(promoCodeID);
    setCouponSpinner(false);
  };
  const handlePaymentChange = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      // TODO: [BOOK-145] disable form submission until stripe has loaded
      return;
    }
    setNextDisabled(true);
    setSpinner(true);

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    if (cardElement === null) {
      navigate("/payment-failure");
      return;
    }
    // submit to customer db
    let customerID;
    const createNewCustomer = props.func.httpsCallable("createCustomerRequest");
    const customerRes = await createNewCustomer({
      email: props.email,
    }).catch((error) => {
      setPaymentError("An unexpected error occurred");
      setSpinner(false);
      setNextDisabled(false);
    });
    if (!customerRes) {
      return;
    }
    customerID = customerRes.data;
    const createSubscription = props.func.httpsCallable(
      "createSubscriptionRequest",
    );
    const subscriptionRes = await createSubscription({
      uid: props.uid,
      customerID: customerID,
      plan: props.paymentPlan,
      code: promoCodeID,
    }).catch(() => {
      setPaymentError("An unexpected error occurred");
      setSpinner(false);
      setNextDisabled(false);
    });
    if (!subscriptionRes) {
      return;
    }
    const { invoiceSecret } = subscriptionRes.data;
    // Use your card Element with other Stripe.js APIs
    if (discount !== 100) {
      const { error } = await stripe.confirmCardPayment(invoiceSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: props.firstname + " " + props.lastname,
          },
        },
      });
      if (error) {
        setPaymentError(
          error.message ? error.message : "unknown error occurred",
        );
        setSpinner(false);
        setNextDisabled(false);
        return;
      }
    }

    let isError;
    const setCustomUserClaim = props.func.httpsCallable("setCustomUserClaim");
    await setCustomUserClaim({ uid: props.uid, siteId: props.siteId }).catch(
      (error) => {
        setSpinner(false);
        setNextDisabled(false);
        isError = true;
      },
    );
    if (isError) {
      return;
    }
    const updatePassword = props.func.httpsCallable("updatePassword");
    await updatePassword({ uid: props.uid, password: props.password }).catch(
      (error) => {
        setSpinner(false);
        setNextDisabled(false);
        isError = true;
      },
    );
    if (isError) {
      return;
    }
    await signInWithEmailAndPassword(getAuth(), props.email, props.password);
    const currentUser = getAuth().currentUser;
    if (!currentUser) {
      getAuth().signOut();
      return;
    }
    await currentUser.getIdToken(true);
    const token = await currentUser.getIdTokenResult();
    const siteId = token.claims.siteId;
    const collectionRef = props.db.collection("user");
    const docRef = collectionRef.doc(props.uid);
    await docRef
      .update({
        siteId,
        firstname: props.firstname,
        lastname: props.lastname,
        emailUpdates: props.emailUpdates,
      })
      .catch((error) => {
        setSpinner(false);
        setNextDisabled(false);
        isError = true;
      });
    if (isError) {
      return;
    }
    await props.db
      .collection("sites")
      .doc(props.siteId)
      .set({ uid: props.uid, name: props.siteName })
      .catch((error) => {
        setSpinner(false);
        setNextDisabled(false);
        isError = true;
      });
    if (isError) {
      return;
    }
    navigate("/");
  };
  return (
    <div>
      <Snackbar
        className="warning-snackbar"
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar">
              <h6>Subscription successfully activated</h6>
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={onSnackClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
      <h4 className="center">Get Started with your free month</h4>
      <h6 className="center">Upgrade, Downgrade or Cancel at any time</h6>
      <Card>
        <CardContent>
          <h5>Your Plan</h5>
          <h6>
            {props.paymentPlan.charAt(0).toUpperCase() +
              props.paymentPlan.slice(1)}
          </h6>
          <p>
            <span aria-label="planPrice">{updatedPlanAmount}</span> GBP / user /
            per month (due in 30 days)
          </p>
          <Button
            variant="contained"
            onClick={togglePricePlan(
              props.setPaymentVisible,
              props.setPricePlanVisible,
              props.pricePlanVisible,
              props.paymentVisible,
            )}
          >
            Change Plan
          </Button>
          <h5>Promotion Code</h5>
          <TextField
            id="promo-code"
            placeholder="enter code"
            value={promoCode}
            onChange={handlePromoCodeChange}
          />
          <LoadingButton
            onClick={handleApplyPromo}
            loading={couponSpinner}
            disabled={couponSpinner}
          >
            Apply
          </LoadingButton>
          <h5>Payment Details</h5>
          <form action="" onSubmit={handlePaymentChange}>
            {stripe === null && (
              <div>
                payment error please contact{" "}
                <a href="mailto:support@bookingflow.app">support@bookingflow</a>
              </div>
            )}
            {stripe !== null && <CardElement id="payment" />}
            <div className="button-div">
              <Button
                variant="contained"
                onClick={togglePricePlan(
                  props.setPaymentVisible,
                  props.setPricePlanVisible,
                  props.pricePlanVisible,
                  props.paymentVisible,
                )}
              >
                Back
              </Button>
              <LoadingButton
                id="signup-pay"
                variant="contained"
                disabled={nextDisabled || !stripe}
                color="primary"
                type="submit"
                loading={spinner}
              >
                Start free trial
              </LoadingButton>
              <p>{paymentError}</p>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default Payment;
