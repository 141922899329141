import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const SentryEnvHostnameMap: { [key: string]: string } = {
  'bookingflow.app': 'production',
  'test-app-bookingflow.web.app': 'staging',
}

const REACT_APP_DISABLE_SENTRY = process.env.REACT_APP_DISABLE_SENTRY === 'true'

// Disable Sentry locally or inside the Docker to avoid noise/respect privacy
const onlineEnv =
  !REACT_APP_DISABLE_SENTRY &&
  Object.keys(SentryEnvHostnameMap).find((item) => window.location.hostname.indexOf(item) >= 0)

Sentry.init({
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  dsn: onlineEnv ? 'https://8fffcc205633430190c6da7bca37dbec@o889568.ingest.sentry.io/5838801' : '',
  environment: onlineEnv ? SentryEnvHostnameMap[onlineEnv] : undefined,
  release: process.env.REACT_APP_GIT_SHA,
  integrations: [new Integrations.BrowserTracing()],
  beforeSend(event) {
    if (event.request?.url) {
      event.request.url = event.request.url.replace(/#.*$/, '')
    }
    return event
  },
})
