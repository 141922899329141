import React from "react";
import Button from "@mui/material/Button";

export interface ISettingsImportSkipProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setWelcomeVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setImportVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setAccommodationVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SettingsImportSkip = (props: ISettingsImportSkipProps) => {
  const handleSkip = () => {
    props.setImportVisible(false);
    props.setAccommodationVisible(true);
    props.setActiveStep(0);
  };
  return <Button onClick={handleSkip}>Skip</Button>;
};

export default SettingsImportSkip;
