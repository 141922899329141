import React from "react";
import firebase from "firebase/compat/app";
import Accommodations from "./Accommodations";
import Button from "@mui/material/Button";
import AccommodationImage from "../static/images/camping.svg";
import { Accommodationtypes } from "@bookingflow/types";
import { getAuth } from "firebase/auth";

export interface ISetupAccommodationProps {
  accommodations: Accommodationtypes;
  siteId: string;
  db: firebase.firestore.Firestore;
  setAccommodationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setImportVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SetupAccommodation = (props: ISetupAccommodationProps) => {
  const handleSubmit = async () => {
    props.setAccommodationVisible(false);
    props.setDialogOpen(false);
    const currentUser = getAuth().currentUser;
    if (!currentUser) {
      getAuth().signOut();
      return;
    }
    const doc = await props.db.collection("user").doc(currentUser.uid);
    doc.update({ firstLogin: false });
  };
  const handleBack = () => {
    props.setImportVisible(true);
    props.setAccommodationVisible(false);
  };
  return (
    <div>
      <img src={AccommodationImage} alt="Accommodation" />
      <Accommodations
        accommodations={props.accommodations}
        siteId={props.siteId}
        db={props.db}
      />
      <div>
        <Button onClick={handleBack}>Back</Button>
        <Button onClick={handleSubmit}>Finish</Button>
      </div>
    </div>
  );
};

export default SetupAccommodation;
