import React, { useState } from "react";
import ImportImage from "../static/images/data_processing.svg";
import SettingsImportSkip from "./SettingsImportSkip";
import Import from "./Import";

export interface ISetupImportProps {
  siteId: string;
  initialSetup: boolean;
  setWelcomeVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setImportVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setAccommodationVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const SetupImport = (props: ISetupImportProps) => {
  const [activeStep, setActiveStep] = useState(0);
  return (
    <div className="main-container">
      <img src={ImportImage} alt="Import" />
      <h5>Data Import</h5>
      <Import
        siteId={props.siteId}
        selectedFileType={"Booking"}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        skip={
          <SettingsImportSkip
            setActiveStep={setActiveStep}
            setWelcomeVisible={props.setWelcomeVisible}
            setAccommodationVisible={props.setAccommodationVisible}
            setImportVisible={props.setImportVisible}
          />
        }
      />
    </div>
  );
};

export default SetupImport;
