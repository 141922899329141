import BookingflowAuth from "./BookingflowAuth";
import { getAuth } from "firebase/auth";

const SignIn = () => {
  const auth = getAuth();
  return (
    <div className="full-main">
      <div id="signinContainer">
        <h5 className="center-align signinBrandText">BookingFlow</h5>
        <BookingflowAuth auth={auth} />
        <div
          className="center-align"
          style={{
            paddingTop: "50px",
          }}
        >
          Don't have an account?{" "}
          <a href="https://bookingflow.app/get-started">Sign up</a>
        </div>
      </div>
    </div>
  );
};
export default SignIn;
