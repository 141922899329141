import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import { Helmet } from "react-helmet";
import NativeSelect from "@mui/material/NativeSelect";
import Button from "@mui/material/Button";

export interface IStaticsProps {
  db: firebase.firestore.Firestore;
  func: firebase.functions.Functions;
}

type StaticPrices = {
  description: string;
  make: string;
  model: string;
  price: string;
  seller: string;
  url: string;
  year: number;
};

const Statics = (props: IStaticsProps) => {
  const [makes, setMakes] = useState<string[]>([]);
  const [models, setModels] = useState<firebase.firestore.DocumentData[]>([]);
  const [make, setMake] = useState<string>("");
  const [model, setModel] = useState<string>("");
  const [modelOptions, setModelOptions] = useState<JSX.Element[] | null>(null);
  const [yearOptions, setYearOptions] = useState<JSX.Element[] | null>(null);
  const [prices, setPrices] = useState<StaticPrices[] | undefined>(undefined);
  const [year, setYear] = useState<string>("");
  const [statics, setStatics] = useState<string>("");
  useEffect(() => {
    const fetchMakesModels = async () => {
      const dbMakes = [];
      const dbModels = [];
      const snapshot = await props.db.collection("statics").get();
      for (const doc of snapshot.docs) {
        const data = doc.data();
        dbMakes.push(data.make);
        dbModels.push(data);
      }
      const deDupedDbMakes = Array.from(new Set(dbMakes));
      setMakes(deDupedDbMakes);
      setModels(dbModels);
    };
    fetchMakesModels();
  }, [props.db]);
  const createMakeSelectItems = () => {
    const items = [
      <option key="placeholder" value="" disabled>
        Select a make
      </option>,
    ];
    makes.forEach((d) => {
      items.push(
        <option key={d} value={d}>
          {d}
        </option>
      );
    });
    return items;
  };
  const handleMakeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.currentTarget;
    const { value } = target;
    setMake(value);
  };

  useEffect(() => {
    const items = [
      <option key="placeholder" value="" disabled>
        Select a model
      </option>,
    ];
    const deDupedModels = Array.from(
      new Set(models.map((d) => (d.make === make ? d.model : null)))
    );
    deDupedModels.forEach((d, i) => {
      if (d) {
        items.push(
          <option key={i} value={d}>
            {d}
          </option>
        );
      }
    });
    setModelOptions(items);
    //TODO: [BOOK-119] Create Static Management functionality
    setStatics("");
  }, [make, models]);
  const handleModelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.currentTarget;
    const { value } = target;
    setModel(value);
  };
  useEffect(() => {
    const items = [
      <option key="placeholder" value="" disabled>
        Select a year
      </option>,
    ];
    const deDupedYears = Array.from(
      new Set(models.map((d) => (d.model === model ? d.year : null)))
    );
    deDupedYears.forEach((d, i) => {
      if (d) {
        items.push(
          <option key={i} value={d}>
            {d}
          </option>
        );
      }
    });
    setYearOptions(items);
  }, [model, models]);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.currentTarget;
    const { value } = target;
    setYear(value);
  };
  const handleSubmit = async () => {
    const snapshot = await props.db
      .collection("statics")
      .doc(make + model + year)
      .collection("prices")
      .get();
    const prices = [];
    for (const doc of snapshot.docs) {
      prices.push(doc.data());
    }
    setPrices(prices as unknown as StaticPrices[]);
  };
  const getPriceObjects = () => {
    const items: JSX.Element[] = [];
    if (!prices) {
      return null;
    }
    prices.forEach((d, i) => {
      items.push(
        <div key={i} className="row">
          {d.make} + {d.model} + {d.year} + {d.price} + {d.seller} + {d.url}
        </div>
      );
    });
    return items;
  };
  return (
    <div>
      <Helmet>
        <title>Statics</title>
      </Helmet>
      <div id="staticsGrid" className="main-container">
        <Button>Add a static</Button>
        {statics}
        <h3>What is the market value of a static</h3>
        <label htmlFor="make">
          Make
          <NativeSelect
            id="make"
            name="make"
            onChange={handleMakeChange}
            aria-label="make-input"
            required
            value={make}
          >
            {createMakeSelectItems()}
          </NativeSelect>
        </label>
        <label htmlFor="model">
          Model
          <NativeSelect
            id="model"
            name="model"
            onChange={handleModelChange}
            aria-label="model-input"
            required
            value={model}
          >
            {modelOptions}
          </NativeSelect>
        </label>
        <label htmlFor="year">
          Year
          <NativeSelect
            id="year"
            name="year"
            onChange={handleYearChange}
            aria-label="year-input"
            required
            value={year}
          >
            {yearOptions}
          </NativeSelect>
        </label>
        <Button onClick={handleSubmit}>Search</Button>
        {getPriceObjects()}
      </div>
    </div>
  );
};

export default Statics;
