import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import React from "react";
import { IBooking } from "@bookingflow/types";
import firebase from "firebase/compat/app";
import { convertTimestamp, getDateArray } from "@bookingflow/utils";

export interface IProps {
  deleteIds: string[];
  db: firebase.firestore.Firestore;
  handleClose: () => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  deleteOpen: boolean;
  siteId: string;
  func: firebase.functions.Functions;
}

const DeleteBooking = (props: IProps) => {
  const errorLogger = props.func.httpsCallable("error");
  const handleSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    props.setLoading(true);
    const batch = props.db.batch();
    for (const deleteId of props.deleteIds) {
      const doc = props.db
        .collection("sites")
        .doc(props.siteId)
        .collection("transactional")
        .doc(deleteId);
      const snapshot = await doc.get();
      const snapshotData = snapshot.data();
      if (!snapshotData) {
        errorLogger({
          error:
            "could not find booking with that id: " +
            props.siteId +
            ":" +
            deleteId,
        });
        return;
      }
      const data = convertTimestamp(snapshotData) as unknown as IBooking;
      const accommodationid: string = data.accommodationid;
      const lastDay = new Date(data.depart.getTime() - 86400000);
      const daterange: Date[] = getDateArray(data.arrive, lastDay);
      const allocationids: string[] = daterange.map((date) =>
        accommodationid.concat(date.toISOString())
      );
      const increment = firebase.firestore.FieldValue.increment(1);
      batch.update(doc, {
        cancelled: true,
        cancelledat: new Date(),
        cancellationreason: "other",
      });
      // free up allocation slot
      const collectionRef = props.db
        .collection("sites")
        .doc(props.siteId)
        .collection("pricingAllocation");
      for (const allocationid of allocationids) {
        const docRef = collectionRef.doc(allocationid);
        // assumes that deleting a booking always makes a slot available
        // not necessarily true if deleting a booking is to correct a double booking
        batch.set(
          docRef,
          { vacancies: increment, available: true },
          { merge: true }
        );
      }
    }
    await batch.commit();
    props.handleClose();
  };

  return (
    <div>
      <Dialog
        aria-label="delete-confirmation"
        aria-describedby="confirm-booking-should-be-deleted"
        open={props.deleteOpen}
        onClose={props.handleClose}
        id="deleteBooking"
      >
        <Card>
          <CardContent>
            <h6>Are you sure you want to delete this booking</h6>
            <Button variant="text" onClick={props.handleClose}>
              Close
            </Button>
            <LoadingButton
              variant="contained"
              onClick={handleSubmit}
              type="submit"
              value="Submit"
              loading={props.loading}
            >
              Confirm
            </LoadingButton>
          </CardContent>
        </Card>
      </Dialog>
    </div>
  );
};

export default DeleteBooking;
