import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { DateTime } from "luxon";
import React, { FC, useState, useCallback } from "react";
import { Bookings, IBooking, IFormattedSerie } from "@bookingflow/types";
import Chart from "./Chart";
import "./Performance.css";
import Spinner from "./Spinner";
import { convertToUTCDate, getDateArray } from "@bookingflow/utils";

export interface IPerformanceProps {
  TY: Bookings;
  LY: Bookings;
  dataLoaded: boolean;
}

export interface ISerie {
  x: Date;
  y: number;
  color?: string;
}
const Performance: FC<IPerformanceProps> = (props: IPerformanceProps) => {
  const [period, setPeriod] = useState("month");
  const [metric, setMetric] = useState("bookings");

  const getLabels = (): Date[] => {
    const end = DateTime.utc().endOf("month").endOf("day");
    const start = end.startOf("month").startOf("day");
    const dateArray: Date[] = getDateArray(start.toJSDate(), end.toJSDate());
    return dateArray;
  };
  const getDay = (day: Date): ((booking: IBooking) => boolean) =>
    function (booking: IBooking): boolean {
      const created: number = convertToUTCDate(booking.created).getTime();
      const date: number = day.setUTCHours(0, 0, 0, 0);
      return created === date;
    };

  const sum = (a: number, b: number): number => Number(a) + Number(b);

  const getSumRevenue = useCallback((bookings: Bookings): number => {
    const amounts: number[] = bookings.map(
      (booking: IBooking) => booking.accommodationcostamount
    );
    const revenue: number = amounts.reduce(sum, 0);
    const roundedRevenue: number = Math.round(revenue * 100) / 100;
    return roundedRevenue;
  }, []);

  const getMonthSeries = useCallback(
    (dates: Date[], TY: Bookings): ISerie[] => {
      const series: ISerie[] = [];
      for (const day of dates) {
        const dayBookings: Bookings = TY.filter(getDay(day));
        if (metric === "bookings") {
          const numBookings: number = dayBookings.length;
          series.push({ x: day, y: numBookings });
        } else if (metric === "revenue") {
          const sumRevenue: number = getSumRevenue(dayBookings);
          series.push({ x: day, y: sumRevenue });
        }
      }
      return series;
    },
    [getSumRevenue, metric]
  );

  const getMonthYOYSeries = useCallback(
    (dates: Date[], TY: Bookings, LY: Bookings): ISerie[] => {
      const series: ISerie[] = [];
      for (const day of dates) {
        const TYDayBookings: Bookings = TY.filter(getDay(day));
        const LYDayBookings: Bookings = LY.filter(
          getDay(DateTime.fromJSDate(day).plus({ year: -1 }).toJSDate())
        );
        if (metric === "bookings") {
          if (LYDayBookings.length !== 0) {
            const diff: number = TYDayBookings.length - LYDayBookings.length;
            series.push({ x: day, y: diff });
          } else {
            series.push({ x: day, y: 0 });
          }
        } else if (metric === "revenue") {
          if (LYDayBookings.length !== 0) {
            const totalTY: number = getSumRevenue(TYDayBookings);
            const totalLY: number = getSumRevenue(LYDayBookings);
            const diff: number = totalTY - totalLY;
            series.push({ x: day, y: diff, color: "#488f31" });
          } else {
            series.push({ x: day, y: 0 });
          }
        }
      }
      return series;
    },
    [getSumRevenue, metric]
  );

  const getSeries = useCallback(
    (TY: Bookings, LY: Bookings): IFormattedSerie[] => {
      let series: ISerie[];
      const dates: Date[] = getLabels();
      if (period === "monthYOY") {
        series = getMonthYOYSeries(dates, TY, LY);
      } else if (period === "month") {
        series = getMonthSeries(dates, TY);
      } else {
        console.error("incorrect period selected");
        return [];
      }
      const formattedSeries = series.map((serie: ISerie) => ({
        y: serie.y,
        x: serie.x.toLocaleDateString(),
        color: serie.color,
      }));
      return formattedSeries;
    },
    [getMonthSeries, getMonthYOYSeries, period]
  );

  const getMonthYOYBookingsHeadline = (TY: Bookings, LY: Bookings): string => {
    if (LY.length === 0) {
      return "Insufficient data";
    }
    const totalTY: number = TY.length;
    const totalLY: number = LY.length;
    const lift: number = totalTY / totalLY - 1;
    const absLift: number = totalTY - totalLY;
    const percLift: number = lift * 100;
    const prefix: string = lift >= 0 ? "+" : "";
    const brackets = ` (${prefix}${absLift.toString()} Bookings)`;
    const suffix: string = lift >= 0 ? "% increase" : "% decrease";
    const headline: string = percLift.toFixed(0).toString() + suffix + brackets;
    return headline;
  };
  const getMonthYOYRevenueHeadline = (TY: Bookings, LY: Bookings): string => {
    if (LY.length === 0) {
      return "Insufficient data";
    }
    const totalTY = getSumRevenue(TY);
    const totalLY = getSumRevenue(LY);
    const lift: number = totalTY / totalLY - 1;
    const absLift: number = totalTY - totalLY;
    const percLift: number = lift * 100;
    const prefix: string = lift >= 0 ? "+" : "";
    const brackets = ` (${prefix}£${absLift.toFixed(2).toString()})`;
    const suffix: string = lift >= 0 ? "% increase" : "% decrease";
    const headline: string = percLift.toFixed(0).toString() + suffix + brackets;
    return headline;
  };
  const getMonthRevenueHeadline = (bookings: Bookings): string => {
    const total = getSumRevenue(bookings);
    const headline = `£${total.toString()}`;
    return headline;
  };
  const getMonthBookingsHeadline = (bookings: Bookings): string => {
    let headline: string;
    const total = bookings.length;
    if (total === 1) {
      headline = `${total.toString()} Booking`;
      return headline;
    }
    headline = `${total.toString()} Bookings`;
    return headline;
  };

  const getHeadline = (
    period: string,
    metric: string,
    TY: Bookings,
    LY: Bookings
  ): string => {
    if (period === "monthYOY" && metric === "bookings") {
      return getMonthYOYBookingsHeadline(TY, LY);
    }
    if (period === "monthYOY" && metric === "revenue") {
      return getMonthYOYRevenueHeadline(TY, LY);
    }
    if (period === "month" && metric === "bookings") {
      return getMonthBookingsHeadline(TY);
    }
    if (period === "month" && metric === "revenue") {
      return getMonthRevenueHeadline(TY);
    }
    console.error("incorrect metric or period selected");
    return "";
  };

  const data: IFormattedSerie[] = React.useMemo(
    () => getSeries(props.TY, props.LY),
    [props.TY, props.LY, getSeries]
  );
  const handlePeriodChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    newValue === 0 ? setPeriod("month") : setPeriod("monthYOY");
  };
  const handleMetricChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    newValue === 0 ? setMetric("bookings") : setMetric("revenue");
  };

  return (
    <Grid id="performance" item xs={12}>
      <Card>
        <CardContent className="card-content">
          <Typography variant="h4" component="h3">
            How are you doing
          </Typography>
          <Tabs
            value={period === "month" ? 0 : 1}
            onChange={handlePeriodChange}
            aria-label="Month vs YOY selector"
          >
            <Tab label="This Month" />
            <Tab label="YoY" />
          </Tabs>
          <Tabs
            value={metric === "bookings" ? 0 : 1}
            onChange={handleMetricChange}
            aria-label="Chart Metric selector"
          >
            <Tab label="Bookings" />
            <Tab label="Revenue" />
          </Tabs>
          <div id="chart-container">
            <div className="row">
              <Typography variant="h5" component="h4">
                {props.dataLoaded &&
                  getHeadline(period, metric, props.TY, props.LY)}
                {!props.dataLoaded && <Spinner id="performanceSpinner" />}
              </Typography>
            </div>

            <div id="chart" className="row">
              <Chart data={data} ylabel={metric} />
            </div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default Performance;
