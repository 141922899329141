import React, { useState } from "react";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import Alert from "@mui/material/Alert";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Upload from "./Upload";
import { dsvFormat } from "d3-dsv";
import Ajv, { ErrorObject } from "ajv";
import addFormats from "ajv-formats";
import schema from "../static/templates/booking.schema.json";
import { ImportBookings } from "@bookingflow/types";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { Link } from "react-router-dom";

export interface IImportProps {
  siteId: string;
  selectedFileType: "Booking" | "Allocation" | "Accommodation";
  skip: React.ReactElement | null;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const Import = (props: IImportProps) => {
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [fileSelected, setFileSelected] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ErrorObject[]>([]);
  const handleNext = () => {
    if (props.activeStep === 2) {
      props.setActiveStep(props.activeStep + 1);
    }
    props.setActiveStep(props.activeStep + 1);
  };
  const handleBack = () => {
    props.setActiveStep(props.activeStep - 1);
  };

  const handleFiles = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setValidationErrors([]);
    const name = props.selectedFileType.toLowerCase();
    const elem = document.getElementById(name) as HTMLInputElement;
    if (elem === null) {
      console.error("IMPORT: no file input exists");
    } else if (elem.files === null) {
      console.error("IMPORT: HTMLInputElement contains no files");
    } else if (elem.files.length > 0) {
      const fileObj: File = elem.files[0];
      const reader = new FileReader();
      reader.onload = async (ev: ProgressEvent<FileReader>) => {
        // Show content  (ev.target === reader)
        if (ev.target !== null) {
          const result = ev.target.result;
          if (typeof result === "string") {
            const csv = dsvFormat(",");
            const data = csv.parse(result);
            const ajv = new Ajv({ allErrors: true, coerceTypes: true }); // options can be passed, e.g. {allErrors: true}
            addFormats(ajv);
            const validate = ajv.compile(schema);
            const valid = validate(data as unknown as ImportBookings);
            if (valid) {
              const storageRef = firebase.storage().ref();
              // const ts = Math.round((new Date()).getTime() / 1000).toString();
              const stagingRef = storageRef.child(
                `${props.siteId}/${name}.csv`
              );
              await stagingRef.put(fileObj);
              setSuccess(true);
              return;
            }
            if (!validate.errors) {
              return;
            }
            if (!valid) {
              setValidationErrors(validate.errors);
            }
          }
        }
      };
      // Read as plain text
      reader.readAsText(fileObj);

      /*
      potentially use webworkers here - seems to be blocking main thread
      https://stackoverflow.com/questions/49300814/how-to-avoid-javascript-vm-to-be-blocked-by-cpu-bound-task
      while (uploadTask.state !== 'complete') {
        setTimeout(() => {
          const percentageUpload = Math.round(uploadTask.bytesTransferred / uploadTask.totalBytes)
          setState({ progress: percentageUpload })
        }, 10)
      }
      console.log('Uploaded a file!')
      // TODO: trigger data load via http and wait for status
      const loadFile = props.func.httpsCallable('addToFirestore')
      await loadFile({
        siteId: props.siteId,
        stagingRef,
      })
      */
    } else {
      console.error("Upload: Files array is empty");
    }
  };
  const handleFileSelect = () => {
    setFileSelected(true);
  };
  const getValidationErrorMessage = () => {
    const messages = validationErrors.map((err) => {
      const dataPath = err.instancePath;
      const problem =
        dataPath === "" ? "file" : dataPath.slice(1, dataPath.length);
      if (!err.message) {
        return "";
      }
      const message = err.message
        .replace("property", "column")
        .replace("date", "yyyy-mm-dd");
      return <div>{problem + " " + message}</div>;
    });

    return messages;
  };
  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return getTemplate();
      case 1:
        return "Open the template, add your historic data and save it";
      case 2:
        return (
          <div id="import">
            <div id="importInput">
              <Input
                type="file"
                id={props.selectedFileType.toLowerCase()}
                onChange={handleFileSelect}
              />
              <Upload
                handleFiles={handleFiles}
                siteId={props.siteId}
                filetype={props.selectedFileType}
              />
            </div>
            <div id="importMessage">
              {success && (
                <Alert severity="success">File uploaded successfully</Alert>
              )}
              {success === false && fileSelected && (
                <Alert severity="error">File upload error</Alert>
              )}
            </div>
          </div>
        );
      case 3:
        return "Data is now importing and should be available in a few mins";
      default:
        return "Unexpected error, please refresh and try again";
    }
  };
  const getTemplate = () => {
    let template: string | null;
    switch (props.selectedFileType.toLowerCase()) {
      case "accommodation":
        template = "/templates/booking.csv";
        break;
      case "booking":
        template = "/templates/booking.csv";
        break;
      case "allocation":
        template = "/templates/allocation.csv";
        break;
      default:
        template = null;
    }
    if (!template) {
      return "";
    }
    return (
      <Link to={template} target="_blank" download>
        <Button variant="contained">Download Template</Button>
      </Link>
    );
  };

  return (
    <div className="main-container">
      <p>You can import your data by following the steps below </p>
      <Stepper activeStep={props.activeStep}>
        <Step key="template">
          <StepLabel>Download Template</StepLabel>
        </Step>
        <Step key="fill">
          <StepLabel>Fill in template</StepLabel>
        </Step>
        <Step key="import">
          <StepLabel>Import Completed Template</StepLabel>
        </Step>
      </Stepper>
      <div id="stepContent">{getStepContent(props.activeStep)}</div>
      <div>
        <Button disabled={props.activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        <Button onClick={handleNext}>Next</Button>
        {props.skip}
      </div>
      {getValidationErrorMessage()}
    </div>
  );
};

export default Import;
