import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./Headline.css";
import React from "react";

export interface IHeadlineProps {
  arrivals: number;
  departures: number;
  vacancies: string;
  dataLoaded: boolean;
}

const Headline = (props: IHeadlineProps) => {
  return (
    <div id="headline-container">
      <div>
        <Card>
          <CardContent>
            <Typography variant="h5">Arrivals</Typography>
            {props.dataLoaded && <span>{props.arrivals}</span>}
            {!props.dataLoaded && (
              <Skeleton variant="text" width={15} height={20} />
            )}
          </CardContent>
        </Card>
      </div>
      <div>
        <Card>
          <CardContent>
            <Typography variant="h5">Departures</Typography>
            {props.dataLoaded && <span>{props.departures}</span>}
            {!props.dataLoaded && (
              <Skeleton variant="text" width={15} height={20} />
            )}
          </CardContent>
        </Card>
      </div>
      <div>
        <Card>
          <CardContent>
            <Typography variant="h5">Vacancies</Typography>
            {props.dataLoaded && <span>{props.vacancies}</span>}
            {!props.dataLoaded && (
              <Skeleton variant="text" width={15} height={20} />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Headline;
