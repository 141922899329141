import { createRoot } from 'react-dom/client'
import React from 'react'
import App from './App'
import './index.css'
// persistence is handled by firebase
//import "./pwa";
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './sentry'

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(<App />)

serviceWorkerRegistration.register()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals