import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import { DateTime } from "luxon";
import { Bookings, IBooking } from "@bookingflow/types";
import "./Recent.css";

export interface IRecentProps {
  bookings: Bookings;
  dataLoaded: boolean;
}

const Recent = (props: IRecentProps) => {
  const createSkeletonRows = (): JSX.Element[] => {
    const items: any[] = [];
    const populateItems = () => {
      for (let index = 0; index < 4; index++) {
        items.push(
          <TableRow key={index}>
            <TableCell>
              <Skeleton variant="rectangular" width={400} height={20} />
            </TableCell>
            <TableCell>
              <Skeleton variant="rectangular" width={50} height={20} />
            </TableCell>
            <TableCell>
              <Skeleton variant="rectangular" width={100} height={20} />
            </TableCell>
            <TableCell>
              <Skeleton variant="rectangular" width={50} height={20} />
            </TableCell>
          </TableRow>
        );
      }
    };

    populateItems();

    return items;
  };
  const createRows = () => {
    const items = [];
    const bookings = props.bookings;
    const populateItems = (d: IBooking, i: number) => {
      items.push(
        <TableRow key={i}>
          <TableCell>{`${d.firstname} ${d.lastname}`}</TableCell>
          <TableCell>{d.nights}</TableCell>
          <TableCell>{"£" + d.accommodationcostamount.toString()}</TableCell>
          <TableCell>
            {DateTime.fromJSDate(d.arrive).setLocale("en-gb").toLocaleString()}
          </TableCell>
        </TableRow>
      );
    };
    if (bookings.length === 0) {
      items.push(
        <TableRow key="1">
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
        </TableRow>
      );
    } else {
      bookings.forEach(populateItems);
    }
    return items;
  };

  return (
    <Card>
      <CardContent>
        <h3>Recent Bookings</h3>
        <TableContainer component={Paper} elevation={0}>
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Nights</TableCell>
                <TableCell>Revenue</TableCell>
                <TableCell>Arrival</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.dataLoaded && createRows()}
              {!props.dataLoaded && createSkeletonRows()}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
export default Recent;
