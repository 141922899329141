import {TextField} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/index.js';
import {Dayjs} from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/index.js";
import { DatePicker } from "@mui/x-date-pickers/DatePicker/index.js";

interface IDatePickerProps {
  label: string;
  placeholder: string;
  value: Date;
  onDayChange: (date: Dayjs | null) => void;
}

export const DayPickerInput = (props: IDatePickerProps) => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          mask="____-__-__"
          disablePast={true}
          label={props.label}
          value={props.value}
          inputFormat={"YYYY-MM-DD"}
          onChange={props.onDayChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </div>
  );
};

/*
export const DatePickerInput = () => {
    const [selected, setSelected] = useState<Date>();
    const [inputValue, setInputValue] = useState<string>('');
    const [isPopperOpen, setIsPopperOpen] = useState(false);
  
    const popperRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
      null
    );
  
    const popper = usePopper(popperRef.current, popperElement, {
      placement: 'bottom-start'
    });
  
    const closePopper = () => {
      setIsPopperOpen(false);
      buttonRef?.current?.focus();
    };
  
    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      setInputValue(e.currentTarget.value);
      const date = DateTime.fromFormat(e.currentTarget.value, 'y-MM-dd');
      if (date.isValid) {
        setSelected(date.toJSDate());
      } else {
        setSelected(undefined);
      }
    };
  
    const handleButtonClick = () => {
      setIsPopperOpen(true);
    };
  
    const handleDaySelect = (date: Date | undefined) => {
      setSelected(date);
      if (date) {
        setInputValue(DateTime.fromJSDate(date).toFormat('y-MM-dd'));
        closePopper();
      } else {
        setInputValue('');
      }
    };
  
    return (
      <div>
        <div ref={popperRef}>
          <input
            type="text"
            placeholder={DateTime.local().toFormat('y-MM-dd')}
            value={inputValue}
            onChange={handleInputChange}
            className="input-reset pa2 ma2 bg-white black ba"
          />
          <button
            ref={buttonRef}
            type="button"
            className="pa2 bg-white button-reset ba"
            aria-label="Pick a date"
            onClick={handleButtonClick}
          >
            <span role="img" aria-label="calendar icon">
              📅
            </span>
          </button>
        </div>
        {isPopperOpen && (
          <FocusTrap
            active
            focusTrapOptions={{
              initialFocus: false,
              allowOutsideClick: true,
              clickOutsideDeactivates: true,
              onDeactivate: closePopper
            }}
          >
            <div
              tabIndex={-1}
              style={popper.styles.popper}
              className="dialog-sheet"
              {...popper.attributes.popper}
              ref={setPopperElement}
              role="dialog"
            >
              <DayPicker
                initialFocus={isPopperOpen}
                mode="single"
                defaultMonth={selected}
                selected={selected}
                onSelect={handleDaySelect}
              />
            </div>
          </FocusTrap>
        )}
      </div>
    );
  }
  */
