import firebase from 'firebase/compat/app'
import 'firebase/compat/functions'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/app-check'
import { getAuth } from 'firebase/auth'

declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined
}
export const initFirebase = () => {
  if (!process.env.REACT_APP_FIREBASE_CONFIG) {
    throw Error('we are having technical issues please try again later')
  }
  const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)
  // Instantiate a Firebase app.
  firebase.initializeApp(firebaseConfig)
  if (process.env.REACT_APP_BUILD_MODE === 'development') {
    // eslint-disable-next-line no-restricted-globals
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_CHECK_DEBUG_TOKEN
  }
  const appCheck = firebase.appCheck()
  const recaptcha = process.env.REACT_APP_GRECAPTCHA
  if (!recaptcha) {
    throw new Error('recaptcha environment variable not set')
  }
  appCheck.activate(
    recaptcha,

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    true
  )

  type FirestoreSettings = {
    ignoreUndefinedProperties: boolean
    experimentalForceLongPolling?: boolean
    host?: string
    ssl?: boolean
  }
  const firestoreSettings: FirestoreSettings = {
    ignoreUndefinedProperties: true,
  }

  // Initialize Cloud Firestore through Firebase
  const db = firebase.firestore()
  db.settings(firestoreSettings)
  const func = firebase.app().functions('europe-west2')
  const storage = firebase.storage()
  const auth = getAuth()
  // @ts-ignore
  if (window.Cypress) {
    // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
    firebase
      .firestore()
      .clearPersistence()
      .catch((error) => {
        console.error('Could not enable persistence:', error.code)
      })
  }
  if (window.location.hostname === 'localhost') {
    // @ts-ignore
    if (window.Cypress) {
      // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
      firestoreSettings.experimentalForceLongPolling = false
      firestoreSettings.host = 'localhost:8080'
      firestoreSettings.ssl = false
      db.settings(firestoreSettings)
    }
    db.useEmulator('localhost', 8089)
    func.useEmulator('localhost', 5001)
    storage.useEmulator('localhost', 9199)
    //  auth.useEmulator('http://localhost:9099')
  }
  const errorLogger = func.httpsCallable('error')

  // TODO: [BOOK-144] Enable firebase persistence when we can notify the user
  firebase
    .firestore()
    .enablePersistence({ synchronizeTabs: true })
    .catch((err) => {
      errorLogger({ error: err })
    })
  return { db, func, auth }
}
