import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import firebase from "firebase/compat/app";
import React from "react";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { Accommodationtypes } from "@bookingflow/types";
import "./Account.css";

export interface IFirestorePlan {
  name: string;
  amount: number;
}

export interface IAccountProps {
  accommodations: Accommodationtypes;
  db: firebase.firestore.Firestore;
  planType: string;
  planAmount: string;
  siteId: string;
  dataLoaded: boolean;
  func: firebase.functions.Functions;
}

const Account = (props: IAccountProps) => {
  return (
    <div className="main-container">
      <Typography variant="h4">Account</Typography>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <h6>General</h6>
          <Link to="/account/settings">
            <Button>Contact Details</Button>
          </Link>
          <br />
          <Link to="/account/accommodations">
            <Button>App Setup</Button>
          </Link>
          <br />
          <Link to="/account/plan">
            <Button>Plan Details</Button>
          </Link>
          <br />
          <Divider />
          <h6>Integrations</h6>
          <Link to="/account/import">
            <Button>Data Import</Button>
          </Link>
          <br />
          {props.planType !== "minimal" && (
            <Link to="/account/website">
              <Button disabled={false}>Website</Button>
            </Link>
          )}
          {props.planType === "minimal" && <Button disabled>Website</Button>}
          {
            <br />
            //props.planType !== 'minimal' && (<Link to="/account/cal-sync"><Button disabled={false}>Calendar Sync</Button></Link>)
          }
          {
            <br />
            //props.planType === 'minimal' && <Button disabled>Calendar Sync</Button>
          }
          {
            //props.planType !== 'minimal' && (<Link to="/account/pitchup"><Button disabled={false}>Pitchup</Button></Link>)
          }
          {
            //props.planType === 'minimal' && <Button disabled>Pitchup</Button>
          }
        </Grid>
        <Grid item xs={10}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
};

export default Account;
