import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Modal from "@mui/material/Modal";
import NativeSelect from "@mui/material/NativeSelect";
import Tooltip from "@mui/material/Tooltip";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import React, { useEffect, useState } from "react";
import { DayPickerInput } from "@bookingflow/components/dist/DayPickerInput/index.js";
import dayjs, { Dayjs } from "dayjs";
import { DateTime } from "luxon";
import {
  Accommodationtypes,
  Allocations,
  IAvailabilityFlexResult,
  IAvailabilityMessage,
  IBooking,
} from "@bookingflow/types";
import "./AddBooking.css";
import {
  convertTimestamp,
  convertToUTCDate,
  getAvailabilityAndPrices,
} from "@bookingflow/utils";

export interface IAddBookingProps {
  accommodations: Accommodationtypes;
  db: firebase.firestore.Firestore;
  editID: string;
  addOpen: boolean;
  handleClose: () => void;
  siteId: string;
  dataLoaded: boolean;
  func: firebase.functions.Functions;
}

const AddBooking = (props: IAddBookingProps) => {
  const [accommodationcostamount, setAccommodationcostamount] = useState(0);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const luxonDay = DateTime.local().startOf("day");
  const luxonDayPlusOne = luxonDay.plus({ day: 1 });
  const today = luxonDay.toJSDate();
  const tomorrow = luxonDayPlusOne.toJSDate();
  const [arrive, setArrive] = useState<Date>(today);
  const [depart, setDepart] = useState<Date>(tomorrow);
  const [email, setEmail] = useState("");
  const [accommodationid, setAccommodation] = useState("");
  const [telephone, setTelephone] = useState("");
  const [adults, setAdults] = useState<number | string | undefined>(0);
  const [children, setChildren] = useState<number | string | undefined>(0);
  const [infants, setInfants] = useState<number | string | undefined>(0);
  const [available, setAvailable] = useState("");
  const [calculatedPrice, setCalculatedPrice] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState("");
  const [onlineTooltipOpen, setOnlineTooltipOpen] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [allocations, setAllocations] = useState<Allocations>([]);
  const errorLogger = props.func.httpsCallable("error");
  const createSelectItems = () => {
    const items = [
      <option key="placeholder" value="" disabled>
        Select an accommodation type
      </option>,
    ];
    props.accommodations.forEach((d) => {
      items.push(
        <option key={d.id} value={d.id}>
          {d.accommodation}
        </option>,
      );
    });
    return items;
  };
  const decrementAllocation = async (batch: firebase.firestore.WriteBatch) => {
    // reduce vacancies
    const siteRef = props.db.collection("sites");
    const sites = siteRef.doc(props.siteId);
    const collectionRef = sites.collection("pricingAllocation");
    for (const allocation of allocations) {
      const doc = collectionRef.doc(
        allocation.accommodationid + allocation.date.toISOString(),
      );
      if (allocation.vacancies - 1 === 0) {
        batch.update(doc, {
          vacancies: allocation.vacancies - 1,
          available: false,
        });
      } else {
        batch.update(doc, { vacancies: allocation.vacancies - 1 });
      }
    }
  };

  const handleSubmitKeyDown = async (
    e: React.KeyboardEvent<HTMLFormElement>,
  ) => {
    /**
     * Note: Pressing enter in some input in a browser forms
     *  triggers onClick on the first child button
     *
     * So, prevent `enter` from triggering `onClick` on any buttons
     *  and instead trigger onSubmit
     */
    if (e.key === "Enter" && !disabled) {
      handleSubmit(e);
    }
  };
  const resetInputState = () => {
    setAccommodationcostamount(0);
    setFirstname("");
    setLastname("");
    setArrive(today);
    setDepart(tomorrow);
    setEmail("");
    setAccommodation("");
    setTelephone("");
    setAdults(0);
    setChildren(0);
    setInfants(0);
    setCalculatedPrice("");
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const created = DateTime.utc().toJSDate();
    const accommodation = props.accommodations.find(
      (accommodation) => accommodation.id === accommodationid,
    );
    if (!accommodation || !arrive || !depart) {
      //TODO: pass message to UI
      errorLogger({
        error: "There is no accommodation with the id" + accommodationid,
      });
      return;
    }
    const accommodationName = accommodation.accommodation;
    if (
      adults === undefined ||
      children === undefined ||
      infants === undefined
    ) {
      return;
    }
    const numberAdults = Number(adults);
    const numberChildren = Number(children);
    const numberInfants = Number(infants);
    const utcArrive = convertToUTCDate(arrive);
    const utcDepart = convertToUTCDate(depart);
    const booking: IBooking = {
      accommodationid,
      accommodation: accommodationName,
      accommodationcostamount,
      accommodationcostcurrency: "GBP",
      remainderamount: accommodationcostamount,
      remaindercurrency: "GBP",
      adults: numberAdults,
      arrive: utcArrive,
      cancelled: false,
      children: numberChildren,
      created,
      depart: utcDepart,
      nights: DateTime.fromJSDate(depart).diff(
        DateTime.fromJSDate(arrive),
        "days",
      ).days,
      email,
      firstname,
      infants: numberInfants,
      lastname,
      siteId: props.siteId,
      status: "confirmed",
      telephone,
    };
    // TODO: Add to connections (e.g pitchup)
    // Add to Firestore
    const siteRef = props.db.collection("sites");
    const sites = siteRef.doc(props.siteId);
    const collectionRef = sites.collection("transactional");

    if (props.editID === "") {
      const batch = props.db.batch();
      const doc = collectionRef.doc();
      batch.set(doc, booking);
      decrementAllocation(batch);
      await batch.commit();
    } else {
      await collectionRef.doc(props.editID).update(booking);
    }
    resetInputState();
    event.preventDefault();
    props.handleClose();
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const target = event.currentTarget;
    const { value } = target;
    setFirstname(value);
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const { value } = target;
    setLastname(value);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const { value } = target;
    setEmail(value);
  };

  const handleFromChange = (startDate: Dayjs | null) => {
    if (!startDate || !startDate.isValid) {
      return;
    }
    setArrive(startDate.toDate());
    if (dayjs(depart).diff(startDate, "day") < 1) {
      setDepart(startDate.add(1, "day").toDate());
    }
    //require recalculation after any date change
    setConfirmDisabled(true);
  };
  const handleToChange = (endDate: Dayjs | null) => {
    if (!endDate || !endDate.isValid) {
      return;
    }
    setDepart(endDate.toDate());
    //require recalculation after any date change
    setConfirmDisabled(true);
  };

  const handleAccommodationTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const target = event.currentTarget;
    const { value } = target;
    setAccommodation(value);
    setDisabled(false);
    setTooltipOpen(false);
    setConfirmDisabled(true);
  };
  const handleContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const { value } = target;
    setTelephone(value);
  };

  const handleAdultsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const value = Number(target.value);
    if (event.currentTarget.value === "0") {
      setAdults("0");
    } else {
      setAdults(value);
    }
    setConfirmDisabled(true);
  };
  const handleChildrenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const value = Number(target.value);
    setConfirmDisabled(true);
    if (event.currentTarget.value === "0") {
      setChildren("0");
    } else {
      setChildren(value);
    }
  };
  const handleInfantsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const value = Number(target.value);
    setConfirmDisabled(true);
    if (event.currentTarget.value === "0") {
      setInfants("0");
    } else {
      setInfants(value);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCalculatedPrice(value);
  };
  const handleOnlineTooltipClose = () => {
    setOnlineTooltipOpen(false);
  };
  const handleOnlineTooltipOpen = () => {
    setOnlineTooltipOpen(true);
  };
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    if (accommodationid !== "") {
      setTooltipTitle("You must first choose an accommodation type");
      setTooltipOpen(false);
    }
  };

  const calculatePrice = async () => {
    if (!arrive || !depart) {
      //TODO: pass message to UI
      errorLogger({
        error: "There is no accommodation with the id" + accommodationid,
      });
      return;
    }
    const utcFrom = convertToUTCDate(arrive);
    const utcTo = convertToUTCDate(depart);
    if (
      adults === undefined ||
      children === undefined ||
      infants === undefined
    ) {
      return;
    }
    const numberAdults = Number(adults);
    const numberChildren = Number(children);
    const numberInfants = Number(infants);
    const flex = 0;
    const availabilityResult: IAvailabilityFlexResult =
      await getAvailabilityAndPrices(
        props.db,
        errorLogger,
        props.siteId,
        utcFrom,
        utcTo,
        accommodationid,
        numberAdults,
        numberChildren,
        numberInfants,
        flex,
      );
    const cost = availabilityResult.recommended?.cost;
    const availability: IAvailabilityMessage = availabilityResult.availability;
    setAvailable(availability.message);
    if (!allocations || !cost) {
      setAccommodationcostamount(0);
      setCalculatedPrice("N/A");
    } else {
      setAccommodationcostamount(cost.totalCost);
      setCalculatedPrice(cost.totalCost.toFixed(2));
      setAllocations(allocations);
    }
  };
  useEffect(() => {
    //TODO: fix this
    //window.addEventListener("online", setOnline);
    //window.addEventListener("offline", setOffline);
    const getEditData = async () => {
      const errorLogger = props.func.httpsCallable("error");
      const siteRef = props.db.collection("sites");
      const sites = siteRef.doc(props.siteId);
      const collectionRef = sites.collection("transactional");
      const snapshot = await collectionRef.doc(props.editID).get();
      const snapshotData = snapshot.data();
      if (!snapshotData) {
        errorLogger({
          error:
            "AddBooking: Edit: there is no booking with the id" + props.editID,
        });
        return;
      }
      const data = convertTimestamp(snapshotData) as unknown as IBooking;
      setArrive(data.arrive);
      setDepart(data.depart);
      setAccommodation(data.accommodationid);
      setAdults(data.adults);
      setInfants(data.infants);
      setChildren(data.children);
      setTelephone(data.telephone);
      setEmail(data.email ? data.email : "");
      setFirstname(data.firstname);
      setLastname(data.lastname);
    };
    if (props.editID) {
      getEditData() as unknown as IBooking;
    }
    return () => {
      //window.removeEventListener('online', setOnline);
      //window.removeEventListener('offline', setOffline);
    };
  }, [props.db, props.func, props.editID, props.siteId]);
  useEffect(() => {
    setConfirmDisabled(false);
  }, [allocations]);
  return (
    <div>
      <Modal
        disableEnforceFocus={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.addOpen}
        onClose={props.handleClose}
      >
        <div className="bookingflowModal">
          <form
            id="AddBooking"
            onKeyDown={handleSubmitKeyDown}
            onSubmit={handleSubmit}
          >
            <h2>
              {props.editID === "" && <span>New Booking</span>}
              {props.editID !== "" && <span>Edit Booking</span>}
            </h2>
            <div id="names">
              <TextField
                type="text"
                id="first"
                name="firstname"
                label="First Name"
                value={firstname}
                onChange={handleFirstNameChange}
                required
              />
              <TextField
                type="text"
                id="last"
                name="lastname"
                label="Last Name"
                value={lastname}
                onChange={handleLastNameChange}
              />
            </div>
            <TextField
              type="text"
              id="email"
              name="email"
              label="Email Address"
              value={email}
              onChange={handleEmailChange}
            />
            <div id="dates">
              <div className="InputFromTo">
                <DayPickerInput
                  label="Check In"
                  value={arrive}
                  placeholder="From"
                  onDayChange={handleFromChange}
                />
              </div>
              <div>
                <DayPickerInput
                  label="Check Out"
                  value={depart}
                  placeholder="To"
                  onDayChange={handleToChange}
                />
              </div>
            </div>
            <Grid item xs={12}>
              <NativeSelect
                id="pitch"
                name="accommodation"
                onChange={handleAccommodationTypeChange}
                aria-label="accommodation-type-input"
                required
                value={accommodationid}
              >
                {props.dataLoaded && createSelectItems()}
              </NativeSelect>
            </Grid>
            <TextField
              type="text"
              id="telephone"
              label="Contact Number"
              onChange={handleContactChange}
              value={telephone}
            />
            <TextField
              id="adults"
              type="number"
              aria-label="num-adults-input"
              label="Adults"
              inputProps={{
                min: "0",
                step: "1",
              }}
              value={adults || ""}
              onChange={handleAdultsChange}
              required
            />
            <TextField
              id="children"
              type="number"
              inputProps={{
                min: "0",
                step: "1",
              }}
              label="Children"
              aria-label="num-children-input"
              onChange={handleChildrenChange}
              value={children || ""}
              required
            />
            <TextField
              id="infants"
              type="number"
              inputProps={{
                min: "0",
                step: "1",
              }}
              label="Infants"
              aria-label="num-infants-input"
              value={infants || ""}
              onChange={handleInfantsChange}
              required
            />
            <Tooltip
              id="tooltip"
              onClose={handleTooltipClose}
              onOpen={handleTooltipOpen}
              open={tooltipOpen}
              title={tooltipTitle}
            >
              <div id="calculateContainer">
                <Button
                  id="calculate"
                  aria-label="Calculate Button"
                  variant="contained"
                  onClick={calculatePrice}
                  disabled={disabled}
                >
                  Calculate
                </Button>
              </div>
            </Tooltip>
            <p>Calculated Price:</p> {available}
            <Input
              aria-label="calculatedPrice"
              value={calculatedPrice}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">£</InputAdornment>
              }
            />
            <Grid container spacing={2}>
              <Grid item>
                <Button onClick={props.handleClose} variant="outlined">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Tooltip
                  id="tooltip"
                  onClose={handleOnlineTooltipClose}
                  onOpen={handleOnlineTooltipOpen}
                  open={onlineTooltipOpen}
                  title="You must be online to calculate prices"
                >
                  <Button
                    aria-label="ConfirmBooking"
                    className="waves-effect waves-light btn right"
                    type="submit"
                    value="Confirm"
                    variant="contained"
                    disabled={confirmDisabled}
                  >
                    Confirm
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddBooking;
